section.order-history {
    .overview-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-around;

        .radial-progress {
            position: relative;

            .progress {
                position: relative;
                height: 170px;
                width: 170px;
                z-index: 1;
            }

            .track, .fill {
                fill: rgba(0, 0, 0, 0);
                stroke-width: 3;
                transform: rotate(90deg) translate(0px, -80px);
            }

            .fill {
                stroke-dasharray: 219.99078369140625;
                stroke-dashoffset: -219.99078369140625;
                stroke: $primary-color;
                transition: stroke-dashoffset 1s ease-out;

                &.one {
                    //transition-delay: 0s;
                }

                &.two {
                    //transition-delay: .1s;
                }

                &.three {
                    //transition-delay: .2s;
                }

                &.four {
                    //transition-delay: .3s;
                }
            }

            .bg {
                fill: rgba(0, 0, 0, 0);
                stroke-dasharray: 219.99078369140625;
                stroke-dashoffset: 0;
                stroke-width: 3;
                stroke: $background-light;
                transform: rotate(90deg) translate(0px, -80px);
                transition: stroke-dashoffset 2s;
            }

            .text {
                display: flex;
                position: absolute;
                align-items: center;
                flex-direction: column;
                bottom: 0;
                justify-content: center;
                left: 0;
                right: 0;
                top: 0;
                z-index: 10;

                .value {
                    fill: $primary-color;
                    font-size: 1.5rem;
                    font-weight: bold;
                    margin-bottom: .3rem;
                }

                .title {
                    font-size: .8rem;
                }
            }
        }
    }

    .active-wrapper {
        ul {
            display: flex;
            flex-direction: column;
            grid-gap: 1rem;

            div.loading {
                display: flex;
                flex-direction: column;
                justify-content: center;
                text-align: center;

                .banner {
                    display: flex;
                    align-items: center;
                    border: 1px solid $border-color;
                    border-radius: 3px;
                    color: $text-color-alt;
                    flex-direction: column;
                    opacity: .75;
                    padding: 1rem 2rem;

                    i {
                        font-size: 2rem;
                    }

                    div {
                        margin-top: 1rem;

                        a {
                            font-weight: bold;
                            transition: all .2s;

                            &:hover {
                                color: $primary-color;
                            }
                        }
                    }
                }

                > i {
                    animation: rotation 1s infinite linear;
                    color: $primary-color;
                    font-size: 3rem;
                    transition: all .2s;
                    user-select: none;
                    
                    @keyframes rotation {
                        from {
                            transform: rotate(0deg);
                        }

                        to {
                            transform: rotate(359deg);
                        }
                    }
                }

                span {
                    margin-top: 1rem;
                    color: $text-color-alt;
                }
            }

            div.empty {
                color: $text-color-alt;
                //font-style: italic;
                text-align: center;
            }

            span.empty {
                color: $text-color-alt;
                text-align: center;

                a {
                    font-weight: bold;
                    transition: all .2s;

                    &:hover {
                        color: $primary-color;
                    }
                }
            }

            .sale-overview {
                display: flex;
                background: $background-light;
                border-radius: 3px;
                font-size: .9rem;
                justify-content: space-around;
                padding: 1rem 2rem;
                width: 100%;

                @media only screen and (max-width: 900px) {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    grid-gap: .5rem;
                }

                .stat {
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    justify-content: center;

                    &.grid-span {
                        grid-column-start: span 2;
                    }

                    .subtitle {
                        color: $text-color-alt;
                        font-size: .8rem;
                    }

                    .value {
                        max-width: 140px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        transition: all .2s;

                        a {
                            transition: all .2s;

                            &:hover {
                                color: $primary-color;
                            }
                        }

                        i {
                            font-size: .7rem;
                            margin-left: .3rem;
                            color: $warning-color;

                            &.message {
                                color: $text-color;
                                font-size: .8rem;
                                transition: all .2s;

                                &:hover {
                                    color: $primary-color;
                                }
                            }
                        }
                    }
                }
            }

            li {
                display: flex;
                border-bottom: 1px solid $background-light;
                grid-gap: 1rem;
                padding-bottom: 2rem;
                margin-bottom: 1rem;

                @media only screen and (max-width: 900px) {
                    flex-direction: column;
                }

                .mobile-wrapper {
                    display: flex;
                    grid-gap: 1rem;
                    width: 100%;

                    @media only screen and (max-width: 460px) {
                        flex-direction: column;
                    }
                }

                &:last-of-type {
                    border-bottom: none;
                    padding-bottom: none;
                }

                .thumbnail-wrapper {
                    position: relative;
                    border-radius: 3px;
                    border: 1px solid #eeeeee;
                    box-sizing: content-box;
                    min-height: 200px;
                    min-width: 200px;
                    overflow: hidden;
                    transition: all 0.3s;

                    // @media only screen and (max-width: 460px) {
                    //     height: calc(100vw - 2rem);
                    //     width: 100%;
                    // }

                    &:hover {
                        border-color: $primary-color;
                    }

                    img {
                        position: absolute;
                        max-height: 200px;
                        max-width: 200px;
                        margin: auto;
                        left: 0;
                        top: 0;
                        right: 0;
                        bottom: 0;

                        // @media only screen and (max-width: 460px) {
                        //     max-height: calc(98vw - 2rem);
                        //     max-width: calc(98vw - 2rem);
                        // }
                    }
                }

                .info {
                    display: flex;
                    position: relative;
                    color: $text-color;
                    flex-direction: column;
                    justify-content: flex-start;
                    width: 100%;

                    .status-icons {
                        display: flex;
                        position: absolute;
                        align-items: center;
                        background: $background-light;
                        border-radius: 3px;
                        bottom: 0;
                        gap: 1rem;
                        justify-content: flex-end;
                        padding: .5rem 1rem;
                        right: 0;

                        @media only screen and (max-width: 650px) {
                            position: unset;
                            margin-top: 1rem;
                        }

                        @media only screen and (max-width: 600px) {
                            justify-content: center;
                        }

                        div.icon {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            gap: .3rem;

                            span {
                                color: $text-color-alt;
                                font-size: .7rem;
                            }

                            i {
                                color: $divider-color;
    
                                &.active {
                                    color: $positive-color;
                                }

                                &.alert {
                                    color: $negative-color;
                                }
                            }
                        }
                    }

                    .subcategory {
                        font-size: 0.8rem;
                        transition: all .3s;
                        color: $text-color-alt;
                        margin-bottom: .5rem;

                        &:hover {
                            color: $primary-color;
                        }
                    }

                    .title {
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        font-size: 1.2rem;
                        font-weight: bold;
                        margin-bottom: 0.5rem;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        transition: all .3s;

                        &:hover {
                            color: $primary-color;
                        }
                    }

                    .reviews {
                        display: inline-flex;
                        align-items: center;
                        color: $divider-color;
                        grid-gap: .3rem;
                        margin-bottom: 1rem;

                        i.fill {
                            color: $primary-color;
                        }

                        i.fad {
                            &:before {
                                color: $primary-color;
                            }
                        }

                        span {
                            color: $text-color-alt;
                            font-size: .8rem;
                        }
                    }

                    .price {
                        display: flex;
                        align-items: center;
                        font-size: 1.4rem;
                        font-weight: bold;
                        gap: .3rem;

                        span {
                            font-size: .9rem;
                            font-weight: normal;
                        }
                    }

                    .options {
                        font-size: .8rem;
                        color: $text-color-alt;
                        margin-top: .1rem;
                    }

                    .stat-wrapper {
                        display: flex;
                        position: absolute;
                        border-top: 1px solid $border-color;
                        bottom: 0;
                        gap: 2rem;
                        margin-top: 0.5rem;
                        padding-top: 0.5rem;
                        justify-content: space-evenly;
                        width: 100%;

                        .stat {
                            font-size: .9rem;

                            i {
                                color: $primary-color;
                                margin-right: .3rem;

                                &.amber {
                                    color: $warning-color;
                                }

                                &.paid {
                                    color: #CFB53B;
                                }
                            }
                        }
                    }
                }

                .actions {
                    display: flex;
                    align-items: flex-end;
                    flex-direction: column;
                    gap: 1rem;
                    justify-content: center;

                    @media only screen and (max-width: 900px) {
                        display: grid;
                        grid-template-columns: repeat(2, 1fr);
                    }

                    @media only screen and (max-width: 460px) {
                        grid-template-columns: repeat(1, 1fr);
                    }

                    button {
                        background: none;
                        border-radius: 3px;
                        border: 1px solid $text-color-alt;
                        color: $text-color-alt;
                        min-width: 180px;
                        padding: .5rem 1rem;
                        transition: all .2s;

                        @media only screen and (max-width: 900px) {
                            width: 100%;
                        }

                        &:hover {
                            background: #805fd310;
                            border-color: $primary-color;
                            color: $primary-color;

                            > i {
                                color: $primary-color !important;
                            }
                        }

                        > i {
                            color: $text-color-alt !important;
                        }
                    }
                }
            }

            .pages {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 1rem;
                overflow-x: auto;

                @media only screen and (max-width: 420px) {
                    gap: .5rem;
                }

                > i {
                    font-size: .8rem;
                    color: $text-color-alt;
                    cursor: pointer;
                    transition: all .2s;

                    &:hover {
                        color: $text-color;
                    }
                }

                button {
                    background: white;
                    border-bottom: 2px solid rgba(0,0,0,0);
                    color: $text-color-alt;
                    padding: .4rem .6rem;
                    transition: all .2s;

                    &.active {
                        border-color: $primary-color;
                    }

                    &:hover {
                        border-color: $primary-color;
                    }
                }
            }
        }
    }
}
