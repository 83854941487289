div.input-modal {
    display: block;
    background: rgba(0, 0, 0, 0.25);
    height: 100%;
    left: 0;
    opacity: 0;
    position: fixed;
    text-align: center;
    top: 0;
    transition: all .2s;
    visibility: hidden;
    width: 100%;
    z-index: 1000;

    @media print {
        display: block !important;
    }

    &.active {
        opacity: 1;
        visibility: visible;
    }

    &:not(.active) {
        section {
            opacity: 0;
            visibility: hidden;
        }
    }

    .share-modal {
        .share-wrapper {
            display: flex;
            gap: 0.2rem;
            overflow-x: scroll;
            padding-bottom: 1rem;

            div > * > * {
                opacity: 0.9;
                border-radius: 5px;

                &:hover {
                    opacity: 1;
                }
            }
        }

        .share-link {
            margin-top: 1rem;
            padding: 1rem 0;
            border: solid 2px $divider-color;
            border-radius: 5px;
            color: $text-color;
            display: grid;
            grid-template-columns: auto 90px;
            cursor: pointer;
            gap: 0.5rem;

            p {
                overflow-x: scroll;
                scrollbar-width: none;
                padding-left: 0.8rem;
            }

            span {
                border-left: solid 1px $divider-color;
                padding-left: 0.5rem;
                padding-right: 0.8rem;
                font-weight: bold;
            }

            &:hover {
                border-color: $primary-color;
            }
        }
    }

    section {
        opacity: 0;
        visibility: hidden;

        &.active {
            opacity: 1;
            visibility: visible;
        }

        &.change-address, &.redeem-voucher {
            .content {
                min-width: 400px;
            }
        }

        &.make-payment {
            .content {
                min-width: 400px;
            }
        }

        &.add-variation {
            .content {
                max-width: 800px !important;
            }
        }

        &.deposit-address, &.withdraw-address, &.trade, &.farm, &.private-key, &.create-dispute, &.upload-evidence, &.view-evidence, &.vote-dispute, &.import-listing, &.leave-review, &.share, &.vote-bp {
            .content {
                width: 477px;

                &::-webkit-scrollbar {
                    width: 6px;
                }

                &::-webkit-scrollbar-track {
                    background: #f1f1f1;
                    border-radius: 0 6px 6px 0;
                }

                &::-webkit-scrollbar-thumb {
                    background: rgba($divider-color, 0.75);
                    border-radius: 0 6px 6px 0;
                    transition: all .3s;
                }

                &::-webkit-scrollbar-thumb:hover {
                    background: $divider-color;
                }
            }
        }

        &.create-account, &.verify-wallet {
            .content {
                max-width: 350px;
            }
        }

        &.buy-voucher {
            .content {
                width: 400px;
            }
        }

        .content {
            display: inline-block;
            background: white;
            border-radius: 6px;
            left: 50%;
            max-height: 95vh;
            overflow-y: auto;
            max-width: 600px;
            padding: 2rem;
            position: absolute;
            text-align: center;
            top: 50%;
            transform: translate(-50%, -50%);

            &.no-overflow {
                overflow-y: unset;
            }

            @media only screen and (max-width: 768px) {
                max-width: 95vw;
                width: 90vw;
            }

            .form-title {
                display: flex;
                align-items: center;
                font-weight: bold;
                grid-gap: .5rem;
                line-height: 1.5;
                margin-bottom: 2rem;
                text-align: left;

                img {
                    border-radius: 100%;
                    height: 28px;
                    width: 28px;
                }

                i {
                    color: $primary-color;
                    font-size: 24px;
                }

                span {
                    font-size: .8rem;

                    &.sign-out {
                        color: $primary-color;
                        font-size: .7rem;
                    }
                }
            }

            .vote-wrapper {
                display: flex;
                align-content: stretch;
                flex-direction: column;
                gap: 1rem;
                position: relative;

                span {
                    font-size: .8rem;
                    text-align: left;
                }

                ul.producers {
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    flex-wrap: wrap;
                    grid-gap: .5rem;
                    justify-content: left;
                    text-align: left;

                    img {
                        border-radius: 100%;
                        height: 28px;
                        width: 28px;
                    }

                    > div {
                        input:checked + label {
                            opacity: 1 !important;
                        }

                        input:disabled + label {
                            opacity: .5;
                        }

                        label {
                            justify-content: flex-start;
                        }
                    }

                    > div {
                        user-select: none;
                        width: 100%;

                        input:checked + label {
                            border-color: $primary-color;
                            color: $primary-color;
                        }

                        label {
                            display: inline-flex;
                            align-items: center;
                            border-radius: 3px;
                            border: 1px solid #eeeeee;
                            cursor: pointer;
                            font-size: 1rem !important;
                            grid-gap: 0.5rem;
                            justify-content: left;
                            line-height: 1;
                            padding: 0.5rem 1rem;
                            position: relative;
                            transition: all 0.3s;
                            width: 100%;
                            z-index: 1;

                            img {
                                max-height: 32px;
                                max-width: 32px;
                                overflow: hidden;
                                border-radius: 100%;
                            }

                            i {
                                position: absolute;
                                right: 1rem;
                                color: $primary-color;
                            }
                        }
                    }
                }

                div.confirm-wrapper {
                    display: flex;
                    align-items: center;
                    background: white;
                    bottom: -2rem;
                    height: 6rem;
                    justify-content: center;
                    margin-bottom: -2rem;
                    position: sticky;
                    z-index: 100;

                    button {
                        background: $primary-color;
                        border-radius: 3px;
                        color: white;
                        padding: .5rem 1rem;
                        transition: all .3s;
                        width: 100%;

                        &:disabled {
                            background: rgba($text-color-alt, 0.5);
                        }

                        > i {
                            color: white;
                        }
                    }
                }
            }

            .import-wrapper {
                display: flex;
                color: $text-color;
                flex-direction: column;
                gap: 1rem;
                text-align: left;

                form {
                    .input-group {
                        width: 100%;

                        > label {
                            margin-bottom: 1rem;
                        }

                        .radio-wrapper {
                            display: flex;
                            flex-wrap: wrap;
                            align-items: center;
                            gap: .5rem;
                            justify-content: flex-start;

                            > div {
                                display: flex;
                                align-items: center;
                                justify-content: center;

                                input:checked + label {
                                    border-color: $primary-color;
                                    color: $primary-color;
                                }

                                input:disabled + label {
                                    opacity: .5;

                                    &:hover {
                                        background: none;
                                        border-color: $border-color;
                                        color: $text-color;
                                    }
                                }

                                label {
                                    display: block;
                                    border-radius: 3px;
                                    border: 1px solid $border-color;
                                    cursor: pointer;
                                    padding: .5rem 1rem;
                                    transition: all .3s;
                                    user-select: none;
    
                                    &:hover {
                                        background: #805fd310;
                                        border-color: $primary-color;
                                        color: $primary-color;
                                    }
            
                                    &.active {
                                        border-color: $primary-color;
                                        color: $primary-color;
                                    };
    
                                    i {
                                        margin-right: .5rem;
                                    }
                                }
                            }
                        }
                    }

                    button {
                        width: 100%;
                    }
                }
            }

            .voucher-wrapper {
                display: flex;
                color: $text-color;
                flex-direction: column;
                gap: 1rem;
                //margin-top: -1rem;
                text-align: left;

                form {
                    .input-group {
                        width: 100%;
                    }

                    button {
                        width: 100%;
                    }
                }

                > i {
                    color: $positive-color;
                    font-size: 2rem;
                    text-align: center;
                }

                > p {
                    text-align: center;
                    font-weight: normal;

                    &.small {
                        font-size: .8rem;
                    }

                    span {
                        font-weight: bold;
                    }
                }

                .purchase-title {
                    font-size: 1.2rem;
                    font-weight: bold;
                }
                
                .purchase-price {
                    color: $primary-color;
                    font-size: 1.5rem;
                    font-weight: bold;
                }

                .purchase-amount {
                    color: $text-color-alt;
                }

                .info {
                    color: $text-color-alt;
                    font-size: .7rem;
                    text-align: center;

                    a {
                        font-size: .7rem;
                    }
                }
            }

            .vote-dispute-wrapper {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                text-align: left;
                gap: 2rem;

                p.subtitle {
                    color: $text-color-alt;
                    font-size: .8rem;
                    margin-bottom: .3rem;
                }

                div.proposal {
                    .share {
                        display: flex;
                        gap: .5rem;
                    }
                }

                div.notice {
                    color: $text-color-alt;
                    text-align: left;
                    font-size: .8rem;
                    margin-bottom: -1rem;
                }

                div.buttons {
                    display: flex;
                    gap: 1rem;
                    width: 100%;

                    button {
                        background: $primary-color;
                        border-radius: 3px;
                        color: white;
                        padding: .5rem 1rem;
                        width: 100%;

                        > i {
                            color: white;
                        }
                    }
                }
            }

            .view-dispute-wrapper {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                text-align: left;
                gap: 2rem;

                .subtitle {
                    color: $text-color-alt;
                    font-size: .8rem;
                    margin-bottom: .3rem;
                }

                .order-status {
                    .status-icons {
                        display: flex;
                        align-items: center;
                        background: $background-light;
                        border-radius: 3px;
                        gap: 1rem;
                        justify-content: flex-end;
                        padding: .5rem 1rem;
    
                        @media only screen and (max-width: 650px) {
                            position: unset;
                            margin-top: 1rem;
                        }
    
                        @media only screen and (max-width: 600px) {
                            justify-content: center;
                        }
    
                        div.icon {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            gap: .3rem;
    
                            span {
                                color: $text-color-alt;
                                font-size: .7rem;
                            }
    
                            i {
                                color: $divider-color;
    
                                &.active {
                                    color: $positive-color;
                                }
    
                                &.alert {
                                    color: $negative-color;
                                }
                            }
                        }
                    }
                }

                div.evidence {
                    .image-previews {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        grid-gap: 0.5rem;
                        margin-bottom: 1rem;
                        overflow: hidden;
                        position: relative;
                        scroll-behavior: smooth;
                        scroll-snap-type: x mandatory;
                        width: 100%;

                        > p.empty {
                            color: $text-color-alt;
                            font-style: italic;
                        }

                        a {
                            display: inline-block;
                            height: 92px;
                            width: 92px;
                            margin: unset;

                            .image-wrapper {
                                
                                background: white;
                                border-radius: 3px;
                                border: 1px solid $border-color;
                                height: 92px;
                                overflow: hidden;
                                position: relative;
                                transition: all 0.3s;
                                width: 92px;

                                &:hover {
                                    border-color: $primary-color;
                                }

                                img {
                                    position: absolute;
                                    bottom: 0;
                                    left: 0;
                                    margin: auto;
                                    max-height: 87.5px;
                                    max-width: 100%;
                                    right: 0;
                                    top: 0;
                                }

                                span {
                                    position: absolute;
                                    bottom: 1.5rem;
                                    font-size: .8rem;
                                    left: 0;
                                    overflow: hidden;
                                    right: 0;
                                    text-align: center;
                                    white-space: nowrap;
                                }

                                i.fa-file-pdf {
                                    display: flex;
                                    align-items: center;
                                    color: $primary-color;
                                    font-size: 2rem;
                                    //height: 100%;
                                    justify-content: center;
                                    margin-top: 1.5rem;
                                    width: 100%;
                                }

                                i.fa-times {
                                    position: absolute;
                                    color: $text-color-alt;
                                    margin: .1rem;
                                    right: 0;
                                    top: 0;
                                    transition: all .2s;

                                    &:hover {
                                        color: $negative-color;
                                    }
                                }
                            }
                        }
                    }

                    div.notes {
                        display: flex;
                        flex-direction: column;
                        gap: 1rem;

                        > p.empty {
                            color: $text-color-alt;
                            font-style: italic;
                        }

                        div.note {
                            display: grid;
                            gap: .3rem;
                            grid-template-columns: 1fr 4fr;

                            p {
                                font-size: .9rem;

                                &.id {
                                    color: $text-color-alt;
                                }
                            }
                        }
                    }
                }
            }

            .leave-review-wrapper {
                .input-group {
                    width: 100%;

                    @media only screen and (max-width: 500px) {
                        width: 100%;
                    }

                    &:has(input:focus, input:not([value=""])), &:has(textarea:focus, textarea:not(:empty)) {
                        > i {
                            &:first-child {
                                visibility: visible;
                            }
                        }
                    }

                    input::-webkit-outer-spin-button,
                    input::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                    }

                    input[type="number"] {
                        -moz-appearance: textfield;
                    }

                    .image-buttons {
                        display: flex;
                        grid-gap: .5rem;
                        margin: .5rem 0;
                        justify-content: space-between;

                        input + label {
                            display: inline-flex;
                            align-items: center;
                            border-radius: 3px;
                            border: 1px solid $border-color;
                            cursor: pointer;
                            font-size: 1rem;
                            grid-gap: .5rem;
                            justify-content: center;
                            padding: .5rem 1rem;
                            transition: all .2s;
                            width: 100%;
                            z-index: 1;

                            i {
                                font-size: 1rem;
                            }
                        }

                        input:checked + label {
                            border-color: $primary-color;
                            color: $primary-color;
                        }
                    }

                    .image-previews {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        grid-gap: 0.5rem;
                        overflow: hidden;
                        position: relative;
                        scroll-behavior: smooth;
                        scroll-snap-type: x mandatory;
                        width: 100%;

                        &.empty {
                            height: 0;
                            margin-top: 0;
                        }

                        .image-wrapper {
                            display: inline-block;
                            background: white;
                            border-radius: 3px;
                            border: 1px solid $border-color;
                            height: 97.2px;
                            overflow: hidden;
                            position: relative;
                            transition: all 0.3s;
                            width: 97.2px;

                            &:hover {
                                border-color: $primary-color;
                            }

                            &.uploaded {
                                opacity: .5;
                            }

                            img {
                                position: absolute;
                                bottom: 0;
                                left: 0;
                                margin: auto;
                                max-height: 87.5px;
                                max-width: 100%;
                                right: 0;
                                top: 0;
                            }

                            span {
                                position: absolute;
                                bottom: 1.5rem;
                                font-size: .8rem;
                                left: 0;
                                overflow: hidden;
                                right: 0;
                                text-align: center;
                                white-space: nowrap;
                            }

                            i.fa-file-pdf {
                                display: flex;
                                align-items: center;
                                color: $primary-color;
                                font-size: 2rem;
                                //height: 100%;
                                justify-content: center;
                                margin-top: 1.5rem;
                                width: 100%;
                            }

                            i.fa-times {
                                position: absolute;
                                color: $text-color-alt;
                                margin: .1rem;
                                right: 0;
                                top: 0;
                                transition: all .2s;

                                &:hover {
                                    color: $negative-color;
                                }
                            }
                        }
                    }

                    .chars-left {
                        position: absolute;
                        color: $text-color-alt;
                        font-size: .8rem;
                        right: 0;
                        text-align: right;
                        top: 0;
                        visibility: hidden;
                        white-space: nowrap;

                        &.images-left {
                            visibility: visible;
                        }

                        &.limit {
                            color: $negative-color;
                        }
                    }

                    .crypto-amount {
                        position: absolute;
                        right: 0;
                        bottom: 0;
                        padding-bottom: 0.5rem;
                        font-size: .9rem;
                        color: $text-color-alt;
                        user-select: none;
                        line-height: 1.5;
                        border-bottom: 1px solid rgba(0,0,0,0);
                    }

                    &:has(input:focus, input:not([value=""])), &:has(textarea:focus, textarea:not(:empty)) {
                        > i {
                            &:first-child {
                                visibility: visible;
                            }
                        }

                        // label {
                        //     transform: translateY(0);
                        //     font-size: .9rem;
                        // }

                        span.chars-left {
                            visibility: visible;
                        }
                    }

                    textarea {
                        display: block;
                        -moz-box-shadow: none;
                        -webkit-box-shadow: none;
                        background: none;
                        border: none;
                        border-bottom: 1px solid $border-color;
                        box-shadow: none;
                        color: $text-color;
                        font-size: 1rem;
                        line-height: 1.5;
                        padding: .5rem 0;
                        min-height: 41px;
                        height: 41px;
                        outline: none;
                        overflow: auto;
                        resize: none;
                       // transition: all .2s;
                        width: 100%;

                        &:focus {
                            border-bottom-color: $primary-color;
                        }

                        &:-webkit-autofill,
                        &:-webkit-autofill:hover,
                        &:-webkit-autofill:focus,
                        &:-webkit-autofill:active {
                            transition: background-color 5000s ease-in-out 0s;
                        }
                    }

                    label {
                        position: relative;
                        //font-size: 1rem;
                        //line-height: 1rem;
                        //transform: translateY(30px);
                        transition: all 0.3s;
                        z-index: -1;

                        &.images, &[for="images"], &[for="files"] {
                            display: inline-flex;
                            align-items: center;
                            border-radius: 3px;
                            border: 1px solid $border-color;
                            cursor: pointer;
                            font-size: 1rem !important;
                            grid-gap: .5rem;
                            justify-content: center;
                            padding: .5rem 1rem;
                            transition: all .2s;
                            width: 100%;
                            z-index: 1;

                            &:hover {
                                color: $primary-color;
                                border-color: $primary-color;
                            }

                            &.limit {
                                border-color: $negative-color;
                                color: $negative-color;
                            }

                            i {
                                font-size: 1.2rem;
                            }
                        }
                    }

                    .rating-wrapper {
                        display: flex;
                        align-items: stretch;
                        margin-top: .5rem;

                        &:hover i {
                            font-weight: 900 !important;
                            opacity: 1;
                        }

                        i {
                            color: $primary-color;
                            cursor: pointer;
                            font-family: "Font Awesome 5 Pro";
                            font-size: 1.5rem;
                            height: 100%;
                            opacity: .5;

                            &:hover {
                                font-weight: 900 !important;
                                opacity: 1;
                            }

                            &:hover ~ i {
                                font-weight: 300 !important;
                                opacity: .5;
                            }

                            &.active {
                                font-weight: 900 !important;
                                opacity: 1;
                            }
                        }
                    }
                }
            }

            .create-dispute-wrapper {
                display: flex;
                align-items: center;
                flex-direction: column;

                .dispute-summary {
                    display: flex;
                    gap: 1rem;
                    justify-content: space-between;
                    margin-bottom: 1rem;
                    width: 100%;

                    > div {
                        //text-align: left;

                        span {
                            color: $text-color-alt;
                            font-size: 0.8rem;
                        }

                        div {

                        }
                    }
                }

                .input-group {
                    width: 100%;

                    @media only screen and (max-width: 500px) {
                        width: 100%;
                    }

                    &:has(input:focus, input:not([value=""])), &:has(textarea:focus, textarea:not(:empty)) {
                        > i {
                            &:first-child {
                                visibility: visible;
                            }
                        }
                    }

                    input::-webkit-outer-spin-button,
                    input::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                    }

                    input[type="number"] {
                        -moz-appearance: textfield;
                    }

                    .image-buttons {
                        display: flex;
                        grid-gap: .5rem;
                        margin: .5rem 0;
                        justify-content: space-between;

                        input + label {
                            display: inline-flex;
                            align-items: center;
                            border-radius: 3px;
                            border: 1px solid $border-color;
                            cursor: pointer;
                            font-size: 1rem;
                            grid-gap: .5rem;
                            justify-content: center;
                            padding: .5rem 1rem;
                            transition: all .2s;
                            width: 100%;
                            z-index: 1;

                            i {
                                font-size: 1rem;
                            }
                        }

                        input:checked + label {
                            border-color: $primary-color;
                            color: $primary-color;
                        }
                    }

                    .image-previews {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        grid-gap: 0.5rem;
                        overflow: hidden;
                        position: relative;
                        scroll-behavior: smooth;
                        scroll-snap-type: x mandatory;
                        width: 100%;

                        &.empty {
                            height: 0;
                            margin-top: 0;
                        }

                        .image-wrapper {
                            display: inline-block;
                            background: white;
                            border-radius: 3px;
                            border: 1px solid $border-color;
                            height: 97.2px;
                            overflow: hidden;
                            position: relative;
                            transition: all 0.3s;
                            width: 97.2px;

                            &:hover {
                                border-color: $primary-color;
                            }

                            &.uploaded {
                                opacity: .5;
                            }

                            img {
                                position: absolute;
                                bottom: 0;
                                left: 0;
                                margin: auto;
                                max-height: 87.5px;
                                max-width: 100%;
                                right: 0;
                                top: 0;
                            }

                            span {
                                position: absolute;
                                bottom: 1.5rem;
                                font-size: .8rem;
                                left: 0;
                                overflow: hidden;
                                right: 0;
                                text-align: center;
                                white-space: nowrap;
                            }

                            i.fa-file-pdf {
                                display: flex;
                                align-items: center;
                                color: $primary-color;
                                font-size: 2rem;
                                //height: 100%;
                                justify-content: center;
                                margin-top: 1.5rem;
                                width: 100%;
                            }

                            i.fa-times {
                                position: absolute;
                                color: $text-color-alt;
                                margin: .1rem;
                                right: 0;
                                top: 0;
                                transition: all .2s;

                                &:hover {
                                    color: $negative-color;
                                }
                            }
                        }
                    }

                    .chars-left {
                        position: absolute;
                        color: $text-color-alt;
                        font-size: .8rem;
                        right: 0;
                        text-align: right;
                        top: 0;
                        visibility: hidden;
                        white-space: nowrap;

                        &.images-left {
                            visibility: visible;
                        }

                        &.limit {
                            color: $negative-color;
                        }
                    }

                    .crypto-amount {
                        position: absolute;
                        right: 0;
                        bottom: 0;
                        padding-bottom: 0.5rem;
                        font-size: .9rem;
                        color: $text-color-alt;
                        user-select: none;
                        line-height: 1.5;
                        border-bottom: 1px solid rgba(0,0,0,0);
                    }

                    &:has(input:focus, input:not([value=""])), &:has(textarea:focus, textarea:not(:empty)) {
                        > i {
                            &:first-child {
                                visibility: visible;
                            }
                        }

                        // label {
                        //     transform: translateY(0);
                        //     font-size: .9rem;
                        // }

                        span.chars-left {
                            visibility: visible;
                        }
                    }

                    textarea {
                        display: block;
                        -moz-box-shadow: none;
                        -webkit-box-shadow: none;
                        background: none;
                        border: none;
                        border-bottom: 1px solid $border-color;
                        box-shadow: none;
                        color: $text-color;
                        font-size: 1rem;
                        line-height: 1.5;
                        padding: .5rem 0;
                        min-height: 41px;
                        height: 41px;
                        outline: none;
                        overflow: auto;
                        resize: none;
                       // transition: all .2s;
                        width: 100%;

                        &:focus {
                            border-bottom-color: $primary-color;
                        }

                        &:-webkit-autofill,
                        &:-webkit-autofill:hover,
                        &:-webkit-autofill:focus,
                        &:-webkit-autofill:active {
                            transition: background-color 5000s ease-in-out 0s;
                        }
                    }

                    label {
                        position: relative;
                        //font-size: 1rem;
                        //line-height: 1rem;
                        //transform: translateY(30px);
                        transition: all 0.3s;
                        z-index: -1;

                        &.images, &[for="images"], &[for="files"] {
                            display: inline-flex;
                            align-items: center;
                            border-radius: 3px;
                            border: 1px solid $border-color;
                            cursor: pointer;
                            font-size: 1rem !important;
                            grid-gap: .5rem;
                            justify-content: center;
                            padding: .5rem 1rem;
                            transition: all .2s;
                            width: 100%;
                            z-index: 1;

                            &:hover {
                                color: $primary-color;
                                border-color: $primary-color;
                            }

                            &.limit {
                                border-color: $negative-color;
                                color: $negative-color;
                            }

                            i {
                                font-size: 1.2rem;
                            }
                        }
                    }
                }

                div.notice {
                    color: $text-color-alt;
                    text-align: left;
                    font-size: .8rem;
                    margin-bottom: .5rem;
                }

                button {
                    width: 100%;
                }
            }

            .kyc-wrapper {
                display: flex;
                align-items: center;
                flex-direction: column;

                .input-group {
                    @media only screen and (max-width: 500px) {
                        width: 100%;
                    }
                }

                a.mobile-link {
                    display: none;
                    color: $text-color;
                    font-size: 1rem;
                    text-align: center;

                    @media only screen and (max-width: 500px) {
                        display: block;
                    }
                }

                .qr-wrapper {
                    border-radius: 6px;
                    border: 1px solid $border-color;
                    height: 250px;
                    margin-bottom: 2rem;
                    width: 250px;
            
                    canvas {
                        height: 100% !important;
                        width: 100% !important;
                    }
                }

                .subtitle {
                    text-align: left;
                    width: 100%;
                }

                .instructions {
                    display: flex;
                    color: $text-color;
                    flex-direction: column;
                    gap: .5rem;
                    text-align: left;
                }

                .finished {
                    display: inline-flex;
                    flex-direction: column;
                    width: 100%;

                    .status {
                        display: inline-flex;
                        align-items: center;
                        border-radius: 3px;
                        border: 1px solid $border-color;
                        gap: .5rem;
                        margin-bottom: 1rem;
                        padding: .5rem 1rem;
    
                        &.green {
                            background: rgba($positive-color, 0.1);
                            border-color: $positive-color;
                            color: $positive-color;
    
                            > i {
                                color: $positive-color;
                            }
                        }
    
                        &.amber {
                            background: rgba($warning-color, 0.1);
                            border-color: $warning-color;
                            color: $warning-color;
    
                            > i {
                                color: $warning-color;
                            }
                        }
    
                        &.red {
                            background: rgba($negative-color, 0.1);
                            border-color: $negative-color;
                            color: $negative-color;
    
                            > i {
                                color: $negative-color;
                            }
                        }
                    }
                }
            }

            .export-wrapper {
                .notice {
                    text-align: left;

                    span {
                        font-weight: bold;
                        color: $negative-color;
                    }
                }

                label {
                    color: $text-color-alt;
                    font-size: .8rem;
                    margin-bottom: .3rem;
                    text-align: left;
                    width: 100%;
                }

                .pk {
                    color: $text-color-alt;
                    border-radius: 3px;
                    background: white;
                    padding: .5rem;
                    overflow-wrap: break-word;
                    border: 1px solid $border-color;
                    font-size: .8rem;
                    margin-bottom: 1rem;
                    line-height: 1;
                    text-align: left;
                    width: 100%;
                }

                button {
                    background-color: $negative-color;
                    width: 100%;
                }
            }

            .create-wrapper {
                button {
                    width: 100%;
                }

                .conditions {
                    display: flex;
                    align-items: flex-start;
                    color: $text-color-alt;
                    flex-direction: column;
                    font-size: .8rem;
                    gap: .3rem;
                    margin-bottom: 1rem;
                    margin-top: -1rem;

                    i {
                        margin-right: .1rem;

                        &.fa-check-circle {
                            color: $positive-color;
                        }

                        &.fa-times-circle {
                            color: $negative-color;
                        }
                    }
                }

                .roadmap {
                    display: flex;
                    //font-size: .8rem;
                    align-items: flex-start;
                    color: $text-color-alt;
                    flex-direction: column;
                    gap: .5rem;
                    margin-bottom: 1rem;
                    //margin-top: -1rem;
                    width: 100%;
                    font-size: .8rem;

                    span {
                        display: flex;
                        align-items: center;
                        line-height: 1rem;
                        gap: .3rem;
                    }

                    span:last-of-type {
                        font-size: 1rem;
                        color: $text-color;
                    }

                    > i {
                        width: 16px;
                        color: $divider-color;
                    }

                    i {
                        font-size: 1rem;
                        margin-right: .1rem;

                        &.fa-check-circle {
                            color: $positive-color;
                        }

                        &.fa-times-circle {
                            color: $negative-color;
                        }

                        &.fa-circle {
                            color: $warning-color;
                        }
                    }
                }

                .info {
                    color: $text-color-alt;
                    font-size: .7rem;
                    margin-top: 1rem;

                    a {
                        font-size: .7rem;
                    }
                }
            }

            .create-ad-wrapper {
                

                .thumbnail-wrapper {

                    img {
                        width: 150px;
                    }
                }

                h4 {
                    color: lighten($text-color, 5%);
                    border-bottom: solid 1px $border-color;
                    margin-bottom: 1rem;
                    padding-bottom: 1rem;
                }

                .duration-wrapper {
                    text-align: left;
                    
                    
                    span {
                        color: $text-color-alt;
                        font-size: 0.9rem;
                    }

                    .selection {
                        margin-top: 0.2rem;
                        display: flex;
                        gap: 4px;
                        text-align: center;
                        
                        div {
                            border: solid 1px $text-color-alt;
                            padding: 1rem;
                            color: $text-color-alt;
                            flex: 1;
                            cursor: pointer;

                            &:hover {
                                border-color: $primary-color;
                                color: $primary-color
                            }

                            // Class/State applied when item is active
                            &.active {
                                background: $primary-color;
                                color: $background-light;
                                border-color: $primary-color;
                            }
                        }
                    }
                }

                .price-wrapper {
                    padding: 2rem 0;
                    text-align: left;

                    h3 {
                        font-size: 1.8rem;
                        color: lighten($text-color, 5%);
                        margin-bottom: 0.2rem;
                    }

                    span {
                        display: flex;
                        align-items: center;
                        font-size: 1.2rem;
                        color: lighten($text-color, 20%);

                        img {
                            width: 20px;
                            margin-bottom: 2px;
                            margin-right: 5px;
                        }
                    }
                }

                button {
                    background-color: $primary-color;
                    color: $background-light;
                    display: block;
                    width: 100%;
                    padding: 1rem 2rem;

                    &:hover {
                        background-color: lighten($primary-color, 3%);
                    }
                }
            }

            .swap-wrapper {
                position: relative;
                color: $text-color;
                text-align: center;

                > i {
                    display: flex;
                    position: absolute;
                    align-items: center;
                    background-color: $border-color;
                    border-radius: 100%;
                    color: $text-color;
                    height: 2rem;
                    justify-content: center;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%) rotate(90deg);
                    width: 2rem;

                    &.fa-plus {
                        top: 60%;
                    }
                }

                & ~ button {
                    background: $primary-color;
                    border-radius: 3px;
                    color: white;
                    margin-top: 1rem;
                    padding: 0.5rem 1rem;
                    width: 100%;

                    &:disabled {
                        background: rgba($text-color-alt, 0.5);
                    }
            
                }

                & ~ .info {
                    display: flex;
                    flex-direction: column;
                    gap: .5rem;
                    margin-top: 1rem;
                    
                    div {
                        display: flex;
                        color: $text-color-alt;
                        font-size: .8rem;
                        justify-content: space-between;

                        span {
                            &.green {
                                color: $positive-color;
                            }

                            &.amber {
                                color: $warning-color;
                            }

                            &.red {
                                color: $negative-color;
                            }
                        }

                        i {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            line-height: 1px;
                        }
                    }
                }

                & ~ .increase-limit {
                    margin-top: .5rem;
                    font-size: .8rem;
                    color: $text-color-alt;
                    border: 1px solid $warning-color;
                    border-radius: 3px;
                    padding: .5rem 1rem;
                    background: rgba($warning-color, 0.1);

                    a {
                        font-size: .8rem;
                        color: $primary-color;
                        text-decoration: underline;
                    }
                }
                
                > .token {
                    display: flex;
                    border: 1px solid $border-color;
                    gap: .5rem;
                    justify-content: space-between;
                    padding: 1rem;

                    &.base {
                        border-radius: 6px 6px 0 0;
                        border-bottom: none;
                        padding-bottom: 1.5rem;
                    }

                    &.quote {
                        border-radius: 0 0 6px 6px;
                        padding-top: 1.5rem;
                    }

                    .input-wrapper {
                        position: relative;

                        > span {
                            display: flex;
                            align-items: center;
                            gap: .5rem;
                        }

                        span.value {
                            display: none;
                            font-size: .7rem;
                            opacity: .8;
                        
                            &.active {
                                display: block;
                            }
                        }

                        > i {
                           position: absolute;
                           bottom: 0;
                           height: 1rem;
                           left: 0;
                           width: 1rem;
                        }
                    }
                    
                    .token-wrapper {
                        span {
                            text-align: right;
                        }
                    }

                    > div {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        gap: .5rem;
                        width: 100%;

                        span {
                            font-size: .8rem;
                            color: $text-color-alt;
                        }

                        input {
                            font-size: 1.2rem;
                            font-weight: bold;
                            width: 100%;
                        }

                        img {
                            border-radius: 100%;
                            height: 28px;
                            width: 28px;
                        }
                    }

                    .currency {
                        display: flex;
                        align-items: center;
                        font-weight: bold;
                        gap: .5rem;
                        justify-content: flex-end;
                        position: relative;
                        
                        .ticker {
                            font-size: 1.2rem;
                            line-height: 1px;
                        }

                        i {
                            font-size: .8rem;
                        }

                        > .menu {
                            display: flex;
                            background: white;
                            border: 1px solid $border-color;
                            border-radius: 3px;
                            //box-shadow: 0px 3px 5px 3px rgba(0, 0, 0, 0.05);
                            cursor: default;
                            flex-direction: column;
                            gap: 1rem;
                            height: 192px;
                            opacity: 0;
                            overflow-y: scroll;
                            padding: 1rem 0;
                            position: absolute;
                            right: -17px;
                            top: 2rem;
                            transition: all .2s;
                            visibility: hidden;
                            z-index: 10;

                            &.active {
                                visibility: visible;
                                opacity: 1;
                            }

                            &::-webkit-scrollbar {
                                width: 0px;
                            }

                            &::-webkit-scrollbar-track {
                                background: $background-light;
                            }

                            &::-webkit-scrollbar-thumb {
                                background: rgba(#805fd3, 0.2);
                            }

                            &::-webkit-scrollbar-thumb:hover {
                                background: $primary-color;
                            }

                            .token {
                                display: flex;
                                align-items: center;
                                gap: .5rem;
                                opacity: .5;
                                padding: 0 1rem;
                                transition: all .2s;

                                &:hover {
                                    opacity: 1;
                                }

                                &.active {
                                    opacity: 1;
                                }
                            }
                        }
                    }
                }

                div.rewards, div.remove {
                    display: flex;
                    align-items: center;
                    gap: 1rem;
                    justify-content: space-between;
                    margin-top: 2rem;

                    &.remove {
                        align-items: flex-start;
                        flex-direction: column;

                        > div {
                            width: 100%;
                        }

                        > button {
                            width: 100%;
                        }
                    }

                    > div {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;

                        .title {
                            font-size: .9rem;
                            margin-bottom: .3rem;
                        }
        
                        .subtitle {
                            display: flex;
                            align-items: center;
                            font-weight: bold;
                            gap: .3rem;
        
                            img {
                                height: 20px;
                                width: 20px;
                            }
                        }
                        
                        ////////////////////////////////////////////////
                        // The main styles
                        
                        .range-slider {
                            width: 100%;
                            margin-bottom: 1rem;
                            --primary-color: #805fd3;
                        
                            --value-offset-y: var(--ticks-gap);
                            --value-active-color: white;
                            --value-background: transparent;
                            --value-background-hover: var(--primary-color);
                            --value-font: 700 12px/1 Arial;
                        
                            --fill-color: var(--primary-color);
                            --progress-background: #eee;
                            --progress-radius: 20px;
                            --track-height: calc(var(--thumb-size) / 2);
                        
                            --min-max-font: 12px Arial;
                            --min-max-opacity: 0.5;
                            --min-max-x-offset: 10%; // 50% to center
                        
                            --thumb-size: 22px;
                            --thumb-color: white;
                            --thumb-shadow: 0 0 3px rgba(0, 0, 0, 0.4), 0 0 1px rgba(0, 0, 0, 0.5) inset,
                            0 0 0 99px var(--thumb-color) inset;
                        
                            --thumb-shadow-active: 0 0 0 calc(var(--thumb-size) / 4) inset
                                var(--thumb-color),
                            0 0 0 99px var(--primary-color) inset, 0 0 3px rgba(0, 0, 0, 0.4);
                        
                            --thumb-shadow-hover: var(--thumb-shadow);
                        
                            --ticks-thickness: 1px;
                            --ticks-height: 5px;
                            --ticks-gap: var(
                            --ticks-height,
                            0
                            ); // vertical space between the ticks and the progress bar
                            --ticks-color: silver;
                        
                            // ⚠️ BELOW VARIABLES SHOULD NOT BE CHANGED
                            --step: 1;
                            --ticks-count: Calc(var(--max) - var(--min)) / var(--step);
                            --maxTicksAllowed: 30;
                            --too-many-ticks: Min(1, Max(var(--ticks-count) - var(--maxTicksAllowed), 0));
                            --x-step: Max(
                            var(--step),
                            var(--too-many-ticks) * (var(--max) - var(--min))
                            ); // manipulate the number of steps if too many ticks exist, so there would only be 2
                            --tickInterval: 100/ ((var(--max) - var(--min)) / var(--step)) * var(--tickEvery, 1);
                            --tickIntervalPerc: calc(
                            (100% - var(--thumb-size)) / ((var(--max) - var(--min)) / var(--x-step)) *
                                var(--tickEvery, 1)
                            );
                        
                            --value-a: Clamp(
                            var(--min),
                            var(--value, 0),
                            var(--max)
                            ); // default value ("--value" is used in single-range markup)
                            --value-b: var(--value, 0); // default value
                            --text-value-a: var(--text-value, "");
                        
                            --completed-a: calc(
                            (var(--value-a) - var(--min)) / (var(--max) - var(--min)) * 100
                            );
                            --completed-b: calc(
                            (var(--value-b) - var(--min)) / (var(--max) - var(--min)) * 100
                            );
                            --ca: Min(var(--completed-a), var(--completed-b));
                            --cb: Max(var(--completed-a), var(--completed-b));
                        
                            // breakdown of the below super-complex brain-breaking CSS math:
                            // "clamp" is used to ensure either "-1" or "1"
                            // "calc" is used to inflat the outcome into a huge number, to get rid of any value between -1 & 1
                            // if absolute diff of both completed % is above "5" (%)
                            // ".001" bumps the value just a bit, to avoid a scenario where calc resulted in "0" (then clamp will also be "0")
                            --thumbs-too-close: Clamp(
                            -1,
                            1000 * (Min(1, Max(var(--cb) - var(--ca) - 5, -1)) + 0.001),
                            1
                            );
                            --thumb-close-to-min: Min(1, Max(var(--ca) - 2, 0)); // 2% threshold
                            --thumb-close-to-max: Min(1, Max(98 - var(--cb), 0)); // 2% threshold
                        
                            @mixin thumb {
                            appearance: none;
                            height: var(--thumb-size);
                            width: var(--thumb-size);
                            transform: var(--thumb-transform);
                            border-radius: var(--thumb-radius, 50%);
                            background: var(--thumb-color);
                            box-shadow: var(--thumb-shadow);
                            border: none;
                            pointer-events: auto;
                            transition: 0.1s;
                            }
                        
                            display: inline-block;
                            height: Max(var(--track-height), var(--thumb-size));
                            // margin: calc((var(--thumb-size) - var(--track-height)) * -.25) var(--thumb-size) 0;
                            background: linear-gradient(
                                to right,
                                var(--ticks-color) var(--ticks-thickness),
                                transparent 1px
                            )
                            repeat-x;
                            background-size: var(--tickIntervalPerc) var(--ticks-height);
                            background-position-x: calc(
                            var(--thumb-size) / 2 - var(--ticks-thickness) / 2
                            );
                            background-position-y: var(--flip-y, bottom);
                        
                            padding-bottom: var(--flip-y, var(--ticks-gap));
                            padding-top: calc(var(--flip-y) * var(--ticks-gap));
                        
                            position: relative;
                            z-index: 1;
                        
                            &[data-ticks-position="top"] {
                            --flip-y: 1;
                            }
                        
                            // mix/max texts
                            &::before,
                            &::after {
                            --offset: calc(var(--thumb-size) / 2);
                            content: counter(x);
                            display: var(--show-min-max, block);
                            font: var(--min-max-font);
                            position: absolute;
                            bottom: var(--flip-y, -2.5ch);
                            top: calc(-2.5ch * var(--flip-y));
                            opacity: Clamp(0, var(--at-edge), var(--min-max-opacity));
                            transform: translateX(
                                calc(var(--min-max-x-offset) * var(--before, -1) * -1)
                                )
                                scale(var(--at-edge));
                            pointer-events: none;
                            }
                        
                            &::before {
                            --before: 1;
                            --at-edge: var(--thumb-close-to-min);
                            counter-reset: x var(--min);
                            left: var(--offset);
                            }
                        
                            &::after {
                            --at-edge: var(--thumb-close-to-max);
                            counter-reset: x var(--max);
                            right: var(--offset);
                            }
                        
                            &__values {
                            position: relative;
                            top: 50%;
                            line-height: 0;
                            text-align: justify;
                            width: 100%;
                            pointer-events: none;
                            margin: 0 auto;
                            z-index: 5;
                        
                            // trick so "justify" will work
                            &::after {
                                content: "";
                                width: 100%;
                                display: inline-block;
                                height: 0;
                                background: red;
                            }
                            }
                        
                            &__progress {
                            --start-end: calc(var(--thumb-size) / 2);
                            --clip-end: calc(100% - (var(--cb)) * 1%);
                            --clip-start: calc(var(--ca) * 1%);
                            --clip: inset(-20px var(--clip-end) -20px var(--clip-start));
                            position: absolute;
                            left: var(--start-end);
                            right: var(--start-end);
                            top: calc(
                                var(--ticks-gap) * var(--flip-y, 0) + var(--thumb-size) / 2 -
                                var(--track-height) / 2
                            );
                            //  transform: var(--flip-y, translateY(-50%) translateZ(0));
                            height: calc(var(--track-height));
                            background: var(--progress-background, #eee);
                            pointer-events: none;
                            z-index: -1;
                            border-radius: var(--progress-radius);
                        
                            // fill area
                            &::before {
                                content: "";
                                position: absolute;
                                // left: Clamp(0%, calc(var(--ca) * 1%), 100%); // confine to 0 or above
                                // width: Min(100%, calc((var(--cb) - var(--ca)) * 1%)); // confine to maximum 100%
                                left: 0;
                                right: 0;
                                clip-path: var(--clip);
                                top: 0;
                                bottom: 0;
                                background: var(--fill-color, black);
                                box-shadow: var(--progress-flll-shadow);
                                z-index: 1;
                                border-radius: inherit;
                            }
                        
                            // shadow-effect
                            &::after {
                                content: "";
                                position: absolute;
                                top: 0;
                                right: 0;
                                bottom: 0;
                                left: 0;
                                box-shadow: var(--progress-shadow);
                                pointer-events: none;
                                border-radius: inherit;
                            }
                            }
                        
                            & > input {
                            -webkit-appearance: none;
                            width: 100%;
                            height: var(--thumb-size);
                            margin: 0;
                            position: absolute;
                            left: 0;
                            top: calc(
                                50% - Max(var(--track-height), var(--thumb-size)) / 2 +
                                calc(var(--ticks-gap) / 2 * var(--flip-y, -1))
                            );
                            cursor: -webkit-grab;
                            cursor: grab;
                            outline: none;
                            background: none;
                        
                            &:not(:only-of-type) {
                                pointer-events: none;
                            }
                        
                            &::-webkit-slider-thumb {
                                @include thumb;
                            }
                            &::-moz-range-thumb {
                                @include thumb;
                            }
                            &::-ms-thumb {
                                @include thumb;
                            }
                        
                            &:hover {
                                --thumb-shadow: var(--thumb-shadow-hover);
                                & + output {
                                    visibility: visible;
                                    --value-background: var(--value-background-hover);
                                    --y-offset: -5px;
                                    color: var(--value-active-color);
                                    box-shadow: 0 0 0 3px var(--value-background);
                                }
                            }
                        
                            &:active {
                                --thumb-shadow: var(--thumb-shadow-active);
                                cursor: grabbing;
                                z-index: 2; // when sliding left thumb over the right or vice-versa, make sure the moved thumb is on top
                                + output {
                                    transition: 0s;
                                }
                            }
                        
                            &:nth-of-type(1) {
                                --is-left-most: Clamp(0, (var(--value-a) - var(--value-b)) * 99999, 1);
                                & + output {
                                &:not(:only-of-type) {
                                    --flip: calc(var(--thumbs-too-close) * -1);
                                }
                        
                                --value: var(--value-a);
                                --x-offset: calc(var(--completed-a) * -1%);
                                &::after {
                                    content: var(--prefix, "") var(--text-value-a) var(--suffix, "");
                                }
                                }
                            }
                        
                            &:nth-of-type(2) {
                                --is-left-most: Clamp(0, (var(--value-b) - var(--value-a)) * 99999, 1);
                                & + output {
                                --value: var(--value-b);
                                }
                            }
                        
                            // non-multiple range should not clip start of progress bar
                            &:only-of-type {
                                ~ .range-slider__progress {
                                --clip-start: 0;
                                }
                            }
                        
                            & + output {
                                visibility: hidden;
                                --flip: -1;
                                --x-offset: calc(var(--completed-b) * -1%);
                                --pos: calc(
                                ((var(--value) - var(--min)) / (var(--max) - var(--min))) * 100%
                                );
                        
                                pointer-events: none;
                                position: absolute;
                                z-index: 5;
                                background: var(--value-background);
                                border-radius: 10px;
                                padding: 2px 4px;
                                left: var(--pos);
                                transform: translate(
                                var(--x-offset),
                                calc(
                                    150% * var(--flip) - (var(--y-offset, 0px) + var(--value-offset-y)) *
                                    var(--flip)
                                )
                                );
                                transition: all 0.12s ease-out, left 0s;
                        
                                &::after {
                                content: var(--prefix, "") var(--text-value-b) var(--suffix, "");
                                font: var(--value-font);
                                }
                            }
                            }
                        }

                        .outputs {
                            display: flex;
                            margin-top: .5rem;
                            justify-content: space-between;
                            width: 100%;
                        }
                    }

                    > button {
                        background: $primary-color;
                        border-radius: 3px;
                        color: white;
                        padding: 0.5rem 1rem;
                        font-size: .9rem;
    
                        &:disabled {
                            background: rgba($text-color-alt, 0.5);
                        }
                    }
                }
            }

            form.change-address {
                display: flex;
                flex-direction: column;
                text-align: left;
                gap: 1rem;

                > .address {
                    display: flex;
                    border-radius: 3px;
                    border: 1px solid $border-color;
                    color: $text-color-alt;
                    grid-gap: 1rem;
                    padding: .5rem 1rem;
                    width: 100%;

                    .name {
                        font-weight: bold;
                    }

                    input {
                        appearance: auto;
                    }

                    label {
                        display: flex;
                        flex-direction: column;
                        gap: .3rem
                    }

                    input:checked + label {
                        color: $text-color;
                    }
                }

                button {
                    background: none;
                    border-radius: 3px;
                    border: 1px solid $border-color;
                    color: $text-color-alt;
                    font-size: 1rem;
                    margin-bottom: 0;
                    margin-top: 0;
                    padding: .5rem 1rem;
                    text-align: center;
                    transition: all .2s;

                    i {
                        margin-right: .3rem;
                    }

                    &:hover {
                        border-color: $primary-color;
                        color: $primary-color;
                    }
                }

                button[type="submit"] {
                    display: block;
                    background: #805fd3;
                    border-radius: 3px;
                    color: white;
                    font-size: 0.9rem;
                    padding: 0.7rem 1.4rem;
                    transition: all 0.2s;
                    -webkit-user-select: none;
                    user-select: none;

                    &:hover {
                        opacity: .9;
                    }
                }
            }

            .countdown {
                .radial-progress {
                    position: relative;

                    &.warning {
                        .value {
                            color: $warning-color;
                        }
                    }

                    &.error {
                        .value {
                            color: $negative-color;
                        }
                    }
        
                    .progress {
                        position: relative;
                        height: 170px;
                        width: 170px;
                        z-index: 1;
                    }
        
                    .track, .fill {
                        fill: rgba(0, 0, 0, 0);
                        stroke-width: 3;
                        transform: rotate(90deg) translate(0px, -80px);
                    }
        
                    .fill {
                        stroke-dasharray: 219.99078369140625;
                        stroke-dashoffset: -219.99078369140625;
                        stroke: $primary-color;
                        transition: stroke-dashoffset 1s ease-out;
                    }
        
                    .bg {
                        fill: rgba(0, 0, 0, 0);
                        stroke-dasharray: 219.99078369140625;
                        stroke-dashoffset: 0;
                        stroke-width: 3;
                        stroke: $background-light;
                        transform: rotate(90deg) translate(0px, -80px);
                        transition: stroke-dashoffset 2s;
                    }
        
                    .text {
                        display: flex;
                        position: absolute;
                        align-items: center;
                        flex-direction: column;
                        bottom: 0;
                        justify-content: center;
                        left: 0;
                        right: 0;
                        top: 0;
                        z-index: 10;
        
                        .value {
                            fill: $primary-color;
                            font-size: 1.5rem;
                            font-weight: bold;
                            margin-bottom: .3rem;
                        }
        
                        .title {
                            font-size: .8rem;
                        }
                    }
                }
            }

            .payments {
                display: flex;
                flex-direction: column;
                gap: 1rem;
                margin-top: 2rem;

                > div {
                    > span {
                        color: $text-color-alt;
                        font-size: .8rem;
                    }

                    .payment {
                        display: flex;
                        align-items: center;
                        border-radius: 3px;
                        border: 1px solid $border-color;
                        grid-gap: 0.5rem;
                        justify-content: center;
                        padding: 1rem;
                        transition: all 0.2s;
                        width: 100%;
    
                        img {
                            height: 28px;
                            width: 28px;
                        }
    
                        > div {
                            font-weight: bold;
    
                            span {
                                color: #7f7f7f;
                            }
                        }
                    }
                }
            }

            button.pay {
                display: block;
                -webkit-user-select: none;
                background: $primary-color;
                border-radius: 3px;
                color: white;
                font-weight: bold;
                padding: 1rem 2rem;
                margin-top: 1rem;
                text-transform: uppercase;
                transition: all 0.3s;
                user-select: none;
                width: 100%;

                &.disabled {
                    background: $divider-color;
                }
            }

            form.refund-order {
                .input-group {
                    &:has(input:focus, input:not([value=""])), &:has(textarea:focus, textarea:not(:empty)) {
                        > i {
                            &:first-child {
                                visibility: visible;
                            }
                        }
                    }

                    .crypto-amount {
                        position: absolute;
                        right: 0;
                        bottom: 0;
                        padding-bottom: 0.5rem;
                        font-size: .9rem;
                        color: $text-color-alt;
                        user-select: none;
                        line-height: 1.5;
                        border-bottom: 1px solid rgba(0,0,0,0);
                    }

                    input::-webkit-outer-spin-button,
                    input::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                    }

                    input[type="number"] {
                        -moz-appearance: textfield;
                    }
                }

                button {
                    width: 100%;
                }
            }

            .detail {
                display: flex;
                align-items: flex-start;
                flex-direction: column;
                margin-bottom: 1rem;

                .subtitle {
                    color: $text-color-alt;
                    font-size: .8rem;
                    margin-bottom: .3rem;
                }

                .value {
                    max-width: 300px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }

            .order-details {
                min-width: 300px;
                text-align: left;

                .detail {
                    display: flex;
                    align-items: flex-start;
                    flex-direction: column;
                    margin-bottom: 1rem;

                    &.no-hide {
                        .value {
                            overflow: unset;
                            text-overflow: unset;
                            white-space: unset;
                        }
                    }

                    .subtitle {
                        color: $text-color-alt;
                        font-size: .8rem;
                        margin-bottom: .3rem;
                    }

                    .value {
                        max-width: 300px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }

                button {
                    background: $primary-color;
                    color: white;
                    padding: .5rem 1rem;
                    border-radius: 3px;
                    width: 100%;

                    i {
                        margin-right: .3rem;
                    }

                    @media print {
                        display: none;
                    }
                }
            }

            .deposit-wrapper, .withdraw-wrapper, .swap-wrapper {
                .tab-wrapper {
                    display: inline-flex;
                    border-bottom: 1px solid $border-color;
                    gap: 1rem;
                    justify-content: center;

                    &+.token {
                        margin-top: 1rem;    
                    }

                    button.tab {
                        background: none;
                        border-bottom: 2px solid rgba(0,0,0,0);
                        color: $text-color;
                        font-weight: bold;
                        padding-bottom: .5rem;
                        transition: all .2s;
                        
                        &.active {
                            border-bottom: 2px solid $primary-color;
                        }
                    }
                }

                div.empty {
                    display: flex;
                    flex-direction: column;
                    gap: .5rem;
                    justify-content: center;
                    margin-top: 1rem;
                    opacity: .5;
                    user-select: none;

                    i {
                        font-size: 1.5rem;
                    }

                    span {
                        font-size: .9rem;
                    }
                }

                form {
                    display: flex;
                    flex-direction: column;
                    margin-top: 1rem;

                    label {
                        margin-bottom: .3rem;
                    }

                    .input-group {
                        position: relative;
                        margin-bottom: 1rem;
                        width: 100%;

                        .balance {
                            display: flex;
                            align-items: center;
                            gap: .5rem;
                            line-height: 1.5;

                            button {
                                color: white;
                                background: $primary-color;
                                border-radius: 30px;
                                font-size: .8rem;
                                line-height: 1.5;
                                padding: .3rem .6rem;
                            }
                        }

                        input {
                            padding: .5rem 1rem;
                            border: 1px solid $border-color;
                            border-radius: 3px;
                            padding-right: 60px;

                            &:focus {
                                border-color: $primary-color;
                            }
                        }

                        span {
                            position: absolute;
                            bottom: 0;
                            color: $primary-color;
                            font-size: .8rem;
                            font-weight: bold;
                            line-height: 25px;
                            padding: .5rem 1rem;
                            right: 0;
                            user-select: none;
                        }
                    }

                    .fee {
                        display: flex;
                        align-items: center;
                        color: $text-color-alt;
                        flex-direction: row;
                        font-size: .8rem;
                        line-height: 1.5;
                        margin-bottom: 1rem;
                        text-align: left;

                        i {
                            font-size: 1rem;
                            padding: 0 .3rem;
                        }

                        span {
                            font-weight: bold;
                            margin: 0 .3rem;
                        }
                    }
                }

                .qr-wrapper {
                    img, div.empty {
                        height: 200px;
                        width: 200px;
                        border: 1px solid $border-color;
                        border-radius: 3px;
                        margin: 1rem auto 0 auto;

                        &.empty {
                            display: flex;
                            align-items: center;
                            color: $text-color-alt;
                            flex-direction: column;
                            gap: .5rem;
                            justify-content: center;
                            transition: all .2s;

                            i {
                                color: $text-color-alt !important;
                                font-size: 2rem;
                            }

                            &:hover {
                                i {
                                    color: $primary-color !important;
                                }
                            }
                        }

                        i {
                            animation: rotation 1s infinite linear;
                            color: $primary-color;
                            font-size: 3rem;
                            transition: all .2s;

                            &.fa-spinner-third {
                                color: $primary-color !important;
                            }
                        }

                        @keyframes rotation {
                            from {
                                transform: rotate(0deg);
                            }
                
                            to {
                                transform: rotate(359deg);
                            }
                        }
                    }

                    .tag {
                        margin-top: 1rem;

                        span {
                            color: $text-color-alt;
                            font-size: .8rem;
                        }

                        div {
                            border-radius: 3px;
                            border: 1px solid $border-color;
                            overflow-wrap: break-word;
                            padding: .5rem 1rem;
                            line-height: 1;

                            button {
                                background: none;
                                transition: all .2s;
                                //font-weight: bold;
                                color: $primary-color;

                                &:hover {
                                    opacity: .9;
                                }
                            }
                        }
                    }
                }

                .notice {
                    font-size: .8rem;
                    color: $text-color-alt;
                    margin-top: 1rem;
                    line-height: 1.2;
                }
            }

            form {
                display: flex;
                //grid-gap: 2rem;
                width: 100%;

                .full {
                    width: 100% !important;
                }

                .input-group {
                    text-align: left;
                    margin-bottom: 2rem;
                    width: 48%;
                }

                table {
                    margin-bottom: 2rem;

                    tr {
                        display: flex;
                        align-items: center;
                        border-bottom: 1px solid $border-color;
                        border-radius: 3px;
                        flex-direction: row;
                        grid-gap: .5rem;
                        padding: .5rem 1rem;

                        &.add-row {
                            color: $text-color-alt;
                            cursor: pointer;
                            font-size: .8rem;

                            &:hover {
                                background: none;
                                color: $primary-color;
                            }

                            td {
                                grid-gap: .5rem;
                                justify-content: center;
                            }
                        }
            
                        &:hover {
                            background: $background-light;
                        }
            
                        &.table-header {
                            background: $background-light;
                            border-radius: 3px 3px 0px 0px;
                            color: $text-color-alt;
            
                            th {
                                font-size: .9rem;
                                font-weight: normal;
                                text-align: left;
                                width: 100%;
            
                                &.long {
                                    min-width: 200px;
                                }
                            }
                        }
            
                        &.empty {
                            color: $text-color-alt;
                            font-size: .9rem;
                            justify-content: center;
            
                            &:hover {
                                background: unset;
                            }
                        }
            
                        td {
                            display: flex;
                            align-items: center;
                            grid-gap: 1rem;
                            width: 100%;
            
                            &.long {
                                min-width: 200px;
                            }
            
                            &.asset {
                                img {
                                    width: 28px;
                                    height: 28px;
                                }
            
                                div {
                                    transition: all .3s;
            
                                    &:hover {
                                        //color: $primary-color;
                                        text-decoration: underline;
                                    }
                                }
                            }

                            input {
                                width: 100%;
                            }

                            select {
                                display: block;
                                font-size: 1rem;
                                transition: all 0.3s;
                                width: 100%;
                                padding: 0 .3rem;

                                option {
                                    line-height: 1.5;
                                    border-radius: 3px;
                                }
                            }
            
                            button {
                                background: $primary-color;
                                border-radius: 3px;
                                color: white;
                                font-size: .9rem;
                                padding: .3rem .9rem;
                                transition: all .3s;
            
                                &:hover {
                                    opacity: .9;
                                }
                            }
                        }

                        td + i {
                            display: flex;
                            align-items: center;
                            color: #7f7f7f;
                            transition: all 0.2s;

                            &:hover {
                                color: $negative-color;
                            }
                        }
                    }
                }
            }

            a {
                color: $text-color-alt;
                margin-top: -1rem;
                margin-bottom: 2rem;
                font-size: .9rem;
                text-align: left;
                width: 100%;

                &:hover {
                    color: $primary-color;
                }
            }

            i.close {
                position: absolute;
                color: $text-color;
                cursor: pointer;
                margin: 1rem;
                right: 0;
                top: 0;
                transition: all .2s;

                &:hover {
                    color: $negative-color;
                }
            }
        }
    }
}
