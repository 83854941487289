section.become-seller {
    margin-bottom: 4rem;

    @media only screen and (max-width: 600px) {
        margin-bottom: 0;
    }

    .page-header {
        color: $text-color;
        background: rgba($background-light, 0.5);
        text-align: center;
        font-weight: bold;
        font-size: 1.8rem;
        padding: 3rem 0;
        border-bottom: 1px solid $border-color;

        @media only screen and (max-width: 600px) {
            font-size: 1.5rem;
            padding: 1rem 0;
        }
    }

    > .container, > .container-full {
        color: $text-color;
        margin: 0 auto;
        padding: 2rem 1rem;
        width: 100%;

        @media only screen and (max-width: 600px) {
            padding: 1rem 1rem 4rem 1rem;
        }

        .intro, .how-to, .fees, .start-selling {
            display: flex;
            color: $text-color;
            gap: 8rem;
            justify-content: space-between;
            margin: 4rem auto;
            max-width: 1280px;

            &.fees, &.start-selling {
                justify-content: center;
                text-align: center;

                .text {
                    align-items: center;
                    gap: 1rem;
                }
            }

            .text {
                display: flex;
                align-items: flex-start;
                flex-direction: column;
                gap: 2rem;
                justify-content: center;
                width: 90%;

                .title {
                    font-size: 2.5rem;
                    color: $text-color;
                    font-weight: bold;
                }

                .description {
                    line-height: 1.5;
                }

                button {
                    background: none;
                    border-radius: 3px;
                    border: 1px solid $text-color;
                    color: $text-color;
                    padding: .5rem 1rem;
                    transition: all .2s;

                    &:hover {
                        background: rgba($text-color, 0.05);
                    }
                }

                .steps {
                    display: grid;
                    gap: 2rem;
                    grid-template-columns: 1fr 1fr;

                    .step {
                        display: flex;
                        gap: 1rem;
                        align-items: center;

                        i {
                            font-size: 2rem;
                        }

                        p {
                            font-weight: bold;
                        }
                    }
                }

                .fee-info {
                    display: flex;
                    gap: 8rem;
                    margin-top: 3rem;

                    .fee {
                        display: flex;
                        flex-direction: column;
                        gap: .5rem;

                        .number {
                            color: $text-color-alt;
                            font-size: 4rem;
                            font-weight: bold;
                        }

                        .title {
                            font-size: 1.5rem;
                        }

                        .description {

                        }
                    }
                }

                a {
                    background: $primary-color;
                    border-radius: 3px;
                    color: white;
                    padding: .5rem 1rem;
                    transition: all .2s;

                    &:hover {
                        opacity: .9;
                    } 
                }
            }

            .image {
                overflow: hidden;
                width: 100%;

                img {
                    border-radius: 6px;
                    width: 100%;
                }
            }
        }
    }
}