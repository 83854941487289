.content-wrapper{
    //overflow: auto;

    section.staking {
        div.migration-notice {
            display: flex;
            align-items: center;
            background: rgba($warning-color, 0.1);
            border-radius: 3px;
            border: 1px solid $warning-color;
            color: $text-color-alt;
            gap: 1rem;
            justify-content: space-between;
            margin-bottom: 2rem;
            padding: 1rem;

            .title {
                font-size: 1.2rem;
                font-weight: bold;
            }

            span {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                gap: .3rem;
                line-height: 20px;
                margin-top: .5rem;

                p {
                    font-weight: bold;
                }

                img {
                    height: 20px;
                    width: 20px;
                }
            }

            button.migrate {
                background: $primary-color;
                border-radius: 3px;
                color: white;
                padding: .5rem 2rem;
            }
        }

        table {
            overflow-x: auto;
            table-layout: fixed;
            width: 100%;

            @media only screen and (max-width: 600px) {
                overflow-x: unset;
            }
            
            tr {
                display: flex;
                align-items: center;
                border-bottom: 1px solid $border-color;
                border-radius: 3px;
                flex-direction: row;
                grid-gap: .5rem;
                min-height: 3rem;
                padding: .5rem 1rem;

                @media only screen and (max-width: 600px) {
                    padding-right: 2rem;
                }

                &.click:first-of-type {
                    border-top: 1px solid $border-color;
                }

                &:hover {
                    background: $background-light;
                }

                &.expand {
                    display: flex;
                    background: #fbfbfb;
                    gap: 1rem;
                    justify-content: space-around;
                    padding: 1rem;

                    .expand-gr {
                        display: flex;
                        align-items: center;
                        flex-direction: column;
                        gap: .3rem;

                        .subtitle {
                            font-size: .8rem;
                        }
                    }
                }

                &.collapse {
                    display: none;
                }

                &.table-header {
                    display: flex;
                    background: $background-light;
                    border-radius: 3px 3px 0px 0px;
                    color: $text-color-alt;
                    padding-left: 1rem;

                    td {
                        //min-width: 100px;
                        font-size: .9rem;
                        font-weight: normal;
                        text-align: left;
                        width: 100%;
                    }
                }

                &.empty {
                    color: $text-color-alt;
                    font-size: .9rem;
                    justify-content: center;

                    &:hover {
                        background: unset;
                    }

                    td {
                        justify-content: center;
                    }
                }

                input[type="checkbox"] {
                    appearance: checkbox;
                    flex-shrink: 0;
                    font-size: 14px;
                    height: 14px;
                    margin-right: 1rem;
                    min-height: 14px;
                    min-width: 14px;
                    width: 14px;
                }

                td {
                    display: inline-flex;
                    align-items: center;
                    font-size: .9rem;
                    grid-gap: .3rem;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    width: 100%;

                    &.long {
                        width: 150%;

                        @media only screen and (max-width: 480px) {
                            width: 200%;
                        }
                    }

                    &.short {
                        width: 60%;

                        @media only screen and (max-width: 600px) {
                            width: 75%;
                        }

                        @media only screen and (max-width: 420px) {
                            width: 100%;
                        }
                    }

                    &.justify-center {
                        @media only screen and (max-width: 380px) {
                            justify-content: center;
                        }
                    }

                    &.justify-end {
                        @media only screen and (max-width: 600px) {
                            width: 50%;
                        }

                        @media only screen and (max-width: 380px) {
                            justify-content: flex-end;
                        }
                    }

                    &.green {
                        color: $positive-color;
                    }

                    &.amber {
                        color: $warning-color;
                    }

                    &.bold {
                        font-weight: bold;
                    }

                    &.asset {
                        img {
                            width: 28px;
                            height: 28px;
                        }

                        div {
                            transition: all .3s;

                            &:hover {
                                //color: $primary-color;
                                text-decoration: underline;
                            }
                        }
                    }

                    img {
                        height: 18px;
                        width: 18px;
                    }

                    button {
                        display: flex;
                        align-items: center;
                        background: $primary-color;
                        border-radius: 3px;
                        color: white;
                        font-size: .9rem;
                        gap: .3rem;
                        justify-content: center;
                        padding: .3rem .9rem;
                        transition: all .3s;
                        width: 100%;

                        &:hover {
                            opacity: .9;
                        }

                        &:disabled {
                            background: $divider-color;
                        }

                        i {
                            color: white;
                        }
                    }
                }
            }
        }

        div.summary {
            display: grid;
            align-items: center;
            border-radius: 6px;
            border: 1px solid $border-color;
            gap: 1rem;
            grid-template-columns: repeat(4, 1fr);
            justify-content: space-between;
            justify-items: center;
            margin-bottom: 4rem;
            padding: 1rem 2rem;

            @media only screen and (max-width: 830px) {
                gap: 0;

                span {
                    border-right: none !important;
                }
            }

            @media only screen and (max-width: 768px) {
                display: flex;
                gap: .5rem;
                flex-direction: column;
                padding: .5rem 0;

                span {
                    border-bottom: 1px solid $border-color;
                    padding-bottom: .5rem;
                }
            }

            span {
                display: flex;
                align-items: center;
                border-right: 1px solid $border-color;
                color: $text-color-alt;
                flex-direction: column;
                justify-content: center;
                position: relative;
                width: 100%;

                &:last-of-type {
                    border: none;
                    padding-bottom: 0;
                }

                > i {
                    line-height: 24px;
                }
        
                div.subtitle {
                    font-size: .8rem;
                }

                p {
                    display: flex;
                    align-items: center;
                    color: $text-color;
                    flex-wrap: wrap;
                    font-weight: bold;
                    gap: .3rem;
                    line-height: 24px;
                    position: relative;

                    div.soon {
                        //position: absolute;
                        background: $primary-color;
                        border-radius: 6px;
                        border: 1px solid $primary-color;
                        color: white;
                        font-size: 0.7rem;
                        opacity: 0.5;
                        padding: 0 0.3rem;
                        right: 0.5rem;
                        top: 0.5rem;
                    }

                    img {
                        height: 20px;
                        width: 20px;
                    }

                    i {
                        &[class="fas fa-gem 5"] {
                            color: #0f52ba;
                        }

                        &[class="fas fa-gem 4"] {
                            color: #33ccff;
                        }

                        &[class="fas fa-gem 3"] {
                            color: #ffd700;
                        }

                        &[class="fas fa-gem 2"] {
                            color: #d7d7d7;
                        }

                        &[class="fas fa-gem 1"] {
                            color: #ad8a56;
                        }

                        &[class="fas fa-gem 0"] {
                            color: #eeeeee;
                        }

                        &.fa-info-circle {
                            font-size: .8rem;
                        }
                    }
                }
            }
        }

        div.add {
            margin-bottom: 4rem;
            
            .slider-wrapper {
                display: flex;
                flex-direction: column;
                gap: 1rem;

                .inputs {
                    display: flex;
                    justify-content: space-between;

                    > div {
                        display: flex;
                        flex-direction: column;

                        &.shortcuts {
                            flex-direction: row;
                        }

                        span {
                            color: $text-color-alt;
                            font-size: .8rem;
                        }

                        input {
                            -moz-appearance: textfield;
                            color: $text-color;
                            font-weight: bold;
                            line-height: 2;

                            &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
                                -webkit-appearance: none;
                                margin: 0;
                            }
                        }

                        i {
                            width: 16px;
                            line-height: 2;
                        }
                    }

                    .shortcuts {
                        display: flex;
                        gap: .5rem;
                        padding-top: 14.7px;

                        button {
                            background: white;
                            border-radius: 3px;
                            border: 1px solid $border-color;
                            font-size: .9rem;
                            line-height: 1;
                            padding: .3rem .6rem;
                        }
                    }
                }

                .slider {
                    display: flex;
                    align-items: stretch;
                    flex-direction: column;
                    grid-gap: 1rem;
                    width: 100%;

                    input[type="range"] {
                        -webkit-appearance: none;
                        -webkit-transition: .2s;
                        background: $border-color;
                        border-radius: 5px;
                        height: .5rem;
                        margin-bottom: .5rem;
                        opacity: 1;
                        outline: none;
                        transition: opacity .2s;
                        width: 100%;

                        &::-webkit-slider-thumb {
                            -webkit-appearance: none;
                            appearance: none;
                            background: $primary-color;
                            border-radius: 50%;
                            cursor: pointer;
                            height: 1.5rem;
                            width: 1.5rem;
                        }

                        &::-moz-range-thumb {
                            background: $primary-color;
                            border-radius: 50%;
                            cursor: pointer;
                            height: 25px;
                            width: 25px;
                        }
                    }
                }
            }

            .duration-wrapper {
                display: flex;
                justify-content: space-between;
                margin-top: 1rem;

                @media only screen and (max-width: 330px) {
                    flex-direction: column;
                    gap: .5rem;
                }

                > div {
                    display: flex;
                    flex-direction: column;
                    line-height: 2;
                    align-items: flex-start;

                    span {
                        color: $text-color-alt;
                        font-size: .8rem;
                    }

                    > div {
                        display: flex;
                        line-height: 2;

                        &.mobile {
                            display: none;
                            position: relative;

                            @media only screen and (max-width: 330px) {
                                width: 100%;
                            }
                        }

                        select {
                            background: none;
                            border-radius: 3px;
                            border: 1px solid $border-color;
                            color: $text-color;
                            font-size: .9rem;
                            font-weight: bold;
                            padding: .4rem .8rem;
                            padding-right: 2rem;

                            & + i {
                                position: absolute;
                                bottom: 0;
                                font-size: .8rem;
                                line-height: 32px;
                                right: 12px;
                                top: 0;
                                z-index: -1;
                            }

                            @media only screen and (max-width: 330px) {
                                text-align: center;
                                padding-right: 0;
                                width: 100%;
                            }
                        }

                        label {
                            background: white;
                            border-radius: 3px;
                            border: 1px solid $border-color;
                            cursor: pointer;
                            font-size: .9rem;
                            font-weight: bold;
                            margin-right: .5rem;
                            padding: 0 .8rem;
                            transition: all .2s;
                            user-select: none;

                            &:hover {
                                border-color: $primary-color;
                            }
                        }

                        input:checked + label {
                            border: 1px solid $primary-color;
                        }
                    }

                    p {
                        border-radius: 3px;
                        border: 1px solid $border-color;
                        font-size: .9rem;
                        font-weight: bold;
                        line-height: 2;
                        padding: 0 .8rem;
                        text-align: center;

                        @media only screen and (max-width: 330px) {
                            width: 100%;
                        }
                    }

                    > i {
                        width: 16px;
                        line-height: 2;
                    }
                }
            }

            button.add {
                background: $primary-color;
                border-radius: 3px;
                color: white;
                margin-top: 1rem;
                padding: .5rem 1rem;
                transition: all .2s;
                width: 100%;

                &:hover {
                    opacity: .9;
                }
            }
        }

        div.stakes {
            margin-bottom: 4rem;
            overflow-x: auto;

            button.mass-action {
                display: inline-flex;
                align-items: center;
                background: $primary-color;
                border-radius: 3px;
                color: white;
                font-size: .9rem;
                gap: .3rem;
                justify-content: center;
                margin-top: 1rem;
                margin-right: 1rem;
                padding: .5rem 1rem;
                transition: all .3s;

                &:hover {
                    opacity: .9;
                }

                &:disabled {
                    background: $divider-color;
                }

                i {
                    color: white;
                }
            }
        }

        div.rewards {
            margin-bottom: 4rem;
            overflow-x: auto;

            .split {
                display: flex;
                align-items: center;
                gap: 2rem;
                margin-bottom: 1rem;
                padding: 0 1rem;

                @media only screen and (max-width: 980px) {
                    padding: 0;
                    gap: 1rem;
                }

                @media only screen and (max-width: 890px) {
                    flex-direction: column;
                }

                > div {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    gap: .5rem;
                    width: 100%;

                    &.divider {
                        background: $divider-color;
                        height: 2rem;
                        width: 1px;
                    }

                    > div {
                        .title {
                            font-size: .9rem;
                            margin-bottom: .3rem;
                        }
        
                        .subtitle {
                            display: flex;
                            //font-size: 1.2rem;
                            align-items: center;
                            font-weight: bold;
                            gap: .3rem;
        
                            img {
                                height: 20px;
                                width: 20px;
                            }
                        }
                    }

                    button {
                        display: flex;
                        align-items: center;
                        background: $primary-color;
                        border-radius: 3px;
                        color: white;
                        font-size: .9rem;
                        gap: .3rem;
                        justify-content: center;
                        padding: .5rem 1rem;
                        transition: all .3s;

                        &.b340 {
                            display: none;
                        }

                        &:hover {
                            opacity: .9;
                        }

                        i {
                            color: white;
                        }
                    }

                    .switch {
                        display: inline-block;
                        position: relative;
                        height: 34px;
                        width: 60px;

                        input {
                            opacity: 0;
                            width: 0;
                            height: 0;
                        }

                        input:checked + .slider {
                            background-color: $primary-color;
                        }

                        input:focus + .slider {
                            box-shadow: 0 0 1px $primary-color;
                        }

                        input:checked + .slider:before {
                            -webkit-transform: translateX(26px);
                            -ms-transform: translateX(26px);
                            transform: translateX(26px);
                        }

                        .slider {
                            position: absolute;
                            -webkit-transition: .4s;
                            background-color: #ccc;
                            border-radius: 34px;
                            bottom: 0;
                            cursor: pointer;
                            left: 0;
                            right: 0;
                            top: 0;
                            transition: .4s;

                            &:before {
                                position: absolute;
                                -webkit-transition: .4s;
                                background-color: white;
                                border-radius: 50%;
                                bottom: 4px;
                                content: "";
                                height: 26px;
                                left: 4px;
                                transition: .4s;
                                width: 26px;
                            }
                        }
                    }
                }
            }
        }

        div.unstakes {
            overflow-x: auto;

            @media only screen and (max-width: 600px) {
                td.amber {
                    justify-content: center;
                }
            }

            button.mass-action {
                display: inline-flex;
                align-items: center;
                background: $primary-color;
                border-radius: 3px;
                color: white;
                font-size: .9rem;
                gap: .3rem;
                justify-content: center;
                margin-top: 1rem;
                margin-right: 1rem;
                padding: .5rem 1rem;
                transition: all .3s;

                &:hover {
                    opacity: .9;
                }

                &:disabled {
                    background: $divider-color;
                }

                i {
                    color: white;
                }
            }
        }
    }
}