section.reg-address {
    display: block;

    form {
        @media only screen and (max-width: 420px) {
            flex-direction: column;
            width: 100%;

            .input-group {
                width: 100%;
            }
        } 
    }
}

section.address-cards {
    ul {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        grid-gap: 1rem;
        justify-content: space-between;

        @media only screen and (max-width: 1100px) {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
        }

        @media only screen and (max-width: 500px) {
            grid-template-columns: repeat(1, 1fr);
        }

        li {
            display: flex;
            border: 1px solid $border-color;
            border-radius: 3px;
            flex-direction: column;
            grid-gap: .5rem;
            height: 200px;
            justify-content: center;
            padding: 1rem;
            width: 49%;

            @media only screen and (max-width: 1100px) {
                width: 100%;
            }

            &.add-new {
                display: flex;
                align-items: center;
                cursor: pointer;
                transition: all .3s;
                color: $text-color-alt;

                &:hover {
                    color: $primary-color;
                    border-color: $primary-color;
                }

                i {
                    font-size: 1.5rem;
                }

                div {
                    font-weight: bold;
                }
            }

            div {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;

                &.bold {
                    font-weight: bold;
                }

                &.actions {
                    display: flex;
                    grid-gap: .5rem;

                    button {
                        width: 100%;
                        background: $primary-color;
                        border-radius: 3px;
                        color: white;
                        font-size: .9rem;
                        padding: .3rem .6rem;
                        transition: all .3s;

                        &:hover {
                            opacity: .9;
                        }

                        i {
                            margin-right: .5rem;
                            animation: rotation 1s infinite linear;
                        }
                    
                        @keyframes rotation {
                            from {
                                transform: rotate(0deg);
                            }
                            to {
                                transform: rotate(359deg);
                            }
                        }
                    }
                }
            }
        }
    }
}