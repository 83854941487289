section.cart {
    .page-header {
        color: $text-color;
        background: rgba($background-light, 0.5);
        text-align: center;
        font-weight: bold;
        font-size: 1.8rem;
        padding: 3rem 0;
        border-bottom: 1px solid $border-color;

        @media only screen and (max-width: 600px) {
            font-size: 1.5rem;
            padding: 1rem 0;
        }
    }

    > .container {
        padding: 2rem 1rem;
        color: $text-color;

        @media only screen and (max-width: 600px) {
            padding: 1rem 1rem 4rem 1rem;
        }

        

        .cart-wrapper {
            display: grid;
            grid-gap: 2rem;
            grid-template-columns: 3fr 1fr;
            margin-top: 2rem;
            min-height: 40rem;

            @media only screen and (max-width: 768px) {
                display: flex;
                flex-direction: column;
                margin-top: 1rem;
                gap: 0;
            }

            .cart-contents {
                table {
                    width: 100%;
                    
                    tr {
                        th {
                            text-align: left;
                            border-bottom: 1px solid $border-color;
                            padding-top: 1rem;
                            padding-bottom: .5rem;
                            padding-right: 2rem;
                            white-space: nowrap;

                            @media only screen and (max-width: 420px) {
                                padding-right: 1rem;

                                &:last-of-type {
                                    padding-right: 0;
                                }
                            }

                            @media only screen and (max-width: 360px) {
                                &.tc360 {
                                    padding-right: 0;
                                }
                            }
                        }

                        td {
                            padding: 1rem 0;
                            padding-right: 2rem;
                            border-bottom: 1px solid $border-color;

                            @media only screen and (max-width: 420px) {
                                padding-right: 1rem;
                            }

                            .qty {
                                margin-top: 1rem;
                            }

                            .total {
                                font-weight: bold;
                                margin-top: 1rem;
                            }

                            div.empty {
                                font-size: .9rem;
                            }

                            &:last-of-type {
                                padding-right: 0;
                            }

                            &.product {
                                display: flex;
                                grid-gap: 1rem;

                                .thumbnail {
                                    display: inline-block;
                                    background: white;
                                    border: 1px solid #eeeeee;
                                    border-radius: 3px;
                                    min-height: 106.25px;
                                    min-width: 106.25px;
                                    overflow: hidden;
                                    position: relative;
                                    transition: all 0.3s;

                                    &:hover {
                                        border-color: $primary-color;
                                    }

                                    img {
                                        position: absolute;
                                        max-width: 100%;
                                        max-height: 106.25px;
                                        margin: auto;
                                        left: 0;
                                        top: 0;
                                        right: 0;
                                        bottom: 0;
                                    }
                                }

                                .text {
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: center;
                                    grid-gap: 0.5rem;

                                    .title {
                                        display: -webkit-box;
                                        -webkit-box-orient: vertical;
                                        -webkit-line-clamp: 2;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        font-weight: bold;
                                        transition: all .3s;

                                        &:hover {
                                            color: $primary-color;
                                        }
                                    }

                                    span.variation {
                                        display: flex;
                                        font-size: .8rem;
                                        gap: .3rem;
                                    }

                                    .stock {
                                        .in-stock {
                                            color: $positive-color;
                                        }

                                        .out-stock {
                                            color: $negative-color;
                                        }

                                        i {
                                            margin-right: .3rem;
                                            font-weight: bold;
                                        }
                                    }
                                }
                            }

                            &.quantity {
                                font-size: 1rem;
                                user-select: none;

                                i {
                                    cursor: pointer;
                                    font-size: 1rem;
                                    z-index: 1002;

                                    &.fa-plus-circle:hover {
                                        color: $positive-color;
                                    }

                                    &.fa-minus-circle:hover {
                                        color: $negative-color;
                                    }
                                }
                            }

                            &.total {
                                font-weight: bold;
                            }
                        }
                    }
                }

                .cart-actions {
                    display: flex;
                    margin-top: 1rem;
                    justify-content: flex-end;

                    .shopping, .clear {
                        display: block;
                        -webkit-user-select: none;
                        background: none;
                        border-radius: 3px;
                        border: 1px solid $text-color-alt;
                        color: $text-color-alt;
                        font-weight: bold;
                        padding: 1rem 2rem;
                        text-transform: uppercase;
                        transition: all .3s;
                        user-select: none;

                        &.secondary {
                            @media only screen and (max-width: 768px) {
                                padding: .5rem 1rem;
                            }
                        }

                        &:hover {
                            background: $text-color-alt;
                            color: white;
                        }

                        i {
                            margin-right: .3rem;
                        }
                    }
                }
            }

            .cart-sidebar {
                position: sticky;
                align-self: flex-start;
                top: 1rem;

                @media only screen and (max-width: 768px) {
                    width: 100%;
                }

                .cart-summary {
                    border: 1px solid $border-color;
                    border-radius: 3px;
                    padding: 1rem;

                    .subtitle {
                        font-weight: bold;
                        text-align: left;
                        border-bottom: 1px solid $border-color;
                        padding-bottom: .5rem;
                        white-space: nowrap;
                    }

                    .delivery-estimate {
                        padding: 1rem 0;
                        display: flex;
                        align-items: center;
                        grid-gap: .5rem;
                        border-bottom: 1px solid #eeeeee;


                        i {
                            display: flex;
                            color: #805fd3;
                            font-size: 1.5rem;
                            justify-content: center;
                            min-width: 30px;
                        }

                        p {
                            font-size: .9rem;
                        }
                    }

                    .total {
                        margin-top: 1rem;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        //border-top: 1px solid $border-color;
                        // padding-top: 1rem;

                        p {
                            font-weight: bold;
                        }

                        span {
                            font-weight: bold;
                            color: $primary-color;
                            font-size: 1.4rem;
                        }
                    }

                    .checkout {
                        display: block;
                        -webkit-user-select: none;
                        background: $primary-color;
                        border-radius: 3px;
                        color: white;
                        font-weight: bold;
                        padding: 1rem 2rem;
                        margin-top: 1rem;
                        text-transform: uppercase;
                        transition: all .3s;
                        user-select: none;
                        width: 100%;

                        &.disabled {
                            background: $divider-color;
                        }

                        &:hover {
                            opacity: .9;
                        }

                        i {
                            margin-left: .3rem;
                        }
                    }
                }

                .cart-voucher {
                    border: 1px solid $border-color;
                    border-radius: 3px;
                    padding: 1rem;
                    margin-top: 2rem;

                    @media only screen and (max-width: 768px) {
                        margin-top: 1rem;
                    }

                    .subtitle {
                        font-weight: bold;
                        text-align: left;
                        border-bottom: 1px solid $border-color;
                        padding-bottom: .5rem;
                        white-space: nowrap;
                    }

                    .input-group {
                        display: flex;
                        margin-top: 1rem;
                        grid-gap: 1rem;
                        width: 100%;

                        input {
                            border-radius: 3px;
                            border: 1px solid $border-color;
                            padding: .5rem 1rem;
                            transition: all .2s;
                            width: 70%;
                        }

                        button {
                            display: block;
                            -webkit-user-select: none;
                            background: white;
                            border: 1px solid $text-color-alt;
                            border-radius: 3px;
                            color: $text-color-alt;
                            //font-weight: bold;
                            padding: .5rem 1rem;
                            //text-transform: uppercase;
                            transition: all 0.3s;
                            width: 30%;

                            &:hover {
                                background: $text-color-alt;
                                color: white;
                            }
                        }
                    }
                }
            }
        }
    }
}
