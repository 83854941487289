div.error-modal {
    display: block;
    background: rgba(0, 0, 0, 0.5);
    height: 100%;
    left: 0;
    opacity: 0;
    position: fixed;
    text-align: center;
    top: 0;
    transition: all .2s;
    visibility: hidden;
    width: 100%;
    z-index: 1001;

    &.success {
        opacity: 1;
        visibility: visible;

        i.status {
            color: $positive-color;

            &:before {
                content: "\f058" !important;
            }
        }
    }

    &.warning {
        opacity: 1;
        visibility: visible;

        i.status {
            color: $warning-color;

            &:before {
                content: "\f06a" !important;
            }
        }
    }

    &.error {
        opacity: 1;
        visibility: visible;

        i.status {
            color: $negative-color;

            &:before {
                content: "\f06a" !important;
            }
        }
    }

    &.fixed {
        i.close {
            display: none;
        }
    }

    div.content {
        display: inline-block;
        background: white;
        border-radius: 6px;
        max-width: 600px;
        padding: 2rem;
        position: absolute;
        text-align: center;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        @media only screen and (max-width: 768px) {
            max-width: 95vw;
            width: 90vw;
        }

        i.status {
            display: block;
            font-size: 1.5rem;
            margin-bottom: 1rem;
            transition: all .2s;

            &:before {
                content: "\f06a";
            }
        }

        div.text {
            display: block;
            color: $text-color;

            a {
                color: $primary-color;
                font-weight: bold;
            }
        }

        i.close {
            position: absolute;
            color: $text-color;
            cursor: pointer;
            margin: 1rem;
            right: 0;
            top: 0;
            transition: all .2s;

            &:hover {
                color: $negative-color;
            }
        }
    }
}
