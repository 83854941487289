@font-face {
    font-family: united-kingdom;
    font-display: swap;
    src: url("/assets/fonts/unitedkingdom/United-Kingdom.otf");
    font-weight: bold;
}

.account-mobile {
    display: none;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100vw;
    z-index: 1000;

    @media print {
        display: none;
    }

    > .container {
        display: flex;
        background: white;
        border-top: 1px solid $border-color;
        gap: 1rem;
        justify-content: space-around;
        overflow-x: scroll;
        padding: 1rem;
        width: 100%;

        @media only screen and (max-width: 420px) {
            padding: 1rem 0;
            gap: 0;
        }

        .action {
            display: flex;
            align-items: center;
            color: $text-color;
            flex-direction: column;
            gap: .5rem;
            transform: all .2s;
            width: 80px;
            position: relative;

            @media only screen and (max-width: 420px) {
                width: 85px;
            }

            &.active {
                i {
                    color: $primary-color;
                }

                p {
                    color: $text-color;
                }
            }

            i {
                color: $divider-color;
                font-size: 1.5rem;
                transform: all .2s;
            }

            p {
                color: $text-color-alt;
                transform: all .2s;
            }

            span {
                position: absolute;
                background: #805fd3;
                border: solid 2px white;
                border-radius: 200px;
                color: white;
                font-size: .7rem;
                text-align: center;
                line-height: 2;
                right: 11%;
                transform:translateY(-14px);
                top: 0%;
                width: 1.7rem;
                height: 1.7rem;

                &.hidden {
                    display: none;
                }
            }
        }
    }

    @media only screen and (max-width: 600px) {
        display: block;
    }
}

.header {
    background: white;
    color: $text-color;
    letter-spacing: .3px;

    @media print {
        display: none;
    }

    .header-top {
        background: $background-light;
        font-size: 1rem;
        text-transform: capitalize;

        .container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            max-width: 1350px;

            @media only screen and (max-width: 1280px) {
                justify-content: center;
            }

            .header-top-left {
                p.welcome {
                    font-size: .7rem;
                    padding: 1rem 0;
                    text-transform: uppercase;
                }
            }

            .header-top-right {
                display: flex;
                align-items: center;

                .dropdown {
                    position: relative;
                    font-size: .7rem;
                    margin-left: 2rem;
                    padding: 1rem 0;

                    &:hover {
                        .dropdown-box {
                            opacity: 1;
                            visibility: visible;
                            
                        }
                    }

                    > .click {
                        display: flex;
                        align-items: center;
                        grid-gap: .3rem;

                        &:after {
                            display: inline-block;
                            content: "\f078";
                            font-family: "Font Awesome 5 Pro";
                            font-size: 90%;
                            font-weight: 900;
                        }
                    }

                    .dropdown-box {
                        position: absolute;
                        background-color: white;
                        border-radius: 0 0 3px 3px;
                        box-shadow: 0px 4px 20px -8px rgb(0 0 0 / 25%);
                        line-height: 1;
                        opacity: 0;
                        right: 0;
                        text-align: center;
                        top: 44px;
                        transition: all .2s ease-out;
                        visibility: hidden;
                        width: fit-content;
                        z-index: 1000;

                        &:before {
                            position: absolute;
                            border: 10px solid transparent;
                            border-bottom: 10px solid white;
                            bottom: 100%;
                            content: "";
                            cursor: pointer;
                            top: auto;
                            transform: translateX(-50%);
                        }

                        > .click {
                            display: flex;
                            align-items: center;
                            grid-gap: .3rem;
                            justify-content: center;
                            padding: .5rem 0;
                            text-transform: uppercase;

                            &:hover {
                                color: $primary-color;
                            }
                        }
                    }
                }

                .divider {
                    width: 1px;
                    height: 1rem;
                    background: $divider-color;
                    margin-left: 2rem;
                }

                .menu {
                    display: inline-block;
                    align-items: center;
                    font-size: .7rem;
                    margin-left: 2rem;
                    padding: 1rem 0;

                    .click {
                        display: flex;
                        align-items: center;
                        grid-gap: 0.3rem;
                        justify-content: space-between;
                        text-transform: none;

                        i {
                            font-size: .8rem;
                            line-height: 1px;
                            margin: auto;

                            &.fa-bell-on {
                                animation: notification-ring 3s cubic-bezier(0.25, 1, 0.5, 1) 5s 3;

                                @keyframes notification-ring {
                                    0%, 30%, 100% {
                                        transform: translateX(0%);
                                        transform-origin: 50% 50%;
                                    }
                                
                                    5% {
                                        transform: translateX(-10px) rotate(6deg);
                                    }
                                
                                    10% {
                                        transform: translateX(5px) rotate(-6deg);
                                    }
                                
                                    15% {
                                        transform: translateX(-5px) rotate(3.6deg);
                                    }
                                
                                    20% {
                                        transform: translateX(2px) rotate(-2.4deg);
                                    }
                                
                                    25% {
                                        transform: translateX(-1px) rotate(1.2deg);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .header-middle {
        background: white;
        border-bottom: 1px solid $border-color;
        font-size: 1rem;
        padding: 2rem 0;
        text-transform: capitalize;

        @media only screen and (max-width: 420px) {
            padding: 1rem 0;
        }

        .container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            grid-gap: 4rem;

            @media only screen and (max-width: 768px) {
                grid-gap: 2rem;
                padding: 0 2rem;
            }

            a.logo {
                display: flex;
                margin: auto 0;
                flex-direction: column;

                img {
                    display: block;
                    width: 140px;

                    @media only screen and (max-width: 420px) {
                        width: 128px;
                    }
                }

                span {
                    font-family: united-kingdom;
                    text-transform: uppercase;
                    color: #656565;
                    text-align: right;
                    font-size: .8rem;
                }
            }

            .search {
                display: flex;
                font-size: .8rem;
                width: 100%;

                .select-wrapper {
                    position: relative;
                    width: 200px;

                    @media only screen and (max-width: 600px) {
                        width: unset;
                    }

                    &:before {
                        position: absolute;
                        content: "\f078";
                        font-family: "Font Awesome 5 Pro";
                        font-size: .7rem;
                        right: 1rem;
                        top: 50%;
                        transform: translateY(-50%);

                        @media only screen and (max-width: 600px) {
                            display: none;
                        }
                    }

                    &:after {
                        content: "";
                        height: 2rem;
                        width: 1px;
                        background: $divider-color;
                        position: absolute;
                        right: 0;
                        top: 50%;
                        transform: translateY(-50%);

                        @media only screen and (max-width: 600px) {
                            display: none;
                        }
                    }

                    select {
                        position: relative;
                        background: none;
                        border: 1px solid #805fd3;
                        border-right: none;
                        border-radius: 3px 0 0 3px;
                        color: #7f7f7f;
                        cursor: pointer;
                        padding: 1rem 2rem 1rem 1rem;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        width: 200px;
                        z-index: 1;

                        @media only screen and (max-width: 768px) {
                            width: unset;
                        }
                    }
                }

                input {
                    width: 100%;
                    border: 1px solid $primary-color;
                    border-left: none;
                    border-right: none;
                    color: $text-color-alt;
                    padding: 0 1rem;

                    @media only screen and (max-width: 600px) {
                        border-left: 1px solid $primary-color;
                        border-radius: 3px 0 0 3px;
                    }
                }

                button {
                    background: none;
                    border: 1px solid $primary-color;
                    border-left: none;
                    border-radius: 0 3px 3px 0;
                    color: $text-color-alt;
                    padding: 1rem;
                }
            }

            .header-middle-right {
                display: flex;
                align-items: center;

                @media only screen and (max-width: 768px) {
                    gap: 2rem;
                }

                .deals {
                    transition: all .3s;

                    &:hover {
                        color: $primary-color;
                    }

                    .click {
                        display: flex;
                        align-items: center;
                        grid-gap: .3rem;

                        i {
                            font-size: 1.5rem;
                            transition: all .2s ease-out;
                        }

                        .text-wrapper {
                            span {
                                font-size: .8rem;
                            }

                            p {
                                font-weight: bold;
                            }
                        }
                    }
                }

                .divider {
                    width: 1px;
                    height: 2rem;
                    background: $divider-color;
                    margin-left: 2rem;
                }

                .label-vertical {
                    position: relative;
                    margin-left: 2rem;

                    &.mobile {
                        font-size: 1.5rem;
                    }

                    @media only screen and (max-width: 768px) {
                        margin-left: 0;
                    }

                    .click {
                        display: flex;
                        align-items: center;
                        flex-direction: column;
                        grid-gap: .3rem;
                        text-align: center;
                        transition: all .3s;

                        &:hover {
                            color: $primary-color;
                        }

                        .icon-wrapper {
                            position: relative;

                            i {
                                font-size: 1.5rem;
                                transition: all .2s ease-out;
                            }

                            .count {
                                position: absolute;
                                background: #805fd3;
                                border-radius: 50%;
                                color: white;
                                font-size: .6rem;
                                height: 1rem;
                                line-height: 1.7;
                                right: -8px;
                                top: -8px;
                                width: 1rem;

                                &.hidden {
                                    display: none;
                                }
                            }
                        }

                        p {
                            font-size: .8rem;
                        }
                    }

                    .cart-wrapper {
                        position: fixed;
                        width: 100vw;
                        height: 100vh;
                        background: rgba(0, 0, 0, 30%);
                        z-index: 1000;
                        transition: all .2s;
                        visibility: hidden;
                        opacity: 0;
                        left: 0;
                        top: 0;

                        &.active {
                            visibility: visible;
                            opacity: 1;

                            .cart-overlay {
                                transform: translate(0%);
                            }
                        }

                        .cart-overlay {
                            position: fixed;
                            background: white;
                            color: $text-color;
                            height: 100vh;
                            max-height: 100vh;
                            max-width: 30rem;
                            overflow-y: auto;
                            padding: 2rem;
                            right: 0;
                            top: 0;
                            transform: translate(100%);
                            transition: all .3s;
                            width: 100%;
                            z-index: 1001;

                            .cart-header {
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                padding-bottom: 1rem;
                                border-bottom: 1px solid $border-color;

                                span {
                                    font-size: 1.2rem;
                                    font-weight: bold;
                                    text-transform: uppercase;
                                }

                                button {
                                    display: flex;
                                    font-size: .9rem;
                                    background: none;
                                    align-items: center;
                                    grid-gap: .6rem;
                                    transition: all .3s;

                                    &:hover {
                                        color: $negative-color;
                                    }

                                    i {
                                        font-size: 1rem;
                                    }
                                }
                            }

                            .actions {
                                display: flex;
                                flex-direction: column;
                                gap: 2rem;
                                margin: 1rem 0;
                                padding-bottom: 4rem;

                                > div, > a > div {
                                    display: flex;
                                    gap: .5rem;
                                    font-size: 1.2rem;

                                    i {
                                        width: 28px;
                                    }
                                }

                                .search {
                                    font-size: 1.2rem;
                                    margin-left: 2rem;
                                    border: 1px solid $border-color;
                                    padding: .5rem 1rem;
                                    width: 75vw;
                                }

                                .account {
                                    margin-left: 2rem;
                                }

                                .click {
                                    flex-direction: row;
                                    gap: .5rem;
                                    width: 100%;

                                    .icon-wrapper {
                                        display: flex !important;
                                        position: relative  !important;
                                        gap: .5rem  !important;
                                        font-size: 1.2rem  !important;
                                        text-align: left;
    
                                        i {
                                            font-size: 1.2rem !important;
                                            width: 28px !important;
                                        }

                                        span {
                                            display: none;
                                        }
                                    }

                                    p {
                                        font-size: 1.2rem !important;
                                    }
                                }
                            }

                            .listings {
                                display: flex;
                                align-items: stretch;
                                flex-direction: column;
                                justify-content: space-between;

                                .empty {
                                    margin-top: 2rem;
                                    text-transform: none;
                                    font-size: .9rem;
                                }

                                .listing {
                                    display: flex;
                                    position: relative;
                                    align-items: center;
                                    //cursor: pointer;
                                    grid-gap: 1rem;
                                    margin-top: 1rem;
                                    border-bottom: 1px solid $border-color;
                                    padding-bottom: 1rem;

                                    &:last-of-type {
                                        border-bottom: none;
                                        padding-bottom: none;
                                    }

                                    .thumbnail {
                                        position: relative;
                                        border-radius: 3px;
                                        border: 1px solid $border-color;
                                        min-height: 100px;
                                        overflow: hidden;
                                        min-width: 100px;
                                        transition: all .3s;

                                        &:hover {
                                            border-color: $primary-color;
                                        }

                                        img {
                                            position: absolute;
                                            max-width: 100px;
                                            max-height: 100px;
                                            margin: auto;
                                            left: 0;
                                            top: 0;
                                            right: 0;
                                            bottom: 0
                                        }
                                    }

                                    .details {
                                        display: flex;
                                        flex-direction: column;
                                        //grid-gap: .5rem;
                                        justify-content: space-evenly;
                                        min-height: 100px;
                                        width: 100%;

                                        .title {
                                            display: -webkit-box;
                                            -webkit-box-orient: vertical;
                                            -webkit-line-clamp: 2;
                                            overflow: hidden;
                                            text-overflow: ellipsis;
                                            font-size: .9rem;
                                            font-weight: bold;
                                            padding-right: 1.5rem;
                                            transition: all .3s;
                                            //margin-bottom: .5rem;

                                            &:hover {
                                                color: $primary-color;
                                            }
                                        }

                                        .price-wrapper {
                                            display: flex;
                                            align-items: center;
                                            justify-content: space-between;
                                            //margin-bottom: .5rem;

                                            .price {
                                                color: $primary-color;
                                                font-weight: bold;

                                                &.out-stock {
                                                    color: $negative-color;
                                                }
                                            }

                                            .quantity {
                                                font-size: 1rem;
                                                user-select: none;

                                                i {
                                                    cursor: pointer;
                                                    font-size: 1rem;
                                                    z-index: 1002;

                                                    &.fa-plus-circle:hover {
                                                        color: $positive-color;
                                                    }

                                                    &.fa-minus-circle:hover {
                                                        color: $negative-color;
                                                    }
                                                }
                                            }
                                        }

                                        .stock {
                                            .in-stock {
                                                color: $positive-color;
                                            }
    
                                            .out-stock {
                                                color: $negative-color;
                                            }
    
                                            i {
                                                margin-right: .3rem;
                                                font-weight: bold;
                                            }
                                        }

                                        .variation {
                                            display: flex;
                                            align-items: center;
                                            grid-gap: .3rem;
                                            font-size: .8rem;

                                        }
                                    }

                                    .remove {
                                        position: absolute;
                                        cursor: pointer;
                                        right: 0;
                                        top: 0;
                                        width: 1rem;
                                        height: 1rem;
                                        text-align: center;
                                        z-index: 1002;

                                        &:hover {
                                            i {
                                                color: $negative-color;
                                            }
                                        }
                                    }
                                }
                            }

                            .cart-total {
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                padding-top: 1rem;
                                border-top: 1px solid $border-color;

                                label {
                                    font-weight: bold;
                                }

                                .subtotal {
                                    color: $primary-color;
                                    font-weight: bold;
                                    font-size: 1.2rem;
                                }
                            }

                            .cart-actions {
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                margin-top: 2rem;
                                grid-gap: 1rem;

                                a {
                                    width: 100%;

                                    button {
                                        width: 100%;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .header-bottom {
        background: white;
        border-bottom: 1px solid $border-color;
        color: $text-color;
        font-size: 1rem;

        > .container {
            display: flex;

            .category-dropdown {
                position: relative;
                min-width: 300px;

                @media only screen and (max-width: 768px) {
                    min-width: 250px;
                }

                @media only screen and (max-width: 600px) {
                    min-width: unset;
                    width: 100%;
                }

                &.hover {
                    @media (hover: hover) {
                        > .click {
                            background: $primary-color;
                            color: white;
                        }
    
                        .category-wrapper {
                            opacity: 1;
                            visibility: visible;
                        }
                    }
                }

                &.tog-hover {
                    @media (hover: none) {
                        > .click {
                            background: $primary-color;
                            color: white;
                        }

                        .category-wrapper {
                            opacity: 1;
                            visibility: visible;
                        }
                    }
                }

                // &:hover {
                //     > .click {
                //         background: $primary-color;
                //         color: white;
                //     }
                //
                //     .category-wrapper {
                //         opacity: 1;
                //         visibility: visible;
                //     }
                // }

                > .click {
                    display: flex;
                    align-items: center;
                    border-left: 1px solid $border-color;
                    border-right: 1px solid $border-color;
                    grid-gap: .5rem;
                    line-height: 1;
                    padding: 1rem 1.5rem;
                    transition: all .3s;

                    &:after {
                        display: inline-block;
                        content: "\f078";
                        font-family: "Font Awesome 5 Pro";
                        font-size: .7rem;
                        font-weight: 300;
                        line-height: 1;
                    }

                    span {
                        flex: 1;
                    }
                }

                .category-wrapper {
                    position: absolute;
                    background: white;
                    box-shadow: 0 2px 10px rgb(0 0 0 / 10%);
                    color: $text-color;
                    opacity: 0;
                    visibility: hidden;
                    width: 100%;
                    z-index: 10;

                    .category-menu {
                        a {
                            &:last-of-type {
                                li {
                                    border-bottom: none;
                                }
                            }
                        }

                        li {
                            display: flex;
                            align-items: center;
                            border-bottom: 1px solid $border-color;
                            grid-gap: .5rem;
                            margin: 0 1rem;
                            padding: 1rem 0;
                            transition: all .3s;

                            &:hover {
                                padding-left: .3rem;

                                i, span {
                                    //color: $primary-color;
                                }
                            }

                            i {
                                display: flex;
                                align-items: center;
                                font-size: 1rem;
                                justify-content: center;
                                line-height: 1;
                                transition: all .3s;
                                width: 24px;
                            }

                            span {
                                font-size: .8rem;
                                line-height: 1rem;
                                transition: all .3s;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }
                        }
                    }
                }
            }

            .top-categories {
                display: flex;
                -ms-overflow-style: none;
                align-items: center;
                gap: 1rem;
                justify-content: space-between;
                line-height: 1;
                margin: 0 2rem;
                overflow-x: scroll;
                scrollbar-width: none;
                width: 100%;
                
                &::-webkit-scrollbar {
                    display: none;
                }

                @media only screen and (max-width: 600px) {
                    width: unset;
                }

                @media only screen and (max-width: 768px) {
                    margin: 0;
                    margin-left: 1rem;
                }

                .click {
                    display: flex;
                    align-items: center;
                    grid-gap: .6rem;
                    position: relative;

                    @media only screen and (max-width: 768px) {
                        max-width: 14vw;
                    }

                    &:before {
                        position: absolute;
                        border-top: 1px solid $primary-color;
                        bottom: -0.5rem;
                        content: "";
                        left: 0;
                        transform-origin: right center;
                        transform: scale(0, 1);
                        transition: transform 0.3s ease, -webkit-transform 0.3s ease;
                        width: 100%;
                        -webkit-transform: scale(0, 1);
                        -webkit-transition: -webkit-transform 0.3s ease;
                    }

                    &:hover {
                        &:before {
                            transform-origin: left center;
                            transform: scale(1, 1);
                            -webkit-transform: scale(1, 1);
                        }
                    }

                    span {
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }
        }
    }
}
