.breadcrumb-wrapper {
    display: flex;
    align-items: center;
    color: $text-color-alt;
    font-size: .8rem;
    justify-content: space-between;
    margin-bottom: 1rem;

    &.border-bottom {
        border-bottom: 1px solid $border-color;
        padding-bottom: 1rem;
    }

    .breadcrumb {
        display: flex;
        align-items: center;
        grid-gap: .5rem;

        a {
            transition: all .3s;

            &:hover {
                color: $primary-color;
            }
        }

        span {
            color: $background-dark;
        }
    }

    .report {
        transition: all .3s;

        &:hover {
            color: $negative-color;
        }
    }
}