section.category {
    padding: 2rem 0;

    @media only screen and (max-width: 600px) {
        padding: 1rem 0;
    }

    >.container {
        color: $text-color;

        .category-wrapper {
            .banner-wrapper {
                position: relative;
                //background-image: url("../../../../public/assets/images/categories/ComputersNetworkHardware.jpg");
                background-position: right;
                background-repeat: no-repeat;
                background-size: cover;
                border-radius: 3px;
                margin-bottom: 2rem;
                padding: 4rem;
                width: 100%;

                @media only screen and (max-width: 768px) {
                    padding: 2rem;
                }

                @media only screen and (max-width: 600px) {
                    margin-bottom: 1rem;
                }

                &.antiques-collectables {
                    background-image: url("https://storex.io/assets/images/categories/category_antiques_collectables.jpg");
                }

                &.books-music {
                    background-image: url("https://storex.io/assets/images/categories/category_books_music.jpg");
                }

                &.business-office {
                    background-image: url("https://storex.io/assets/images/categories/category_business_office.jpg");
                }

                &.computers-hardware {
                    background-image: url("https://storex.io/assets/images/categories/category_computers_hardware.jpg");
                }

                &.electronics {
                    background-image: url("https://storex.io/assets/images/categories/category_electronics.jpg");
                }

                &.fashion {
                    background-image: url("https://storex.io/assets/images/categories/category_fashion.jpg");
                }

                &.health-beauty {
                    background-image: url("https://storex.io/assets/images/categories/category_health_beauty.jpg");
                }

                &.hobbies-leisure {
                    background-image: url("https://storex.io/assets/images/categories/category_hobbies_leisure.jpg");
                }

                &.home-garden {
                    background-image: url("https://storex.io/assets/images/categories/category_home_garden.jpg");
                }

                &.jewelry-watches {
                    background-image: url("https://storex.io/assets/images/categories/category_jewelry_watches.jpg");
                }

                &.phones-smart-watches {
                    background-image: url("https://storex.io/assets/images/categories/category_phones_smart_watches.jpg");
                }

                &.services-gigs {
                    background-image: url("https://storex.io/assets/images/categories/category_services_gigs.jpg");
                }

                &.sporting {
                    background-image: url("https://storex.io/assets/images/categories/category_sporting.jpg");
                }

                &.toys-games {
                    background-image: url("https://storex.io/assets/images/categories/category_toys_games.jpg");
                }

                &.vehicle-parts {
                    background-image: url("https://storex.io/assets/images/categories/category_vehicle_parts.jpg");
                }

                &.other {
                    background-image: url("https://storex.io/assets/images/categories/category_other.jpg");
                }

                .category-subtitle {
                    color: $text-color;
                    font-weight: bold;
                    margin-bottom: .3rem;
                    text-transform: uppercase;
                }

                .category-title {
                    color: white;
                    font-size: 2rem;
                    font-weight: bold;
                    text-transform: uppercase;
                    margin-bottom: 1rem;

                    @media only screen and (max-width: 420px) {
                        font-size: 1.5rem;
                        text-shadow: 2px 2px 10px rgb(1, 1, 1, 0.4);
                    }
                }

                button {
                    color: white;
                    background: $text-color;
                    padding: 1rem 2rem;
                    border-radius: 3px;
                    transition: all .3s;

                    @media only screen and (max-width: 768px) {
                        padding: 1rem;
                    }

                    &:hover {
                        opacity: .9;
                    }
                }
            }

            .content-wrapper {
                display: grid;
                grid-template-columns: 3fr .01fr 10fr;
                grid-gap: 1rem;
                color: $text-color;

                @media only screen and (max-width: 600px) {
                    display: block;
                }

                .sidebar-wrapper {
                    .sidebar-title {
                        display: flex;
                        align-items: center;
                        border-bottom: 1px solid $border-color;
                        font-weight: bold;
                        justify-content: space-between;

                        .underline {
                            border-bottom: 2px solid $primary-color;
                            padding-bottom: .5rem;
                        }

                        .reset {
                            display: flex;
                            font-size: 0.8rem;
                            font-weight: normal;
                            background: none;
                            align-items: center;
                            grid-gap: 0.6rem;
                            transition: all 0.3s;
                            opacity: .5;

                            &:hover {
                                opacity: 1;
                                //color: $negative-color;
                            }
                        }
                    }

                    .filter {
                        margin-top: 1rem;

                        .heading {
                            display: flex;
                            margin-bottom: .5rem;
                            padding-bottom: .3rem;
                            font-weight: bold;
                            font-size: .9rem;
                            justify-content: space-between;
                            align-items: center;
                            transition: all .3s;
                        }

                        ul {
                            li {
                                font-size: .9rem;
                                margin-bottom: .5rem;
                                transition: all .3s;
                                cursor: pointer;

                                &.active {
                                    color: $primary-color;
                                    font-weight: bold;
                                }

                                &:hover {
                                    color: $primary-color;
                                }
                            }
                        }

                        input {
                            display: none;
                            
                            &[name="min"],
                                &[name="max"] {
                                    display: inline-block;
                                }
                            &:checked {
                                +label {
                                    color: $primary-color;
                                    font-weight: bold;
                                }
                            }
                        }

                        label {
                            display: block;
                            font-size: .9rem;
                            margin-bottom: .5rem;
                            transition: all .3s;
                            cursor: pointer;
                            user-select: none;

                            &:hover {
                                color: $primary-color;
                            }
                        }
                    }
                }

                .divider {
                    width: 1px;
                    background: $border-color;
                }

                .listings-wrapper {
                    .preferences {
                        display: flex;
                        margin-bottom: 1rem;
                        align-items: center;
                        justify-content: space-between;

                        @media only screen and (max-width: 420px) {
                            gap: 1rem;
                        }

                        >div {
                            display: flex;
                            align-items: center;
                            grid-gap: .5rem;
                            position: relative;

                            @media only screen and (max-width: 420px) {
                                width: 100%;
                            }

                            p {
                                white-space: nowrap;
                            }

                            select {
                                display: block;
                                border-radius: 3px;
                                border: 1px solid $border-color;
                                color: #3a3a3a;
                                font-size: 1rem;
                                line-height: 1.5;
                                padding: .3rem .6rem;
                                text-align: left;
                                width: 100%;
                            }

                            >i {
                                display: none;
                                position: absolute;
                                right: .6rem;
                            }
                        }

                        .results {
                            color: $text-color-alt;
                            font-size: .8rem;
                            font-style: italic;

                            @media only screen and (max-width: 420px) {
                                order: 1;
                                justify-content: center;
                                margin-top: .5rem;
                            }
                        }
                    }

                    .mobile-filters {
                        position: relative;
                        background: none;
                        border-radius: 3px;
                        border: 1px solid $border-color;
                        color: $text-color;
                        line-height: 1.5;
                        margin-bottom: 1rem;
                        padding: .3rem .6rem;
                        text-align: left;
                        width: 100%;

                        i {
                            position: absolute;
                            line-height: 1.5;
                            right: .6rem;
                        }
                    }

                    .filters-modal {
                        position: fixed;
                        background: rgba(0, 0, 0, 30%);
                        height: 100vh;
                        left: 0;
                        opacity: 0;
                        top: 0;
                        transition: all .2s;
                        visibility: hidden;
                        width: 100vw;
                        z-index: 1000;

                        &.active {
                            visibility: visible;
                            opacity: 1;

                            .filter-overlay {
                                transform: translate(0%);
                            }
                        }

                        .filter-overlay {
                            position: fixed;
                            background: white;
                            color: $text-color;
                            height: 100vh;
                            max-height: 100vh;
                            max-width: 30rem;
                            overflow-y: auto;
                            padding: 2rem;
                            right: 0;
                            top: 0;
                            transform: translate(100%);
                            transition: all .3s;
                            width: 100%;
                            z-index: 1001;

                            .filter-header {
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                padding-bottom: 1rem;
                                border-bottom: 1px solid $border-color;

                                span {
                                    font-size: 1.2rem;
                                    font-weight: bold;
                                    text-transform: uppercase;
                                }

                                button {
                                    display: flex;
                                    font-size: .9rem;
                                    background: none;
                                    align-items: center;
                                    grid-gap: .6rem;
                                    transition: all .3s;

                                    &:hover {
                                        color: $negative-color;
                                    }

                                    i {
                                        font-size: 1rem;
                                    }
                                }
                            }

                            .filters {
                                display: flex;
                                flex-direction: column;
                                gap: 1rem;
                                margin: 1rem 0;

                                .reset {
                                    display: flex;
                                    color: $text-color;
                                    font-size: 0.8rem;
                                    font-weight: normal;
                                    background: none;
                                    align-items: center;
                                    grid-gap: 0.6rem;
                                    transition: all 0.3s;

                                    &:hover {
                                        color: $primary-color;
                                    }
                                }

                                .filter {
                                    .heading {
                                        display: flex;
                                        margin-bottom: .5rem;
                                        padding-bottom: .3rem;
                                        font-weight: bold;
                                        font-size: .9rem;
                                        justify-content: space-between;
                                        align-items: center;
                                        transition: all .3s;
                                    }

                                    ul {
                                        li {
                                            font-size: .9rem;
                                            margin-bottom: .5rem;
                                            transition: all .3s;
                                            cursor: pointer;

                                            &.active {
                                                color: $primary-color;
                                                font-weight: bold;
                                            }

                                            &:hover {
                                                color: $primary-color;
                                            }
                                        }
                                    }
                                    
                                    .price-filter-wrapper {
                                        input {
                                            display: block;
                                        }
                                    }
            
                                    input {
                                        display: none;

                                        &:checked {
                                            +label {
                                                color: $primary-color;
                                                font-weight: bold;
                                            }
                                        }
                                    }

                                    label {
                                        display: block;
                                        font-size: .9rem;
                                        margin-bottom: .5rem;
                                        transition: all .3s;
                                        cursor: pointer;
                                        user-select: none;

                                        &:hover {
                                            color: $primary-color;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .listings {
                        display: flex;
                        flex-direction: column;
                        grid-gap: 1rem;

                        div.loading {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            text-align: center;

                            .banner {
                                display: flex;
                                align-items: center;
                                border: 1px solid $border-color;
                                border-radius: 3px;
                                color: $text-color-alt;
                                flex-direction: column;
                                opacity: .75;
                                padding: 1rem 2rem;

                                i {
                                    font-size: 2rem;
                                }

                                div {
                                    margin-top: 1rem;

                                    a {
                                        font-weight: bold;
                                        transition: all .2s;

                                        &:hover {
                                            color: $primary-color;
                                        }
                                    }
                                }
                            }

                            >i {
                                animation: rotation 1s infinite linear;
                                color: $primary-color;
                                font-size: 3rem;
                                transition: all .2s;
                                user-select: none;

                                @keyframes rotation {
                                    from {
                                        transform: rotate(0deg);
                                    }

                                    to {
                                        transform: rotate(359deg);
                                    }
                                }
                            }

                            span {
                                margin-top: 1rem;
                                color: $text-color-alt;
                            }
                        }

                        li {
                            display: flex;
                            border-bottom: 1px solid $border-color;
                            grid-gap: 1rem;
                            padding-bottom: 1rem;

                            @media only screen and (max-width: 420px) {
                                flex-direction: column;
                            }

                            &:last-of-type {
                                border-bottom: none;
                                padding-bottom: none;
                            }

                            .thumbnail-wrapper {
                                position: relative;
                                border-radius: 3px;
                                border: 1px solid #eeeeee;
                                box-sizing: content-box;
                                min-height: 200px;
                                min-width: 200px;
                                overflow: hidden;
                                transition: all .3s;

                                @media only screen and (max-width: 420px) {
                                    min-height: 90vw;
                                    min-width: 90vw;
                                }

                                &:hover {
                                    border-color: $primary-color;
                                }

                                img {
                                    position: absolute;
                                    max-height: 200px;
                                    max-width: 200px;
                                    margin: auto;
                                    left: 0;
                                    top: 0;
                                    right: 0;
                                    bottom: 0;

                                    @media only screen and (max-width: 420px) {
                                        max-height: 90vw;
                                        max-width: 90vw;
                                    }
                                }
                            }

                            .info-wrapper {
                                display: flex;
                                position: relative;
                                flex-direction: column;
                                justify-content: flex-start;
                                color: $text-color;
                                width: 100%;
                                ;

                                .flex {
                                    display: flex;
                                    justify-content: space-between;
                                    //border-bottom: 1px solid $border-color;
                                    margin-bottom: .3rem;

                                    //padding-bottom: .5rem;
                                    .subcategory {
                                        font-size: .8rem;
                                        color: $text-color-alt;
                                        transition: all .3s;

                                        &:hover {
                                            color: $primary-color;
                                        }
                                    }

                                    .cart-status {
                                        color: $text-color-alt;
                                        font-size: .8rem;

                                        i {
                                            margin-right: .1rem;
                                        }
                                    }
                                }

                                .title {
                                    display: -webkit-box;
                                    -webkit-box-orient: vertical;
                                    -webkit-line-clamp: 2;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    font-size: 1.2rem;
                                    font-weight: bold;
                                    margin-bottom: .3rem;
                                    transition: all .3s;

                                    &:hover {
                                        color: $primary-color;
                                    }
                                }

                                .reviews {
                                    display: inline-flex;
                                    align-items: center;
                                    color: $divider-color;
                                    grid-gap: .3rem;
                                    margin-bottom: 1rem;

                                    i.fill {
                                        color: $primary-color;
                                    }

                                    i.fad {
                                        &:before {
                                            color: $primary-color;
                                        }
                                    }

                                    span {
                                        color: $text-color-alt;
                                        font-size: .8rem;
                                    }
                                }

                                .condition {
                                    color: $text-color-alt;
                                    font-size: .9rem;
                                    margin-bottom: .6rem;
                                }

                                .price {
                                    font-size: 1.4rem;
                                    font-weight: bold;

                                    //margin-bottom: 1rem;
                                    .shipping-cost {
                                        display: inline-block;
                                        color: $text-color;
                                        margin-left: .5rem;
                                        font-size: .8rem;
                                        font-weight: normal;

                                        span {
                                            font-weight: bold;

                                            &.free {
                                                color: $positive-color;
                                            }

                                            &.unavailable {
                                                color: $negative-color;
                                            }
                                        }
                                    }
                                }

                                .options {
                                    font-size: .8rem;
                                    color: $text-color-alt;
                                    margin-top: .1rem;
                                }

                                .stock {
                                    margin-top: 1rem;

                                    .in-stock {
                                        color: $positive-color;
                                    }

                                    .out-stock {
                                        color: $negative-color;
                                    }

                                    i {
                                        margin-right: .3rem;
                                        font-weight: bold;
                                    }
                                }

                                .seller-profile {
                                    display: flex;
                                    position: absolute;
                                    right: 10rem;
                                    bottom: 3rem;
                                    align-items: center;
                                    grid-gap: .5rem;
                                    padding: 1rem;

                                    @media only screen and (max-width: 1100px) {
                                        right: 4rem;
                                    }

                                    @media only screen and (max-width: 1000px) {
                                        right: 2rem;
                                    }

                                    @media only screen and (max-width: 900px) {
                                        display: none;
                                    }

                                    .avatar {
                                        min-height: 40px;
                                        min-width: 40px;
                                        border-radius: 100%;
                                        border: 1px solid $border-color;
                                        overflow: hidden;
                                        position: relative;

                                        img {
                                            position: absolute;
                                            max-height: 100%;
                                            max-width: 100%;
                                            margin: auto;
                                            left: 0;
                                            top: 0;
                                            right: 0;
                                            bottom: 0;
                                        }
                                    }

                                    .wrapper {
                                        display: flex;
                                        align-items: center;
                                        justify-content: space-between;
                                        width: 100%;

                                        .text {
                                            .username {
                                                display: flex;
                                                align-items: center;
                                                grid-gap: .3rem;
                                                font-size: .9rem;
                                                font-weight: bold;
                                                transition: all .3s;
                                                margin-bottom: .1rem;

                                                &:hover {
                                                    color: $primary-color;
                                                }

                                                i {
                                                    display: none;
                                                    color: #20cca5;
                                                    font-size: .8rem;

                                                    &.active {
                                                        display: inline-block;
                                                    }
                                                }
                                            }

                                            .joined {
                                                font-size: .8rem;
                                            }
                                        }

                                        i {
                                            font-size: 1.5rem;
                                            transition: all .3s;

                                            &:hover {
                                                color: $primary-color;
                                            }
                                        }
                                    }

                                    .rating {
                                        display: inline-flex;
                                        align-items: center;
                                        color: $primary-color;
                                        font-size: .8rem;
                                        grid-gap: .3rem;
                                    }
                                }
                            }
                        }

                        .pages {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            gap: 1rem;
                            overflow-x: auto;
                            font-weight: 700;
                            color: $text-color-alt;

                            @media only screen and (max-width: 420px) {
                                gap: .5rem;
                            }

                            button {
                                background: white;
                                border-bottom: 2px solid rgba(0, 0, 0, 0);
                                color: $text-color-alt;
                                padding: .4rem .6rem;
                                transition: all .2s;

                                &:hover {
                                    color: $text-color;
                                    border-color: $primary-color;
                                }

                                &:disabled {
                                    pointer-events: none;
                                    color: #cccccc;
                                }
                            }

                            @media only screen and (max-width: 320px) {
                                button:first-of-type,
                                button:last-of-type {
                                    display: none;
                                }
                            }

                            div {
                                width: 5rem;
                                border: solid 1px $text-color-alt;
                                border-radius: 2px;
                                padding: 0.2rem 0.4rem;
                                cursor: text;

                                > * {
                                    width: 30px;
                                }

                                input {
                                    text-align: right;
                                    font-weight: 700;
                                    color: $text-color-alt;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}