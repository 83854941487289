.price-filter-wrapper {

    $size: 20px;
    $color: $primary-color;


    .price-sliders {
        position: relative;
        width: 300px;
        height: $size;
        background: $background-light;
        border-radius: $size;

        .slider {
            position: absolute;
            width: 100%;
            pointer-events: none;
            -webkit-appearance: none;
            appearance: none;
            background: transparent;
        }

        .slider::-webkit-slider-thumb {
            pointer-events: all;
            width: $size;
            height: $size;
            border-radius: $size;
            background: $color;
            cursor: pointer;
            -webkit-appearance: none;
            appearance: none;
        }

        .slider::-moz-range-thumb {
            pointer-events: all;
            width: $size;
            height: $size;
            border-radius: $size;
            background: $color;
            cursor: pointer;
        }
    }

    .price-values {
        display: flex;
        padding-top: 0.5rem;
        font-size: 0.9rem;
        color: $text-color-alt;

        input[type="text"] {
            width: 100%;

            &:last-of-type {
                text-align: right;
            }
        }
    }
}