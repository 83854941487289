section.summary, section.active-listings, section.selling-history {
    .overview-wrapper {
        display: grid;
        align-items: center;
        border-radius: 6px;
        border: 1px solid $border-color;
        gap: 1rem;
        grid-template-columns: repeat(3, 1fr);
        justify-content: space-between;
        justify-items: center;
        padding: 1rem 2rem;

        @media only screen and (max-width: 830px) {
            gap: 0;

            span {
                border-right: none !important;
            }
        }

        @media only screen and (max-width: 768px) {
            display: flex;
            gap: .5rem;
            flex-direction: column;
            padding: .5rem 0;

            span {
                border-bottom: 1px solid $border-color;
                padding-bottom: .5rem;
            }
        }

        span {
            display: flex;
            align-items: center;
            border-right: 1px solid $border-color;
            color: $text-color-alt;
            flex-direction: column;
            justify-content: center;
            width: 100%;

            &:last-of-type {
                border: none;
                padding-bottom: 0;
            }

            > i {
                line-height: 24px;
            }
    
            div.subtitle {
                font-size: .8rem;
            }

            p {
                display: flex;
                align-items: center;
                color: $text-color;
                flex-wrap: wrap;
                font-weight: bold;
                gap: .3rem;
                line-height: 24px;

                img {
                    height: 20px;
                    width: 20px;
                }
            }
        }
    }

    div.mass-buttons {
        display: flex;
        justify-content: flex-end;

        button.claim-all {
            display: flex;
            align-items: center;
            background: $primary-color;
            border-radius: 3px;
            color: white;
            font-size: .9rem;
            gap: .3rem;
            justify-content: center;
            padding: .5rem 1rem;
            transition: all .3s;
    
            &:hover {
                opacity: .9;
            }
    
            &:disabled {
                background: $divider-color;
            }
    
            i {
                color: white;
            }
        }
    }

    .active-wrapper {
        ul {
            display: flex;
            flex-direction: column;
            grid-gap: 1rem;

            div.loading {
                display: flex;
                flex-direction: column;
                justify-content: center;
                text-align: center;

                .banner {
                    display: flex;
                    align-items: center;
                    border: 1px solid $border-color;
                    border-radius: 3px;
                    color: $text-color-alt;
                    flex-direction: column;
                    opacity: .75;
                    padding: 1rem 2rem;

                    i {
                        font-size: 2rem;
                    }

                    div {
                        margin-top: 1rem;

                        a {
                            font-weight: bold;
                            transition: all .2s;

                            &:hover {
                                color: $primary-color;
                            }
                        }
                    }
                }

                > i {
                    animation: rotation 1s infinite linear;
                    color: $primary-color;
                    font-size: 3rem;
                    transition: all .2s;
                    user-select: none;
                    
                    @keyframes rotation {
                        from {
                            transform: rotate(0deg);
                        }

                        to {
                            transform: rotate(359deg);
                        }
                    }
                }

                span {
                    margin-top: 1rem;
                    color: $text-color-alt;
                }
            }

            div.empty {
                color: $text-color-alt;
                //font-style: italic;
                text-align: center;
            }

            span.empty {
                color: $text-color-alt;
                text-align: center;

                a {
                    font-weight: bold;
                    transition: all .2s;

                    &:hover {
                        color: $primary-color;
                    }
                }
            }

            .sale-overview {
                display: flex;
                background: $background-light;
                border-radius: 3px;
                font-size: .9rem;
                justify-content: space-around;
                padding: 1rem 2rem;
                width: 100%;

                @media only screen and (max-width: 900px) {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    grid-gap: .5rem;
                }

                .stat {
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    justify-content: center;

                    .subtitle {
                        color: $text-color-alt;
                        font-size: .8rem;
                    }

                    .value {
                        max-width: 140px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        transition: all .2s;

                        a {
                            transition: all .2s;

                            &:hover {
                                color: $primary-color;
                            }
                        }

                        i {
                            font-size: .7rem;
                            margin-left: .3rem;
                            color: $warning-color;

                            &.message {
                                color: $text-color;
                                font-size: .8rem;
                                transition: all .2s;

                                &:hover {
                                    color: $primary-color;
                                }
                            }
                        }
                    }
                }
            }

            li {
                display: flex;
                border-bottom: 1px solid $background-light;
                grid-gap: 1rem;
                padding-bottom: 2rem;
                margin-bottom: 1rem;

                @media only screen and (max-width: 1000px) {
                    flex-direction: column;
                }

                &:last-of-type {
                    border-bottom: none;
                    padding-bottom: none;
                }

                .mobile-wrapper {
                    display: flex;
                    grid-gap: 1rem;
                    width: 100%;

                    @media only screen and (max-width: 460px) {
                        flex-direction: column;
                    }
                }

                .thumbnail-wrapper {
                    position: relative;
                    border-radius: 3px;
                    border: 1px solid #eeeeee;
                    box-sizing: content-box;
                    min-height: 200px;
                    min-width: 200px;
                    overflow: hidden;
                    transition: all 0.3s;

                    &:hover {
                        border-color: $primary-color;
                    }

                    img {
                        position: absolute;
                        max-height: 200px;
                        max-width: 200px;
                        margin: auto;
                        left: 0;
                        top: 0;
                        right: 0;
                        bottom: 0;
                    }
                }

                .info {
                    display: flex;
                    position: relative;
                    color: $text-color;
                    flex-direction: column;
                    justify-content: flex-start;
                    width: 100%;

                    .status-icons {
                        display: flex;
                        position: absolute;
                        align-items: center;
                        background: $background-light;
                        border-radius: 3px;
                        bottom: 0;
                        gap: 1rem;
                        justify-content: flex-end;
                        padding: .5rem 1rem;
                        right: 0;

                        @media only screen and (max-width: 650px) {
                            position: unset;
                            margin-top: 1rem;
                            justify-content: center;
                        }

                        div.icon {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            gap: .3rem;

                            span {
                                color: $text-color-alt;
                                font-size: .7rem;
                            }

                            i {
                                color: $divider-color;
    
                                &.active {
                                    color: $positive-color;
                                }

                                &.alert {
                                    color: $negative-color;
                                }
                            }
                        }
                    }

                    .subcategory {
                        font-size: 0.8rem;
                        transition: all .3s;
                        color: $text-color-alt;
                        margin-bottom: .3rem;

                        &:hover {
                            color: $primary-color;
                        }
                    }

                    .title {
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        font-size: 1.2rem;
                        font-weight: bold;
                        margin-bottom: .3rem;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        transition: all .3s;

                        &:hover {
                            color: $primary-color;
                        }
                    }

                    .reviews {
                        display: inline-flex;
                        align-items: center;
                        color: $divider-color;
                        grid-gap: .3rem;
                        margin-bottom: 1rem;

                        i.fill {
                            color: $primary-color;
                        }

                        i.fad {
                            &:before {
                                color: $primary-color;
                            }
                        }

                        span {
                            color: $text-color-alt;
                            font-size: .8rem;
                        }
                    }

                    .condition {
                        color: $text-color-alt;
                        font-size: .9rem;
                        margin-bottom: .6rem;
                    }

                    .price {
                        display: flex;
                        align-items: center;
                        font-size: 1.4rem;
                        font-weight: bold;
                        gap: .3rem;

                        span {
                            font-size: .9rem;
                            font-weight: normal;
                        }
                    }

                    .options {
                        font-size: .8rem;
                        color: $text-color-alt;
                        margin-top: .1rem;
                    }

                    .stat-wrapper {
                        display: flex;
                        //position: absolute;
                        //border-top: 1px solid $border-color;
                        //bottom: 0;
                        gap: 2rem;
                        margin-top: 1rem;
                        //padding-top: 0.5rem;
                        justify-content: space-evenly;
                        width: 100%;

                        @media only screen and (max-width: 1000px) {
                            position: unset;
                            white-space: nowrap;
                            overflow-x: auto;
                        }

                        @media only screen and (max-width: 900px) {
                            display: grid;
                            grid-template-columns: repeat(3, 1fr);
                            gap: 1rem;
                        }

                        .stat {
                            font-size: .9rem;

                            i {
                                color: $primary-color;
                                margin-right: .3rem;

                                &.amber {
                                    color: $warning-color;
                                }

                                &.paid {
                                    color: #CFB53B;
                                }
                            }
                        }
                    }
                }

                .mobile-stat-wrapper {
                    display: none;
                    border-top: 1px solid $border-color;
                    gap: 1rem;
                    margin-top: 0.5rem;
                    padding-top: 0.5rem;
                    justify-content: space-evenly;
                    width: 100%;

                    @media only screen and (max-width: 420px) {
                        justify-content: space-between;
                    }

                    .stat {
                        font-size: .9rem;

                        @media only screen and (max-width: 420px) {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            gap: .3rem;
                        }

                        i {
                            color: $primary-color;
                            margin-right: .3rem;

                            @media only screen and (max-width: 420px) {
                                margin-right: 0;
                            }

                            &.amber {
                                color: $warning-color;
                            }

                            &.paid {
                                color: #CFB53B;
                            }
                        }
                    }
                }

                .actions {
                    display: flex;
                    align-items: flex-end;
                    flex-direction: column;
                    gap: 1rem;
                    justify-content: center;

                    @media only screen and (max-width: 1000px) {
                        display: grid;
                        grid-template-columns: repeat(3, 1fr);
                    }

                    @media only screen and (max-width: 460px) {
                        grid-template-columns: repeat(1, 1fr);
                    }

                    button {
                        background: none;
                        border-radius: 3px;
                        border: 1px solid $text-color-alt;
                        color: $text-color-alt;
                        min-width: 180px;
                        padding: .5rem 1rem;
                        transition: all .2s;

                        &.disabled {
                            opacity: .25;
                        }

                        @media only screen and (max-width: 768px) {
                            min-width: unset;
                        }

                        &:hover {
                            background: #805fd310;
                            border-color: $primary-color;
                            color: $primary-color;
                        }
                    }
                }
            }

            .pages {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 1rem;
                overflow-x: auto;

                > i {
                    font-size: .8rem;
                    color: $text-color-alt;
                    cursor: pointer;
                    transition: all .2s;

                    &:hover {
                        color: $text-color;
                    }
                }

                button {
                    background: white;
                    border-bottom: 2px solid rgba(0,0,0,0);
                    color: $text-color-alt;
                    padding: .4rem .6rem;
                    transition: all .2s;

                    &.active {
                        border-color: $primary-color;
                    }

                    &:hover {
                        border-color: $primary-color;
                    }
                }
            }
        }
    }
}
