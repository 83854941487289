section.my-disputes, section.join-panel, section.arbitration-panel {
    margin-bottom: 2rem;

    .my-disputes, .arbitration-panel {
        margin-top: 2rem;

        ul {
            display: flex;
            flex-direction: column;
            grid-gap: 1rem;

            div.loading {
                display: flex;
                flex-direction: column;
                justify-content: center;
                text-align: center;

                .banner {
                    display: flex;
                    align-items: center;
                    border: 1px solid $border-color;
                    border-radius: 3px;
                    color: $text-color-alt;
                    flex-direction: column;
                    opacity: .75;
                    padding: 1rem 2rem;

                    i {
                        font-size: 2rem;
                    }

                    div {
                        margin-top: 1rem;

                        a {
                            font-weight: bold;
                            transition: all .2s;

                            &:hover {
                                color: $primary-color;
                            }
                        }
                    }
                }

                > i {
                    animation: rotation 1s infinite linear;
                    color: $primary-color;
                    font-size: 3rem;
                    transition: all .2s;
                    user-select: none;
                    
                    @keyframes rotation {
                        from {
                            transform: rotate(0deg);
                        }

                        to {
                            transform: rotate(359deg);
                        }
                    }
                }

                span {
                    margin-top: 1rem;
                    color: $text-color-alt;
                }
            }

            div.empty {
                color: $text-color-alt;
                //font-style: italic;
                text-align: center;
            }

            span.empty {
                color: $text-color-alt;
                text-align: center;

                a {
                    font-weight: bold;
                    transition: all .2s;

                    &:hover {
                        color: $primary-color;
                    }
                }
            }

            .dispute-overview {
                display: flex;
                background: $background-light;
                border-radius: 3px;
                font-size: .9rem;
                justify-content: space-around;
                padding: 1rem 2rem;
                width: 100%;

                @media only screen and (max-width: 900px) {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    grid-gap: .5rem;
                }

                .stat {
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    justify-content: center;

                    &.grid-span {
                        grid-column-start: span 2;
                    }

                    .subtitle {
                        color: $text-color-alt;
                        font-size: .8rem;
                    }

                    .value {
                        max-width: 140px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        transition: all .2s;

                        a {
                            transition: all .2s;

                            &:hover {
                                color: $primary-color;
                            }
                        }

                        i {
                            font-size: .7rem;
                            margin-left: .3rem;
                            color: $warning-color;

                            &.message {
                                color: $text-color;
                                font-size: .8rem;
                                transition: all .2s;

                                &:hover {
                                    color: $primary-color;
                                }
                            }
                        }
                    }
                }
            }

            li {
                display: flex;
                border-bottom: 1px solid $background-light;
                grid-gap: 1rem;
                padding-bottom: 2rem;
                margin-bottom: 1rem;

                @media only screen and (max-width: 900px) {
                    flex-direction: column;
                }

                .mobile-wrapper {
                    display: flex;
                    grid-gap: 1rem;
                    width: 100%;

                    @media only screen and (max-width: 460px) {
                        flex-direction: column;
                    }
                }

                &:last-of-type {
                    margin-bottom: 0;
                }

                .thumbnail-wrapper {
                    position: relative;
                    border-radius: 3px;
                    border: 1px solid #eeeeee;
                    box-sizing: content-box;
                    min-height: 200px;
                    min-width: 200px;
                    overflow: hidden;
                    transition: all 0.3s;

                    // @media only screen and (max-width: 460px) {
                    //     height: calc(100vw - 2rem);
                    //     width: 100%;
                    // }

                    &:hover {
                        border-color: $primary-color;
                    }

                    img {
                        position: absolute;
                        max-height: 200px;
                        max-width: 200px;
                        margin: auto;
                        left: 0;
                        top: 0;
                        right: 0;
                        bottom: 0;

                        // @media only screen and (max-width: 460px) {
                        //     max-height: calc(98vw - 2rem);
                        //     max-width: calc(98vw - 2rem);
                        // }
                    }
                }

                .info {
                    display: flex;
                    position: relative;
                    color: $text-color;
                    flex-direction: column;
                    justify-content: flex-start;
                    width: 100%;

                    .status-icons {
                        display: flex;
                        position: absolute;
                        align-items: center;
                        //background: $background-light;
                        //border-radius: 3px;
                        bottom: 0;
                        gap: 1rem;
                        justify-content: flex-end;
                        //padding: .5rem 1rem;
                        right: 0;

                        @media only screen and (max-width: 650px) {
                            flex-direction: column;
                            position: unset;
                            margin-top: 1rem;
                        }

                        @media only screen and (max-width: 600px) {
                            justify-content: center;
                        }

                        > div.section {
                            display: flex;
                            background: $background-light;
                            border-radius: 3px;
                            gap: 1rem;
                            padding: .5rem 1rem;

                            @media only screen and (max-width: 650px) {
                                justify-content: center;
                                width: 100%;
                            }
                        }

                        p {
                            font-size: .9rem;

                            i {
                                color: $text-color;
                                margin-right: .1rem;
                            }
                        }

                        div.icon {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            gap: .3rem;

                            span {
                                color: $text-color-alt;
                                font-size: .7rem;
                            }

                            i {
                                color: $divider-color;

                                &.complete {
                                    color: $positive-color;
                                }
    
                                &.pending {
                                    color: $warning-color;
                                }

                                &.alert {
                                    color: $negative-color;
                                }
                            }
                        }
                    }

                    .subcategory {
                        font-size: 0.8rem;
                        transition: all .3s;
                        color: $text-color-alt;
                        margin-bottom: .5rem;

                        &:hover {
                            color: $primary-color;
                        }
                    }

                    .title {
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        font-size: 1.2rem;
                        font-weight: bold;
                        margin-bottom: 0.5rem;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        transition: all .3s;

                        &:hover {
                            color: $primary-color;
                        }
                    }

                    .reviews {
                        display: inline-flex;
                        align-items: center;
                        color: $divider-color;
                        grid-gap: .3rem;
                        margin-bottom: 1rem;

                        i.fill {
                            color: $primary-color;
                        }

                        i.fad {
                            &:before {
                                color: $primary-color;
                            }
                        }

                        span {
                            color: $text-color-alt;
                            font-size: .8rem;
                        }
                    }

                    .price {
                        display: flex;
                        align-items: center;
                        font-size: 1.4rem;
                        font-weight: bold;
                        gap: .3rem;

                        span {
                            font-size: .9rem;
                            font-weight: normal;
                        }
                    }

                    .options {
                        font-size: .8rem;
                        color: $text-color-alt;
                        margin-top: .1rem;
                    }

                    .reason {
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 4;
                        font-size: .8rem;
                        //font-style: italic;
                        margin-top: .5rem;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                    .stat-wrapper {
                        display: flex;
                        position: absolute;
                        border-top: 1px solid $border-color;
                        bottom: 0;
                        gap: 2rem;
                        margin-top: 0.5rem;
                        padding-top: 0.5rem;
                        justify-content: space-evenly;
                        width: 100%;

                        .stat {
                            font-size: .9rem;

                            i {
                                color: $primary-color;
                                margin-right: .3rem;

                                &.amber {
                                    color: $warning-color;
                                }

                                &.paid {
                                    color: #CFB53B;
                                }
                            }
                        }
                    }
                }

                .actions {
                    display: flex;
                    align-items: flex-end;
                    flex-direction: column;
                    gap: 1rem;
                    justify-content: center;

                    @media only screen and (max-width: 900px) {
                        display: grid;
                        grid-template-columns: repeat(2, 1fr);
                    }

                    @media only screen and (max-width: 460px) {
                        grid-template-columns: repeat(1, 1fr);
                    }

                    button {
                        background: none;
                        border-radius: 3px;
                        border: 1px solid $text-color-alt;
                        color: $text-color-alt;
                        min-width: 180px;
                        padding: .5rem 1rem;
                        transition: all .2s;

                        @media only screen and (max-width: 900px) {
                            width: 100%;
                        }

                        &:hover {
                            background: #805fd310;
                            border-color: $primary-color;
                            color: $primary-color;

                            > i {
                                color: $primary-color !important;
                            }
                        }

                        > i {
                            color: $text-color-alt !important;
                        }
                    }
                }
            }

            .pages {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 1rem;
                overflow-x: auto;

                @media only screen and (max-width: 420px) {
                    gap: .5rem;
                }

                > i {
                    font-size: .8rem;
                    color: $text-color-alt;
                    cursor: pointer;
                    transition: all .2s;

                    &:hover {
                        color: $text-color;
                    }
                }

                button {
                    background: white;
                    border-bottom: 2px solid rgba(0,0,0,0);
                    color: $text-color-alt;
                    padding: .4rem .6rem;
                    transition: all .2s;

                    &.active {
                        border-color: $primary-color;
                    }

                    &:hover {
                        border-color: $primary-color;
                    }
                }
            }
        }
    }

    .join-panel {
        margin-top: 4rem;

        .banner-wrapper {
            position: relative;
            background-image: url("https://storex.io/assets/images/categories/category_services_gigs.jpg");
            background-position: right;
            background-repeat: no-repeat;
            background-size: cover;
            border-radius: 3px;
            margin-bottom: 2rem;
            padding: 2rem;
            width: 100%;

            @media only screen and (max-width: 768px) {
                padding: 2rem;
            }

            @media only screen and (max-width: 600px) {
                margin-bottom: 1rem;
            }

            .category-subtitle {
                color: $text-color;
                font-weight: bold;
                margin-bottom: .3rem;
                text-transform: uppercase;
            }

            .category-title {
                color: white;
                font-size: 2rem;
                font-weight: bold;
                text-transform: uppercase;
                margin-bottom: 1rem;

                @media only screen and (max-width: 420px) {
                    font-size: 1.5rem;
                    text-shadow: 2px 2px 10px rgb(1, 1, 1, 0.4);
                }
            }

            .buttons {
                display: flex;
                gap: 1rem;

                button {
                    color: white;
                    background: $text-color;
                    padding: 1rem 2rem;
                    border-radius: 3px;
                    transition: all .3s;

                    &.secondary {
                        background: $text-color-alt;
                        border-color: $text-color-alt;
                    }
    
                    @media only screen and (max-width: 768px) {
                        padding: 1rem;
                    }
    
                    &:hover {
                        opacity: .9;
                    }

                    i {
                        color: white !important;
                        margin-right: .3rem;
                    }
                }
            }
        }
    }

    .arbitration-panel {
        margin-top: 4rem;

        .summary {
            background: $background-light;
            padding: 2rem;
            margin-bottom: 2rem;

            .top-wrapper {
                display: flex;
                align-items: center;
                color: $text-color;
                justify-content: space-between;

                .title {
                    font-size: 1.2rem;
                    font-weight: bold;
                }

                .subtitle {
                    display: flex;
                    align-items: center;
                    color: $text-color-alt;
                    font-size: .8rem;
                    gap: .3rem;

                    &.active {
                        i {
                            color: $positive-color;
                        }
                    }

                    &.inactive {
                        i {
                            color: $divider-color;
                        }
                    }

                    &.suspended {
                        i {
                            color: $negative-color;
                        }
                    }
                }
            }

            .bottom-wrapper {
                display: flex;
                align-items: stretch;
                color: $text-color;
                justify-content: space-between;
                margin-top: 2rem;

                @media only screen and (max-width: 600px) {
                    flex-direction: column;
                }

                button.leave {
                    color: white;
                    background: $negative-color;
                    padding: .5rem 1rem;
                    border-radius: 3px;
                    transition: all .3s;
                    height: 100%;

                    &.secondary {
                        background: $text-color-alt;
                        border-color: $text-color-alt;
                    }
    
                    @media only screen and (max-width: 768px) {
                        padding: 1rem;
                    }

                    @media only screen and (max-width: 600px) {
                        margin-top: 1rem;
                        width: 100%;
                    }
    
                    &:hover {
                        opacity: .9;
                    }

                    i {
                        color: white;
                        margin-right: .3rem;
                    }
                }

                .rewards {
                    display: flex;
                    gap: 2rem;

                    @media only screen and (max-width: 424px) {
                        flex-direction: column;
                        gap: 1rem;

                        button {
                            padding: 1rem !important;
                        }
                    }

                    > div {
                        .title {
                            font-size: .9rem;
                            margin-bottom: .3rem;
                            text-wrap: nowrap;
                        }
        
                        .subtitle {
                            display: flex;
                            //font-size: 1.2rem;
                            gap: .3rem;
                            align-items: center;
                            font-weight: bold;
        
                            img {
                                height: 20px;
                                width: 20px;
                            }
                        }
                    }

                    button {
                        display: flex;
                        align-items: center;
                        background: $primary-color;
                        border-radius: 3px;
                        color: white;
                        font-size: .9rem;
                        gap: .3rem;
                        justify-content: center;
                        padding: .5rem 1rem;
                        transition: all .3s;
                        text-wrap: nowrap;

                        @media only screen and (max-width: 600px) {
                            width: 100%;
                        }

                        &.b340 {
                            display: none;
                        }

                        &:hover {
                            opacity: .9;
                        }

                        i {
                            color: white;
                        }
                    }
                }
            }
        }
    }
}
