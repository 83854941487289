.w3a-modal {
    transition: all .2s;
    height: 100vh;
    width: 100vw;
    background: rgba(0, 0, 0, 0.25);

    input, button {
        border-radius: 3px !important;
    }
}

.w3a-modal__inner {
    border-radius: 6px !important;
    min-height: 300px !important;
}

.w3a-modal__content {
    .w3ajs-passwordless {
        margin-bottom: 2rem !important;
    }

    .w3a-button-expand {
        //color: $primary-color !important;
        color: rgb(17, 25, 40) !important;

        &:hover {
            color: black !important;
        }
    }
}

.w3a-modal__footer {
    display: none !important;
}

.w3a-spinner-power {
    display: none !important;
}

.w3ajs-passwordless-form {
    input {
        margin-top: 0 !important;
        padding: .75rem 1rem !important;
    }

    button {
        background: $primary-color !important;

        &:disabled {
            opacity: .7;
        }

        &:hover {
            background: #704bce !important;
        }
    }
}

.w3ajs-external-wallet {
    display: none !important;

    &.active {
        display: block !important;
        margin-bottom: 1rem !important;

        button {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: .5rem;
            transition: all .2s;

            img {
                height: 20px;
                width: 20px;
                color: black;
            }
        }
    }
}

.w3a-social__policy {
    display: none !important;
}

.w3a-button--login {
    transition: all .2s;
}