section.message {
    margin-bottom: 2rem;

    .page-header {
        color: $text-color;
        background: rgba($background-light, 0.5);
        text-align: center;
        font-weight: bold;
        font-size: 1.8rem;
        padding: 3rem 0;
        border-bottom: 1px solid $border-color;

        @media only screen and (max-width: 600px) {
          font-size: 1.5rem;
          padding: 1rem 0;
        }
    }

    > .container {
        padding: 2rem 1rem;
        color: $text-color;
        text-align: center;

        @media only screen and (max-width: 600px) {
          padding: 1rem 1rem 4rem 1rem;
        }

        .message-wrapper {
            display: grid;
            grid-template-columns: 3fr 7fr;

            @media only screen and (max-width: 460px) {
              display: block;
            }

            .sidebar-wrapper {
                max-height: 30rem;
                background: rgba(245, 245, 245, 0.5);
                border: 1px solid $border-color;
                border-right: none;
                overflow: hidden;
                overflow-y: auto;

                @media only screen and (max-width: 460px) {
                  border-right: 1px solid $border-color;
                  border-bottom: none;
                }

                &::-webkit-scrollbar {
                  width: 5px;
                }
                
                /* Track */
                &::-webkit-scrollbar-track {
                  background: $background-light;
                }
                
                /* Handle */
                &::-webkit-scrollbar-thumb {
                  background: $divider-color;
                }

                ul {
                  @media only screen and (max-width: 460px) {
                    height: 64px;
                    overflow: hidden;
                    overflow-y: auto;
                    scroll-snap-type: y mandatory;
                  }

                    li {
                        display: flex;
                        align-items: center;
                        background: white;
                        border-bottom: 1px solid $border-color;
                        border-left: 4px solid rgba(0,0,0,0);
                        cursor: pointer;
                        gap: 1rem;
                        padding: .5rem 1rem;
                        transition: background .2s;

                        @media only screen and (max-width: 460px) {
                          scroll-snap-align: center;
                        }

                        &.empty {
                          justify-content: center;
                          line-height: 48px;

                          &:hover {
                            background: white;
                          }
                        }

                        &:hover {
                            background: $background-light;

                            .username {
                                color: $primary-color;
                            }
                        }

                        &.active {
                            //background: $background-light;
                            border-left: 4px solid $primary-color;

                            .username {
                                color: $primary-color;
                            }
                        }

                        .profile-picture {
                            position: relative;
                            min-height: 48px;
                            min-width: 48px;

                            img {
                                position: absolute;
                                border-radius: 100%;
                                border: 2px solid $border-color;
                                margin: auto;
                                max-height: 48px;
                                max-width: 48px;
                                overflow: hidden;
                                left: 0;
                            }
                        }

                        .username {
                            color: $text-color-alt;
                            font-weight: bold;

                            @media only screen and (max-width: 768px) {
                              white-space: nowrap;
                              overflow: hidden;
                              text-overflow: ellipsis;
                            }
                        }
                    }
                }
            }

            .chat-wrapper {
                display: flex;
                flex-direction: column;
                height: 30rem;
                border: 1px solid $border-color;
                font-size: 1.25rem;
                justify-content: space-between;

                .type {
                    position: relative;

                    input {
                        width: 100%;
                        border-top: 1px solid $border-color;
                        padding: 1rem 4rem 1rem 1.5rem;

                        &.disabled {
                          opacity: .75;
                        }
                    }

                    i {
                        display: flex;
                        position: absolute;
                        align-items: center;
                        bottom: 0;
                        color: $primary-color;
                        font-size: 2rem;
                        right: 0;
                        top: 0;
                        margin-right: 1rem;
                        cursor: pointer;

                        &.disabled {
                          color: $divider-color;
                        }
                    }
                }

                .imessage {
                    background-color: #fff;
                    border-radius: 0.25rem;
                    display: flex;
                    flex-direction: column;
                    font-size: 1.25rem;
                    padding: 0.5rem 1.5rem;
                    text-align: left;
                    max-height: 30rem;
                    overflow-y: auto;

                    &::-webkit-scrollbar {
                      width: 5px;
                    }
                    
                    /* Track */
                    &::-webkit-scrollbar-track {
                      background: $background-light;
                    }
                    
                    /* Handle */
                    &::-webkit-scrollbar-thumb {
                      background: $divider-color;
                    }
                  }
                  
                  .imessage p {
                    border-radius: 1.15rem;
                    line-height: 1.25;
                    max-width: 75%;
                    padding: 0.5rem .875rem;
                    position: relative;
                    word-wrap: break-word;
                  }
                  
                  .imessage p::before,
                  .imessage p::after {
                    bottom: -0.1rem;
                    content: "";
                    height: 1rem;
                    position: absolute;
                  }
                  
                  span {
                    font-size: .7rem;

                    &.from-me {
                      align-self: flex-end;
                      margin-top: .3rem;
                    }

                    &.from-them {
                      align-items: flex-start;
                    }
                  }

                  p.from-me {
                    align-self: flex-end;
                    background-color: $primary-color;
                    color: #fff; 
                  }
                  
                  p.from-me::before {
                    border-bottom-left-radius: 0.8rem 0.7rem;
                    border-right: 1rem solid $primary-color;
                    right: -0.35rem;
                    transform: translate(0, -0.1rem);
                  }
                  
                  p.from-me::after {
                    background-color: #fff;
                    border-bottom-left-radius: 0.5rem;
                    right: -40px;
                    transform:translate(-30px, -2px);
                    width: 10px;
                  }
                  
                  p[class^="from-"] {
                    margin: 0.5rem 0;
                    width: fit-content;
                  }
                  
                  p.from-me ~ p.from-me {
                    margin: 0.25rem 0 0;
                  }
                  
                  p.from-me ~ p.from-me:not(:last-child) {
                    margin: 0.25rem 0 0;
                  }
                  
                  p.from-me ~ p.from-me:last-child {
                    margin-bottom: 0.5rem;
                  }
                  
                  p.from-them {
                    align-items: flex-start;
                    background-color: #e5e5ea;
                    color: #000;
                  }
                  
                  p.from-them:before {
                    border-bottom-right-radius: 0.8rem 0.7rem;
                    border-left: 1rem solid #e5e5ea;
                    left: -0.35rem;
                    transform: translate(0, -0.1rem);
                  }
                  
                  p.from-them::after {
                    background-color: #fff;
                    border-bottom-right-radius: 0.5rem;
                    left: 20px;
                    transform: translate(-30px, -2px);
                    width: 10px;
                  }
                  
                  p[class^="from-"].emoji {
                    background: none;
                    font-size: 2.5rem;
                  }
                  
                  p[class^="from-"].emoji::before {
                    content: none;
                  }
                  
                  .no-tail::before {
                    display: none;
                  }
                  
                  .margin-b_none {
                    margin-bottom: 0 !important;
                  }
                  
                  .margin-b_one {
                    margin-bottom: 1rem !important;
                  }
                  
                  .margin-t_one {
                    margin-top: 1rem !important;
                  }

                // p {
                //     border-radius: 1.15rem;
                //     line-height: 1.25;
                //     max-width: 75%;
                //     padding: 0.5rem .875rem;
                //     position: relative;
                //     word-wrap: break-word;

                //     &:before, &:after {
                //         bottom: -0.1rem;
                //         content: "";
                //         height: 1rem;
                //         position: absolute;
                //     }

                //     &.self {
                //         align-self: flex-end;
                //         background-color: $primary-color;
                //         color: #fff;

                //         &:before {
                //             border-bottom-left-radius: 0.8rem 0.7rem;
                //             border-right: 1rem solid $primary-color;
                //             right: -0.35rem;
                //             transform: translate(0, -0.1rem);
                //         }

                //         &:after {
                //             background-color: #fff;
                //             border-bottom-left-radius: 0.5rem;
                //             right: -40px;
                //             transform:translate(-30px, -2px);
                //             width: 10px;
                //         }

                //         &.no-tail:before {
                //             display: none;
                //         }
                //     }

                //     &.them {
                //         align-items: flex-start;
                //         background-color: #e5e5ea;
                //         color: #000;

                //         &:before {
                //             border-bottom-right-radius: 0.8rem 0.7rem;
                //             border-left: 1rem solid #e5e5ea;
                //             left: -0.35rem;
                //             transform: translate(0, -0.1rem);
                //         }

                //         &:after {
                //             background-color: #fff;
                //             border-bottom-right-radius: 0.5rem;
                //             left: 20px;
                //             transform: translate(-30px, -2px);
                //             width: 10px;
                //         }

                //         &.no-tail:before {
                //             display: none;
                //         }
                //     }
                // }
            }
        }
    }
}
