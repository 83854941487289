section.create-listing {
    margin-bottom: 10rem;

    @media only screen and (max-width: 600px) {
        margin-bottom: 4rem;
    }

    .page-header {
        color: $text-color;
        background: rgba($background-light, 0.5);
        text-align: center;
        font-weight: bold;
        font-size: 1.8rem;
        padding: 3rem 0;
        border-bottom: 1px solid $border-color;

        @media only screen and (max-width: 600px) {
            font-size: 1.5rem;
            padding: 1rem 0;
        }
    }

    > .container {
        padding: 2rem 1rem;
        color: $text-color;

        @media only screen and (max-width: 600px) {
            padding: 1rem;
        }

        .create-wrapper {
            display: flex;
            align-items: flex-start;
            grid-gap: 1rem;

            .input-sidebar {
                width: 30%;

                @media only screen and (max-width: 768px) {
                    width: 100%;
                }

                div.action-buttons {
                    display: flex;
                    gap: .5rem;
                    margin-bottom: 1rem;

                    > button {
                        background: none;
                        border-radius: 3px;
                        border: 1px solid $text-color-alt;
                        color: $text-color-alt;
                        font-size: .7rem;
                        padding: .5rem 1rem;
                        transition: all .3s;

                        &:hover {
                            background: #805fd310;
                            border-color: $primary-color;
                            color: $primary-color;
                        }

                        &.active {
                            border-color: $primary-color;
                            color: $primary-color;
                        };
                    }
                }

                form {
                    .input-group {
                        margin-bottom: 2rem;
                        width: 100%;

                        div.relative {
                            position: relative;

                            input + i {
                                display: flex;
                                align-items: center;
                                bottom: 0;
                                color: $text-color-alt;
                                //line-height: 1.5;
                                position: absolute;
                                right: 0;
                                top: 0;
                                transition: all .2s;

                                &:hover {
                                    color: $negative-color;
                                }
                            }
                        }

                        &.images, &.options, &.variations, &.escrow, &.payments, &.shipping {
                            > label {
                                margin-bottom: .5rem;
                            }

                            .free {
                                display: flex;
                                position: absolute;
                                border: 1px solid rgba(0, 0, 0, 0);
                                bottom: 0;
                                gap: .3rem;
                                line-height: 24px;
                                padding: .5rem 0 .5rem 1rem;
                                right: 0;

                                input {
                                    appearance: auto;
                                }
                            }

                            input:disabled {
                                opacity: .5;

                                &+label {
                                    opacity: .5;
                                }
                            }
                        }

                        &.options {
                            input, select {
                                margin-bottom: 1rem;

                                &:nth-of-type(3) {
                                    margin-bottom: 0;
                                }

                                option[value="custom"] {
                                    color: $text-color-alt;
                                    font-style: italic;
                                }

                                + i {
                                    display: flex;
                                    align-items: center;
                                    bottom: 0;
                                    color: #7f7f7f;
                                    padding: 0;
                                    position: absolute;
                                    right: 0;
                                    top: 0;
                                    transition: all 0.2s;
                                    z-index: 1;
                                }
                            }

                            > i + label + input {
                                margin-bottom: 0;
                            }

                            select:has(option[value="custom"]:checked) {
                                display: none;
                            }

                            div.inline {
                                display: flex;
                                align-items: center;
                                flex-direction: row;
                                gap: .3rem;
                                margin-bottom: 1rem;

                                label {
                                    font-size: .9rem;
                                    width: unset;
                                }

                                input {
                                    background: none;
                                    border: 1px solid $border-color;
                                    padding: .3rem .6rem;
                                    font-size: .9rem;
                                    line-height: unset;
                                    margin: unset;
                                    transition: all .2s;
                                    width: 100%;
                                }
                            }

                            label {
                                span {
                                    color: $warning-color;
                                }
                            }
                        }

                        &:has(input:focus, input:not([value=""])), &:has(textarea:focus, textarea:not(:empty)) {
                            > i {
                                &:first-child {
                                    visibility: visible;
                                }
                            }

                            // label {
                            //     transform: translateY(0);
                            //     font-size: .9rem;
                            // }

                            span.chars-left {
                                visibility: visible;
                            }
                        }

                        .radio-wrapper, .checkbox-wrapper {
                            display: flex;
                            align-items: center;
                            grid-gap: 1rem;
                            justify-content: space-between;

                            &.checkbox-wrapper {
                                flex-wrap: wrap;
                                text-align: left;

                                @media only screen and (max-width: 360px) {
                                    flex-direction: column;
                                    flex-wrap: nowrap;
                                }

                                img {
                                    border-radius: 100%;
                                    height: 28px;
                                    width: 28px;
                                }

                                > div {
                                    flex-basis: 47.5%;

                                    input:disabled + label {
                                        opacity: .5;
                                    }

                                    label {
                                        justify-content: flex-start;
                                    }
                                }
                            }

                            > div {
                                user-select: none;
                                width: 100%;

                                input:checked + label {
                                    border-color: $primary-color;
                                    color: $primary-color;
                                }

                                label {
                                    display: inline-flex;
                                    align-items: center;
                                    border-radius: 3px;
                                    border: 1px solid #eeeeee;
                                    cursor: pointer;
                                    font-size: 1rem !important;
                                    grid-gap: 0.5rem;
                                    justify-content: center;
                                    line-height: 1;
                                    padding: 0.5rem 1rem;
                                    transition: all 0.3s;
                                    width: 100%;
                                    z-index: 1;
    
                                    > i {
                                        font-size: 1.2rem;
                                    }
                                }
                            }
                        }

                        textarea {
                            display: block;
                            -moz-box-shadow: none;
                            -webkit-box-shadow: none;
                            background: none;
                            border: none;
                            border-bottom: 1px solid $border-color;
                            box-shadow: none;
                            color: $text-color;
                            font-size: 1rem;
                            line-height: 1.5;
                            padding: .5rem 0;
                            min-height: 41px;
                            height: 41px;
                            outline: none;
                            overflow: auto;
                            resize: none;
                           // transition: all .2s;
                            width: 100%;

                            &:focus {
                                border-bottom-color: $primary-color;
                            }

                            &:-webkit-autofill,
                            &:-webkit-autofill:hover,
                            &:-webkit-autofill:focus,
                            &:-webkit-autofill:active {
                                transition: background-color 5000s ease-in-out 0s;
                            }
                        }

                        label {
                            position: relative;
                            //font-size: 1rem;
                            //line-height: 1rem;
                            //transform: translateY(30px);
                            transition: all 0.3s;
                            z-index: -1;

                            &.images, &[for="images"] {
                                display: inline-flex;
                                align-items: center;
                                border-radius: 3px;
                                border: 1px solid $border-color;
                                cursor: pointer;
                                font-size: 1rem !important;
                                grid-gap: .5rem;
                                justify-content: center;
                                padding: .5rem 1rem;
                                transition: all .2s;
                                width: 100%;
                                z-index: 1;

                                &:hover {
                                    color: $primary-color;
                                    border-color: $primary-color;
                                }

                                &.limit {
                                    border-color: $negative-color;
                                    color: $negative-color;
                                }

                                i {
                                    font-size: 1.2rem;
                                }
                            }
                        }

                        .add-option, .add-variation, .add-international {
                            display: inline-flex;
                            align-items: center;
                            background: none;
                            border-radius: 3px;
                            border: 1px solid $border-color;
                            color: $text-color-alt;
                            cursor: pointer;
                            font-size: 1rem;
                            grid-gap: .5rem;
                            justify-content: center;
                            padding: .5rem 1rem;
                            transition: all .2s;
                            width: 100%;
                            z-index: 1;

                            &:hover {
                                color: $primary-color;
                                border-color: $primary-color;
                            }

                            i {
                                font-size: 1rem;
                            }
                        }

                        .image-buttons {
                            display: flex;
                            grid-gap: .5rem;
                            margin-bottom: .5rem;
                            justify-content: space-between;

                            input + label {
                                display: inline-flex;
                                align-items: center;
                                border-radius: 3px;
                                border: 1px solid $border-color;
                                cursor: pointer;
                                font-size: 1rem;
                                grid-gap: .5rem;
                                justify-content: center;
                                padding: .5rem 1rem;
                                transition: all .2s;
                                width: 100%;
                                z-index: 1;

                                i {
                                    font-size: 1rem;
                                }
                            }

                            input:checked + label {
                                border-color: $primary-color;
                                color: $primary-color;
                            }
                        }

                        .chars-left, .images-left {
                            position: absolute;
                            color: $text-color-alt;
                            font-size: .8rem;
                            right: 0;
                            text-align: right;
                            top: 0;
                            visibility: hidden;
                            white-space: nowrap;

                            &.images-left {
                                visibility: visible;
                            }

                            &.limit {
                                color: $negative-color;
                            }
                        }

                        .image-previews {
                            display: flex;
                            flex-direction: row;
                            flex-wrap: wrap;
                            grid-gap: 0.5rem;
                            overflow: hidden;
                            position: relative;
                            scroll-behavior: smooth;
                            scroll-snap-type: x mandatory;
                            width: 374px;

                            &.empty {
                                height: 0;
                                margin-top: 0;
                            }

                            .image-wrapper {
                                display: inline-block;
                                background: white;
                                border-radius: 3px;
                                border: 1px solid $border-color;
                                height: 87.5px;
                                min-width: 87.5px;
                                overflow: hidden;
                                position: relative;
                                transition: all 0.3s;

                                &:hover {
                                    border-color: $primary-color;
                                }

                                img {
                                    position: absolute;
                                    bottom: 0;
                                    left: 0;
                                    margin: auto;
                                    max-height: 87.5px;
                                    max-width: 100%;
                                    right: 0;
                                    top: 0;

                                    & + i {
                                        position: absolute;
                                        color: $text-color-alt;
                                        margin: .1rem;
                                        right: 0;
                                        top: 0;
                                        transition: all .2s;

                                        &:hover {
                                            color: $negative-color;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    button {
                        display: block;
                        background: $primary-color;
                        border-radius: 3px;
                        color: white;
                        font-size: .9rem;
                        padding: .7rem 1.4rem;
                        transition: all .2s;
                        -webkit-user-select: none;
                        user-select: none;
                        width: 100%;

                        &:hover {
                            opacity: .9;
                        }
                    }
                }
            }

            .preview-wrapper {
                position: sticky;
                align-self: flex-start;
                border-left: 1px solid $border-color;
                padding-left: 1rem;
                top: 1rem;
                width: 100%;

                .preview-visibility {
                    display: inline-flex;
                    align-items: center;
                    border-radius: 3px;
                    border: 1px solid $border-color;
                    color: $text-color-alt;
                    font-size: .9rem;
                    gap: .5rem;
                    justify-content: center;
                    margin-bottom: 1rem;
                    padding: .5rem 1rem;
                }

                .listing-preview {
                    opacity: .75;
                    width: 100%;

                    .breadcrumb {
                        border-bottom: none;
                        padding-bottom: 0;
                    }

                    .content-wrapper {
                        display: flex;
                        grid-gap: 1rem;
                        flex-direction: column;
        
                        .top-wrapper {
                            display: flex;
                            grid-gap: 1rem;
        
                            .thumbnail-wrapper {
                                box-sizing: content-box;
                                user-select: none;
        
                                .thumbnail {
                                    position: relative;
                                    height: 400px;
                                    width: 400px;
                                    border-radius: 3px;
                                    border: 1px solid #eeeeee;
                                    overflow: hidden;
        
                                    img, iframe {
                                        position: absolute;
                                        max-height: 400px;
                                        max-width: 400px;
                                        margin: auto;
                                        left: 0;
                                        top: 0;
                                        right: 0;
                                        bottom: 0;
                                    }
        
                                    iframe {
                                        position: absolute;
                                        height: 400px;
                                        width: 400px;
                                        margin: auto;
                                        left: 0;
                                        top: 0;
                                        right: 0;
                                        bottom: 0;
                                    }
                                }
        
                                .previews-wrapper {
                                    height: 94px;
                                    margin-top: .5rem;
                                    overflow: hidden;
                                    position: relative;
        
                                    .previews {
                                        display: flex;
                                        //border-radius: 0 6px 6px 0;
                                        width: 400px;
                                        overflow: hidden;
                                        position: relative;
                                        scroll-behavior: smooth;
                                        scroll-snap-type: x mandatory;
                                        height: 94px;
                                        flex-direction: row;
                                        grid-gap: .5rem;
        
                                        .image {
                                            display: inline-block;
                                            background: white;
                                            border: 1px solid $border-color;
                                            border-radius: 3px;
                                            min-width: 94px;
                                            overflow: hidden;
                                            position: relative;
                                            transition: all .2s;
        
                                            &:hover {
                                                border-color: $primary-color;
                                            }
        
                                            &.active {
                                                border-color: $primary-color;
                                            }
        
                                            i {
                                                display: flex;
                                                align-items: center;
                                                color: white;
                                                font-size: 2rem;
                                                height: 100%;
                                                justify-content: center;
                                                position: absolute;
                                                width: 100%;
                                                z-index: 10;
                                            }
        
                                            img {
                                                position: absolute;
                                                max-width: 100%;
                                                max-height: 94px;
                                                margin: auto;
                                                left: 0;
                                                top: 0;
                                                right: 0;
                                                bottom: 0;
                                            }
                                        }
                                    }
                                }
                            }
        
                            .summary-wrapper {
                                color: $text-color;
                                width: 100%;
        
                                .title {
                                    display: -webkit-box;
                                    -webkit-box-orient: vertical;
                                    -webkit-line-clamp: 2;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    font-size: 1.2rem;
                                    font-weight: bold;
                                    margin-bottom: 10px;
                                    transition: all .2s;
                                }
        
                                .price {
                                    border-top: 1px solid $border-color;
                                    font-size: 1.8rem;
                                    font-weight: bold;
                                    margin-bottom: 1rem;
                                    padding-top: 1rem;
        
                                    .postage {
                                        display: inline-block;
                                        color: $text-color;
                                        margin-left: .5rem;
                                        font-size: .8rem;
                                        font-weight: normal;
        
                                        span {
                                            font-weight: bold;
        
                                            &.free {
                                                color: $positive-color;
                                            }
                                        }
                                    }
                                }
        
                                .stock {
                                    margin-bottom: 1rem;
        
                                    .in-stock {
                                        color: $positive-color;
                                    }
        
                                    .out-stock {
                                        color: $negative-color;
                                    }
        
                                    i {
                                        margin-right: .3rem;
                                        font-weight: bold;
                                    }
                                }
        
                                .reviews {
                                    display: inline-flex;
                                    align-items: center;
                                    color: $primary-color;
                                    grid-gap: .3rem;
                                    margin-bottom: 1rem;
        
                                    span {
                                        color: $text-color-alt;
                                        font-size: .8rem;
                                    }
                                }
        
                                .options {
                                    margin-bottom: 1rem;
        
                                    .attribute {
                                        display: flex;
                                        margin-bottom: 1rem;
                                        gap: .5rem;
        
                                        span {
                                            display: inline-flex;
                                            align-items: flex-start;
                                            font-size: .8rem;
                                            padding: .3rem 0;
                                            line-height: 18px;
                                        }
        
                                        .radio-wrapper {
                                            display: inline-flex;
                                            flex-wrap: wrap;
                                            gap: .5rem;
        
                                            > div {
                                                display: inline-block;
        
                                                input {
                                                    display: none;
        
                                                    &:checked {
                                                        + label {
                                                            color: $primary-color !important;
                                                            border-color: $primary-color !important;
                                                        }
                                                    }
        
                                                    &:disabled {
                                                        + label {
                                                            color: $border-color !important;
                                                            border-color: $border-color !important;
                                                        }
                                                    }
                                                }
        
                                                label {
                                                    display: inline-block;
                                                    border: 1px solid $divider-color;
                                                    color: $text-color-alt;
                                                    font-size: .8rem;
                                                    line-height: 1rem;
                                                    padding: .3rem .5rem;
                                                    transition: all .2s;
                                                    font-weight: bold;
                                                    border-radius: 3px;
        
                                                    &:hover {
                                                        border-color: $primary-color;
                                                        color: $primary-color;
                                                    }
                                                }
                                            }
                                        }
                                    }
        
                                    .no-options {
                                        display: block;
                                        //font-style: italic;
                                        margin-bottom: 1rem;
                                        font-size: .8rem;
                                    }
        
                                    .reset {
                                        display: inline-block;
                                        font-size: .8rem;
                                        opacity: .5;
                                        transition: all .2s;
        
                                        &:hover {
                                            opacity: 1;
                                        }
        
                                        i {
                                            font-size: .8rem;
                                            margin-right: .3rem;
                                        }
                                    }
                                }
        
                                .checkout {
                                    display: block;
                                    border-top: 1px solid $border-color;
                                    padding-top: 1rem;
        
                                    .row {
                                        display: flex;
                                        grid-column-end: 3;
                                        grid-column-gap: .5rem;
                                        grid-column-start: 1;
                                        width: 100%;
                                        margin-bottom: 1rem;
        
                                        button {
                                            display: block;
                                            background: #805fd3;
                                            border-radius: 3px;
                                            color: white;
                                            font-weight: bold;
                                            margin: 0 auto;
                                            padding: 1rem 2rem;
                                            text-transform: uppercase;
                                            transition: all 0.2s;
                                            -webkit-user-select: none;
                                            user-select: none;
        
                                            &:hover {
                                                opacity: .9;
                                            }
        
                                            &.purchase {
                                                width: 100%;
                                            }
        
                                            &.wishlist, &.cart {
                                                padding: 1rem !important;
                                                width: 100%;
                                                border: 1px solid $text-color;
                                                color: $text-color;
                                                background: none;
        
                                                &.wishlist {
                                                    width: 70%;
                                                }
        
                                                &:hover {
                                                    background: $primary-color;
                                                    border-color: $primary-color;
                                                    color: white;
                                                    opacity: 1;
                                                }
                                            }
        
                                            &.remove-cart {
                                                display: flex;
                                                align-items: center;
                                                justify-content: space-between;
                                                cursor: default;
        
                                                &:hover {
                                                    background: none;
                                                    border-color: $text-color;
                                                    color: $text-color;
                                                }
        
                                                i {
                                                    margin-right: 0;
                                                    transition: color .3s;
                                                    cursor: pointer;
        
                                                    &.fa-minus-circle:hover {
                                                        color: $negative-color;
                                                    }
        
                                                    &.fa-plus-circle:hover {
                                                        color: $positive-color;
                                                    }
                                                }
                                            }
        
                                            &.wished {
                                                &:hover {
                                                    background: $negative-color;
                                                    border-color: $negative-color;
                                                }
                                            }
        
                                            i {
                                                margin-right: .3rem;
                                            }
                                        }
                                    }
                                }
        
                                .currencies {
                                    display: flex;
                                    margin-bottom: 1rem;
                                    align-items: center;
        
                                    span {
                                        display: inline-block;
                                        font-size: .8rem;
                                        color: $text-color-alt;
                                        margin-right: .5rem;
                                    }
        
                                    img {
                                        display: inline-block;
                                        border-radius: 100%;
                                        height: 28px;
                                        margin-right: .5rem;
                                        width: 28px;
                                    }
        
                                    div.overflow {
                                        display: none;
                                        height: 28px;
                                        width: 28px;
                                        background: $primary-color;
                                        border-radius: 100px;
                                        position: relative;
                                        user-select: none;
                                        line-height: 28px;
        
                                        &:last-of-type {
                                            display: inline-block;
                                        }
        
                                        div.number {
                                            position: absolute;
                                            text-align: center;
                                            left: 0;
                                            right: 0;
                                            color: white;
                                            font-size: .8rem;
                                            font-weight: bold;
                                        }
                                    }
                                }
        
                                .cashback {
                                    display: flex;
                                    align-items: center;
                                    margin-top: 1rem;
        
                                    span {
                                        display: inline-block;
                                        font-size: .8rem;
                                        color: $text-color-alt;
                                        margin-right: .5rem;
                                    }
        
                                    .amount {
                                        background: $primary-color;
                                        color: white;
                                        padding: .2rem .4rem;
                                        font-weight: bold;
                                        line-height: 1.2;
                                        border-radius: 3px;
                                    }
                                }
                            }
                        }
        
                        .bottom-wrapper {
                            .description-tabs {
                                border-bottom: 1px solid $border-color;
                                margin-bottom: 2rem;
        
                                .tablink {
                                    background: none;
                                    font-weight: bold;
                                    font-size: 1.2rem;
                                    padding: 1rem 0;
                                    margin-right: 2rem;
                                    color: $text-color-alt;
                                    border-bottom: 2px solid rgba(0, 0, 0, 0,);
                                    transition: all .2s;
        
                                    &:hover {
                                        color: $text-color;
                                        border-bottom: 2px solid $primary-color;
                                    }
        
                                    &.active {
                                        color: $text-color;
                                        border-bottom: 2px solid $primary-color;
                                    }
        
                                    @keyframes fadeEffect {
                                        from { opacity: 0 }
                                        to { opacity: 1 }
                                    }
                                }
                            }
        
                            .tab-content {
                                color: $text-color;
                                line-height: 1.5;
                            }
                        }
                    }
                }
            }
        }
    }
}
