.top-categories {
    background: $background-light;
    color: $text-color;
    margin-top: 4rem;
    padding: 3rem;

    .title {
        font-size: 1.2rem;
        font-weight: bold;
        text-align: center;
        margin-bottom: 2rem;
    }

    @media only screen and (max-width: 1400px) {
        padding: 3rem 0;
    }

    @media only screen and (max-width: 768px) {
        margin-top: 2rem;
        padding: 2rem 0;
    }

    .categories-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 1rem;

        @media only screen and (max-width: 860px) {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
        }

        @media only screen and (max-width: 640px) {
            grid-template-columns: repeat(2, 1fr);
        }

        .category {
            position: relative;
            overflow: hidden;
            height: 190px;
            width: 190px;

            &:hover {
                img {
                    transform: scale(1.05);
                }

                .name {
                    &:before {
                        transform-origin: left center;
                        transform: scale(1, 1);
                        -webkit-transform: scale(1, 1);
                    }
                }
            }

            img {
                height: 100%;
                transition: all .3s;
                width: 100%;
            }

            span {
                position: absolute;
                bottom: 0;
                font-size: .9rem;
                left: 50%;
                padding-bottom: .5rem;
                transform: translate(-50%, -50%);
                transition: all .3s;
                white-space: nowrap;

                &:before {
                    position: absolute;
                    border-top: 1px solid $primary-color;
                    bottom: .3rem;
                    content: "";
                    transform-origin: right center;
                    transform: scale(0, 1);
                    transition: transform 0.3s ease, -webkit-transform 0.3s ease;
                    width: 100%;
                    -webkit-transform: scale(0, 1);
                    -webkit-transition: -webkit-transform 0.3s ease;
                }
            }

            @media only screen and (max-width: 860px) {
                margin: 0 auto;
            }

            @media only screen and (max-width: 430px) {
                height: 140px;
                width: 140px;
            }
        }
    }
}