.features-wrapper {
  margin: 3rem 0;

  @media only screen and (max-width: 768px) {
    margin: 1rem 0;
  }

  .features {
    color: $text-color;
    border: 1px solid $border-color;
    border-radius: 3px;
    padding: 1.5rem 1rem;

    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1rem;

    @media only screen and (max-width: 768px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media only screen and (max-width: 600px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media only screen and (max-width: 480px) {
      grid-template-columns: 1fr;
      grid-row-gap: 2rem;
    }

    .feature {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;

      i {
        color: $primary-color;
        font-size: 2rem;
        margin-right: 1rem;
      }

      .title {
        font-size: .9rem;
        font-weight: bold;
        margin-bottom: .3rem;
      }

      .subtitle {
        font-size: .8rem;
      }
    }
  }
}