section.checkout {
    .page-header {
        color: $text-color;
        background: rgba($background-light, 0.5);
        text-align: center;
        font-weight: bold;
        font-size: 1.8rem;
        padding: 3rem 0;
        border-bottom: 1px solid $border-color;

        @media only screen and (max-width: 600px) {
            font-size: 1.5rem;
            padding: 1rem 0;
        }
    }

    > .container {
        padding: 2rem 1rem;
        color: $text-color;

        @media only screen and (max-width: 600px) {
            padding: 1rem 1rem 4rem 1rem;
        }

        .checkout-wrapper {
            display: grid;
            grid-gap: 2rem;
            grid-template-columns: 3fr 1fr;
            margin-top: 2rem;
            min-height: 40rem;

            @media only screen and (max-width: 768px) {
                gap: 1rem;
                grid-template-columns: 11fr 5fr;
            }

            @media only screen and (max-width: 600px) {
                display: flex;
                flex-direction: column;
                gap: 2rem;
            }

            .checkout-contents {
                .subtitle {
                    font-weight: bold;
                    margin-bottom: 1rem;
                }

                .addresses {
                    display: flex;
                    grid-gap: 2rem;

                    @media only screen and (max-width: 768px) {
                        gap: 1rem;
                    }

                    @media only screen and (max-width: 420px) {
                        flex-direction: column;
                        gap: 2rem;
                    }

                    .billing-details, .shipping-details {
                        width: 100%;

                        > .address {
                            display: flex;
                            border-radius: 3px;
                            border: 1px solid $border-color;
                            flex-direction: column;
                            grid-gap: .3rem;
                            padding: 1rem;
                            width: 100%;

                            .name {
                                font-weight: bold;
                            }
                        }

                        .edit {
                            display: block;
                            -webkit-user-select: none;
                            background: white;
                            border: 1px solid $border-color;
                            border-radius: 3px;
                            color: $text-color-alt;
                            padding: 0.5rem 1rem;
                            transition: all 0.3s;
                            margin-top: 1rem;
                            width: 100%;

                            &:hover {
                                background: $text-color-alt;
                                color: white;
                            }
                        }
                    }
                }

                .shipping-services {
                    margin-top: 2rem;

                    div.empty {
                        font-size: .9rem;
                    }

                    .item {
                        display: flex;
                        align-items: center;
                        border-bottom: 1px solid $border-color;
                        flex-direction: column;
                        margin-bottom: 2rem;
                        padding-bottom: 2rem;

                        .mobile-div {
                            display: flex;
                            align-items: center;
                            grid-gap: 1rem;

                            @media only screen and (max-width: 420px) {
                                width: 100%;
                            }
                        }

                        &.disabled {
                            opacity: .75;
                        }

                        &:last-of-type {
                            border-bottom: none;
                            margin-bottom: 0;
                            padding-bottom: 0;
                        }

                        > div {
                            display: flex;
                            align-items: center;
                            grid-gap: 1rem;
                            width: 100%;

                            @media only screen and (max-width: 420px) {
                                flex-direction: column;
                            }

                            .thumbnail {
                                position: relative;
                                border-radius: 3px;
                                border: 1px solid $border-color;
                                height: 100px;
                                overflow: hidden;
                                width: 100px;
                                transition: all 0.3s;

                                &:hover {
                                    border-color: $primary-color;
                                }

                                img {
                                    position: absolute;
                                    max-width: 90%;
                                    max-height: 90%;
                                    margin: auto;
                                    left: 0;
                                    top: 0;
                                    right: 0;
                                    bottom: 0;
                                }
                            }

                            .details {
                                display: flex;
                                flex-direction: column;
                                grid-gap: .5rem;
                                justify-content: center;
                                width: 100%;

                                .title {
                                    display: -webkit-box;
                                    -webkit-box-orient: vertical;
                                    -webkit-line-clamp: 2;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    font-weight: bold;
                                    transition: all 0.3s;
                                    font-size: .8rem;

                                    &:hover {
                                        color: $primary-color;
                                    }
                                }

                                .price {
                                    font-weight: bold;
                                    color: $primary-color;

                                    span {
                                        color: $text-color;
                                        font-size: .8rem;
                                        font-weight: normal;
                                    }
                                }

                                .quantity {
                                    font-size: 1rem;
                                    user-select: none;

                                    i {
                                        cursor: pointer;
                                        font-size: 1rem;
                                        z-index: 1002;

                                        &.fa-plus-circle:hover {
                                            color: $positive-color;
                                        }

                                        &.fa-minus-circle:hover {
                                            color: $negative-color;
                                        }
                                    }
                                }

                                span.variation {
                                    display: flex;
                                    font-size: .8rem;
                                    gap: .3rem;
                                }
                            }

                            .shipping-options {
                                > div {
                                    display: flex;
                                    align-items: center;
                                    justify-content: flex-start;
                                }

                                input {
                                    appearance: auto;
                                }

                                label {
                                    margin-left: 1rem;

                                    .price {
                                        color: $primary-color;
                                        font-weight: bold;
                                        margin-top: .5rem;
                                        
                                        &.free {
                                            color: $positive-color;
                                        }

                                        &.unavailable {
                                            color: $negative-color;
                                        }
                                    }

                                    .estimate {
                                        font-size: .9rem;
                                        margin-top: .5rem;
                                    }
                                }
                            }

                            .escrow-status {
                                display: flex;
                                align-items: center;
                                gap: .5rem;
                                margin-left: auto;

                                > i {
                                    font-size: 1.5rem;
                                    color: $positive-color;

                                    &.warning {
                                        color: $warning-color;
                                    }
                                }

                                p {
                                    display: flex;
                                    font-size: .9rem;
                                    align-items: center;
                                    gap: .3rem;

                                    i {
                                        font-size: 12px;
                                    }
                                }
                            }
                        }

                        .payment-options {
                            display: flex;
                            flex-direction: row;
                            flex-wrap: wrap;
                            justify-content: flex-start;
                            grid-gap: 1rem;
                            grid-row-gap: 0;

                            @media only screen and (max-width: 420px) {
                                flex-direction: column;
                                width: 100%;
                            }
    
                            .input-group {
                                min-width: 290px !important;

                                @media only screen and (max-width: 420px) {
                                    width: 100%;
                                }
    
                                input {
                                    appearance: none;
    
                                    &:checked {
                                        + label {
                                            border-color: $primary-color;
    
                                            span {
                                                color: $primary-color;
                                            }
                                        }
                                    }
                                }
    
                                label {
                                    display: flex;
                                    align-items: center;
                                    border-radius: 3px;
                                    border: 1px solid $border-color;
                                    cursor: pointer;
                                    grid-gap: .5rem;
                                    padding: 1rem;
                                    transition: all .2s;
                                    width: 100%;
    
                                    img {
                                        width: 28px;
                                        height: 28px;
                                    }
    
                                    .value {
                                        font-weight: bold;
    
                                        span {
                                            color: $text-color-alt;
                                        }
                                    }
    
                                    i {
                                        color: $primary-color;
                                        font-size: 1rem;
                                    }
                                }
                            }
                        }
                    }
                }

                .order-notes {
                    margin-top: 2rem;

                    textarea {
                        border: 1px solid $border-color;
                        border-radius: 3px;
                        padding: 1rem;
                        width: 100%;
                        min-height: 100px;
                        resize: none;

                        &:focus {
                            border-color: $divider-color;
                            outline: none;
                            box-shadow: none;
                        }
                    }
                }
            }

            .checkout-sidebar {
                position: sticky;
                align-self: flex-start;
                top: 1rem;

                @media only screen and (max-width: 600px) {
                    width: 100%;
                }

                .cashback {
                    margin-top: 1rem;

                    p {
                        text-align: center;
                        border: 1px solid $border-color;
                        padding: .5rem 1rem;
                        border-radius: 3px;

                        span {
                            font-weight: bold;

                            &.symbol {
                                color: $primary-color;
                            }
                        }
                    }
                }

                .cart-summary {
                    border: 1px solid $border-color;
                    border-radius: 3px;
                    padding: 1rem;

                    .subtitle {
                        font-weight: bold;
                        text-align: left;
                        border-bottom: 1px solid $border-color;
                        padding-bottom: .5rem;
                        white-space: nowrap;
                    }

                    .feature {
                        padding: 1rem 0;
                        display: flex;
                        align-items: center;
                        grid-gap: .5rem;
                        border-bottom: 1px solid #eeeeee;


                        i {
                            display: flex;
                            color: #805fd3;
                            font-size: 1.5rem;
                            justify-content: center;
                            min-width: 30px;
                        }

                        p {
                            font-size: .9rem;
                        }
                    }

                    .cart-items {
                        margin-top: 1rem;
                        padding-bottom: 1rem;
                        border-bottom: 1px solid $border-color;

                        .empty {
                            font-size: .9rem;
                        }

                        .item {
                            display: flex;
                            margin-bottom: 1rem;
                            grid-gap: 1rem;

                            &:last-of-type {
                                margin-bottom: 0;
                            }

                            .thumbnail {
                                position: relative;
                                border-radius: 3px;
                                border: 1px solid $border-color;
                                height: 100px;
                                overflow: hidden;
                                width: 100px;
                                transition: all 0.3s;

                                &:hover {
                                    border-color: $primary-color;
                                }

                                img {
                                    position: absolute;
                                    max-width: 90%;
                                    max-height: 90%;
                                    margin: auto;
                                    left: 0;
                                    top: 0;
                                    right: 0;
                                    bottom: 0;
                                }
                            }

                            .details {
                                display: flex;
                                flex-direction: column;
                                grid-gap: .5rem;
                                justify-content: center;

                                .title {
                                    display: -webkit-box;
                                    -webkit-box-orient: vertical;
                                    -webkit-line-clamp: 2;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    font-weight: bold;
                                    transition: all 0.3s;
                                    font-size: .8rem;

                                    &:hover {
                                        color: $primary-color;
                                    }
                                }

                                .price {
                                    font-weight: bold;
                                    color: $primary-color;
                                }

                                .quantity {
                                    font-size: .8rem;
                                }
                            }
                        }
                    }

                    .subtotal {
                        display: flex;
                        margin-top: 1rem;
                        justify-content: space-between;
                        align-items: center;
                    }

                    .total {
                        margin-top: 1rem;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        //border-top: 1px solid $border-color;
                        // padding-top: 1rem;

                        p {
                            font-weight: bold;
                        }

                        span {
                            font-weight: bold;
                            color: $primary-color;
                            font-size: 1.4rem;
                        }
                    }

                    .checkout {
                        display: block;
                        -webkit-user-select: none;
                        background: $primary-color;
                        border-radius: 3px;
                        color: white;
                        font-weight: bold;
                        padding: 1rem 2rem;
                        margin-top: 1rem;
                        text-transform: uppercase;
                        transition: all .3s;
                        user-select: none;
                        width: 100%;

                        @media only screen and (max-width: 768px) {
                            padding: 1rem;
                        }

                        &.disabled {
                            background: $divider-color;
                        }

                        &:hover {
                            opacity: .9;
                        }

                        i {
                            margin-left: .3rem;
                        }
                    }
                }
            }
        }
    }
}
