section.error {
    margin-bottom: 10rem;

    .page-header {
        color: $text-color;
        background: rgba($background-light, 0.5);
        text-align: center;
        font-weight: bold;
        font-size: 1.8rem;
        padding: 3rem 0;
        border-bottom: 1px solid $border-color;
    }

    > .container {
        padding: 4rem 1rem;
        color: $text-color;
        text-align: center;

        i {
            font-size: 3rem;
            text-align: center;
            
            &.red {
                color: $negative-color;
            }

            &.amber {
                color: $warning-color;
            }

            &.grey {
                color: $text-color-alt;
            }
        }

        .description {
            text-align: center;
            font-size: 1.2rem;
            margin-top: 1rem;
        }

        button {
            display: block;
            -webkit-user-select: none;
            background: #805fd3;
            border-radius: 3px;
            color: white;
            font-size: .9rem;
            padding: .7rem 1.4rem;
            margin: 0 auto;
            transition: all 0.3s;
            user-select: none;
            margin-top: 1rem;

            &:hover {
                opacity: .9;
            }
        }
    }
}
