section.order-confirmation {
    margin-bottom: 10rem;

    @media only screen and (max-width: 600px) {
        margin-bottom: 0;
    }

    .page-header {
        color: $text-color;
        background: rgba($background-light, 0.5);
        text-align: center;
        font-weight: bold;
        font-size: 1.8rem;
        padding: 3rem 0;
        border-bottom: 1px solid $border-color;

        @media only screen and (max-width: 600px) {
            font-size: 1.5rem;
            padding: 1rem 0;
        }
    }

    > .container {
        padding: 2rem 1rem;
        color: $text-color;
        text-align: center;

        @media only screen and (max-width: 600px) {
            padding: 1rem 1rem 4rem 1rem;
        }

        i {
            font-size: 3rem;
            margin-top: 3rem;
            text-align: center;
            color: $positive-color;

            @media only screen and (max-width: 600px) {
                margin-top: 1rem;
            }
        }

        .thank {
            margin-top: 1rem;
            text-align: center;
            font-size: 2rem;
        }

        .orderNo {
            text-align: center;
            font-size: 1.2rem;
            margin-top: 1rem;
        }

        .tx {
            text-align: center;
            margin-top: 1rem;
            font-size: .8rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        button {
            display: block;
            -webkit-user-select: none;
            background: #805fd3;
            border-radius: 3px;
            color: white;
            font-weight: bold;
            padding: 1rem 2rem;
            margin: 0 auto;
            text-transform: uppercase;
            transition: all 0.3s;
            user-select: none;
            margin-top: 1rem;

            &:hover {
                opacity: .9;
            }
        }
    }
}
