section.shipping-zones {
    margin-bottom: 10rem;

    .page-header {
        color: $text-color;
        background: rgba($background-light, 0.5);
        text-align: center;
        font-weight: bold;
        font-size: 1.8rem;
        padding: 3rem 0;
        border-bottom: 1px solid $border-color;

        @media only screen and (max-width: 600px) {
            font-size: 1.5rem;
            padding: 1rem 0;
        }
    }

    > .container {
        color: $text-color;
        max-width: 960px;
        padding: 2rem 1rem;
        text-align: center;

        @media only screen and (max-width: 600px) {
            padding: 1rem 1rem 4rem 1rem;
        }
        
        img {
            @media only screen and (max-width: 768px) {
                width: 90vw;
            }
        }

        .accordion {
            text-align: left;
            transition: .3s;
            border-bottom: 1px solid $border-color;

            &:last-of-type {
                border-bottom: none;
            }

            &.active {
                .panel {
                    padding: .5rem 0;
                    max-height: 100rem;
                }

                button:after {
                    content: "\f068";
                }
            }

            button {
                background-color: #f9f9f9;
                border: none;
                outline: none;
                padding: .5rem 1rem;
                text-align: left;
                transition: .3s;
                width: 100%;

                &:after {
                    color: $text-color;
                    content: "\f067";
                    float: right;
                    font-family: "Font Awesome 5 Pro";
                    margin-left: .5rem;
                }
            }

            .panel {
                background-color: white;
                max-height: 0;
                overflow: hidden;
                padding: 0;
                transition: max-height .3s, padding .3s ease-out;

                p {
                    line-height: 1.9;
                    letter-spacing: 0.2px;
                }
            }
        }
    }
}
