section.vouchers {
    margin-bottom: 2rem;

    div.buy-voucher {
        display: grid;
        gap: 1rem;
        grid-template-columns: repeat(3, 1fr);
        margin-top: 2rem;

        .voucher {
            position: relative;
            border-radius: 6px;
            border: 1px solid $border-color;
            min-width: 170px;
            overflow: hidden;
            padding: 1rem;

            .watermark {
                margin-bottom: 1rem;
                opacity: .5;
                width: 80px;
            }

            .denomination {
                font-weight: bold;
                font-size: 1.5rem;
                margin-bottom: .5rem;
            }

            .ticker {
                color: $text-color-alt;
                font-weight: bold;
                font-size: 1rem;
                margin-bottom: .5rem;
            }

            .buy {
                width: 100%;
                padding: .5rem 1rem;
                border-radius: 3px;
                transition: all .2s;

                &:hover {
                    opacity: .9;
                }
            }

            .logo {
                position: absolute;
                bottom: 0;
                top: -3rem;
                margin: auto 0;
                opacity: .4;
                right: -3rem;
                width: 170px;
                transform: rotate(15deg);
                z-index: -1;
            }
        }
    }
}

section.promotions {
    ul {
        display: flex;
        flex-direction: column;
        grid-gap: 1rem;

        div.empty {
            color: $text-color-alt;
            //font-style: italic;
            text-align: center;
        }

        span.empty {
            display: block;
            color: $text-color-alt;
            text-align: center;

            a {
                font-weight: bold;
                transition: all .2s;

                &:hover {
                    color: $primary-color;
                }
            }
        }
    }
}