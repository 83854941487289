$alt-font-family: sans-serif !default;
$font-family: "Roboto", $alt-font-family !important;
$second-font-family: "Open Sans", $alt-font-family !default;

$primary-color: #805fd3;
$text-color: #3a3a3a;
$text-color-alt: #7f7f7f;
$divider-color: #cfcfcf;
$border-color: #eeeeee;
$border-color-alt: #ededed;
$positive-color: #79ba5b;
$negative-color: #d35f5f;
$warning-color: #d3a85f;

$background-light: #f5f5f5;
$background-medium: #E2E2E2;
$background-dark: #cccccc;
