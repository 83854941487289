.container {
    display: block;
    max-width: 1280px;
    width: 100%;
    padding: 0 1rem;
    margin: 0 auto;
}

.container-small {
    display: block;
    max-width: 960px;
    width: 100%;
    padding: 0 1rem;
    margin: 0 auto;
}

.container-full {
    display: block;
    width: 100%;
    margin: 0 auto;

    &.alt {
        background: $background-light;
    }
}
