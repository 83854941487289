.blog {
    color: $text-color;
    margin-bottom: 3rem;

    .section-title {
        display: flex;
        font-weight: bold;
        font-size: 1.2rem;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1rem;
        padding-bottom: 1rem;
        border-bottom: 1px solid $border-color;

        a {
            font-weight: normal;
            font-size: 0.9rem;
            display: flex;
            align-items: center;
            grid-gap: 0.3rem;
            opacity: .5;
            transition: all .3s;

            &:hover {
                opacity: 1;
            }
        }
    }

    .blog-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        grid-gap: 1rem;

        .blog-post {
            .thumbnail-wrapper {
                position: relative;
                overflow: hidden;
                width: 295px;
                height: 190px;
                border-radius: 3px;

                img {
                    height: 100%;
                    width: 100%;
                }

                .date {
                    position: absolute;
                    background: white;
                    border-radius: 3px;
                    border: 1px solid $border-color;
                    left: .5rem;
                    opacity: .9;
                    padding: .4rem .6rem;
                    text-align: center;
                    top: .5rem;
                    z-index: 1;

                    .month {
                        font-size: .7rem;
                    }

                    .day {
                        color: $primary-color;
                        font-size: 1.1rem;
                        font-weight: bold;
                    }
                }
            }

            .info-wrapper {
                color: $text-color;
                margin-top: 0.6rem;

                a {
                    font-weight: bold;
                    transition: all .3s;

                    &:hover {
                        color: $primary-color;
                    }
                }

                .author {
                    font-size: .9rem;
                    margin-top: .2rem;

                    a {
                        color: $primary-color;
                        font-weight: bold;
                    }
                }
            }
        }
    }
}