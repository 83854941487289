@font-face {
    font-family: geforce-bold;
    font-display: swap;
    src: url("/assets/fonts/geforce/geforce-bold.ttf");
    font-weight: bold;
}

@font-face {
    font-family: geforce-light;
    font-display: swap;
    src: url("/assets/fonts/geforce/geforce-light.ttf");
    font-weight: 300;
}

@font-face {
    font-family: sf-medium;
    font-display: swap;
    src: url("/assets/fonts/sanfrancisco/SF-Pro-Display-Medium.otf");
    font-weight: 300;
}

@font-face {
    font-family: sf-regular;
    font-display: swap;
    src: url("/assets/fonts/sanfrancisco/SF-Pro-Display-Regular.otf");
    font-weight: 300;
}

section.index {
    .slideshow-wrapper {
        position: relative;

        > .container-full {
            display: flex;
            min-height: 516.39px;
            overflow: hidden;
            scroll-behavior: smooth;
            scroll-snap-type: x mandatory;

            @media only screen and (max-width: 768px) {
                min-height: 450px;
            }

            @media only screen and (max-width: 690px) {
                min-height: 400px;
            }

            @media only screen and (max-width: 600px) {
                min-height: 350px;
            }

            @media only screen and (max-width: 480px) {
                min-height: 700px;
            }

            .slideshow {
                position: relative;
                background: rgba($primary-color, 0.03);

                .slideshow-background {
                    height: 100%;
                    width: 100vw;
                    z-index: -1;

                    @media only screen and (max-width: 480px) {
                        visibility: hidden;
                    }
                }

                &.phone-001 {
                    div.content-wrapper {
                        display: flex;
                        position: absolute;
                        align-items: center;
                        grid-gap: 2rem;
                        justify-content: center;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        width: 100%;
                        padding: 0 4rem;

                        @media only screen and (max-width: 1200px) {
                            margin-left: 0;
                        }

                        @media only screen and (max-width: 690px) {
                            padding: 0 2rem;
                        }

                        @media only screen and (max-width: 600px) {
                            grid-gap: 1rem;
                        }

                        @media only screen and (max-width: 480px) {
                            flex-direction: column;
                            grid-gap: 2rem;
                            top: 46%;
                        }

                        div.content {
                            color: $text-color;
                            max-width: 628px;
                            width: 100%;

                            @media only screen and (max-width: 1200px) {
                                margin-left: 0;
                            }

                            @media only screen and (max-width: 480px) {
                                order: 2;
                            }

                            div.title {
                                display: block;
                                font-family: sf-medium;
                                font-size: 3rem;

                                @media only screen and (max-width: 768px) {
                                    font-size: 2.5rem;
                                }

                                @media only screen and (max-width: 600px) {
                                    font-size: 2rem;
                                }

                                @media only screen and (max-width: 480px) {
                                    font-size: 1.8rem;
                                }
                            }

                            div.subtitle {
                                display: none;
                            }

                            div.description {
                                display: block;
                                font-family: sf-regular;
                                font-size: 1.5rem;
                                margin-top: 2rem;
                                
                                @media only screen and (max-width: 768px) {
                                    font-size: 1.2rem;
                                    margin-top: 1rem;
                                }

                                @media only screen and (max-width: 600px) {
                                    font-size: 1rem;
                                }

                                @media only screen and (max-width: 480px) {
                                    display: -webkit-box;
                                    -webkit-box-orient: vertical;
                                    -webkit-line-clamp: 4;
                                    font-size: 1.4rem;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                }

                                @media only screen and (max-width: 380px) {
                                    max-height: 22vw;
                                    overflow: hidden;
                                    margin-top: .5rem;
                                    -webkit-line-clamp: 3;
                                }
                            }

                            a.browse {
                                display: inline-block;
                                border-radius: 3px;
                                border: 1px solid $text-color;
                                font-size: 1rem;
                                font-weight: bold;
                                margin-top: 2rem;
                                padding: 1rem;
                                text-transform: uppercase;
                                transition: all .2s;

                                @media only screen and (max-width: 768px) {
                                    margin-top: 1rem;
                                    padding: 0;
                                    border: none;
                                }

                                @media only screen and (max-width: 600px) {
                                    font-size: .9rem;
                                }

                                @media only screen and (max-width: 480px) {
                                    display: inline-block;
                                    -webkit-user-select: none;
                                    background: $primary-color;
                                    border-radius: 3px;
                                    color: white;
                                    font-size: .9rem;
                                    padding: .7rem 1.4rem;
                                    transition: all .2s;
                                    user-select: none;
                                }

                                @media only screen and (max-width: 380px) {
                                    margin-top: .5rem;
                                }

                                &:hover {
                                    background: $text-color;
                                    color: white;
                                }
                            }
                        }

                        img {
                            max-height: 400px;
                            max-width: 400px;
                            opacity: .9;
                            //margin-right: -4rem;

                            @media only screen and (max-width: 768px) {
                                max-height: 300px;
                                max-width: 300px;
                                margin-right: -3rem;
                            }

                            @media only screen and (max-width: 690px) {
                                margin-right: 0 !important;
                            }

                            @media only screen and (max-width: 600px) {
                                max-height: 250px;
                                max-width: 250px;
                                margin-right: -2rem;
                            }

                            @media only screen and (max-width: 480px) {
                                max-height: 350px;
                                max-width: 100%;
                                margin-right: -2rem;
                            }

                            @media only screen and (max-width: 380px) {
                                
                            }
                        }
                    }
                }

                &.gpu-001 {
                    div.content-wrapper {
                        display: flex;
                        position: absolute;
                        align-items: center;
                        grid-gap: 2rem;
                        justify-content: center;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        width: 100%;
                        padding: 0 4rem;

                        @media only screen and (max-width: 1200px) {
                            margin-left: 0;
                        }

                        @media only screen and (max-width: 690px) {
                            padding: 0 2rem;
                        }

                        @media only screen and (max-width: 600px) {
                            grid-gap: 1rem;
                        }

                        @media only screen and (max-width: 480px) {
                            flex-direction: column;
                            grid-gap: 2rem;
                            top: 46%;
                        }

                        div.content {
                            color: $text-color;
                            max-width: 628px;
                            width: 100%;

                            @media only screen and (max-width: 1200px) {
                                margin-left: 0;
                            }

                            @media only screen and (max-width: 480px) {
                                order: 2;
                            }

                            div.title {
                                display: block;
                                //color: #76b900;
                                font-family: geforce-bold;
                                font-size: 3rem;
                                text-transform: uppercase;

                                @media only screen and (max-width: 768px) {
                                    font-size: 2rem;
                                }

                                @media only screen and (max-width: 600px) {
                                    font-size: 1.8rem;
                                }

                                @media only screen and (max-width: 480px) {
                                    font-size: 1.8rem;
                                }

                                @media only screen and (max-width: 390px) {
                                    font-size: 1.2rem;
                                }
                            }

                            div.subtitle {
                                display: block;
                                font-family: geforce-bold;
                                font-size: 2rem;
                                text-transform: uppercase;

                                @media only screen and (max-width: 768px) {
                                    font-size: 1.5rem;
                                }

                                @media only screen and (max-width: 600px) {
                                    font-size: 1.2rem;
                                }

                                @media only screen and (max-width: 390px) {
                                    font-size: .9rem;
                                }
                            }

                            div.description {
                                display: block;
                                font-family: geforce-light;
                                font-weight: bold;
                                font-size: 1.5rem;
                                margin-top: 2rem;

                                @media only screen and (max-width: 980px) {
                                    margin-top: 1rem;
                                }

                                @media only screen and (max-width: 768px) {
                                    font-size: 1.2rem;
                                }

                                @media only screen and (max-width: 600px) {
                                    font-size: 1rem;
                                }

                                @media only screen and (max-width: 480px) {
                                    display: -webkit-box;
                                    -webkit-box-orient: vertical;
                                    -webkit-line-clamp: 4;
                                    font-size: 1.4rem;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                }

                                @media only screen and (max-width: 380px) {
                                    max-height: 22vw;
                                    overflow: hidden;
                                    margin-top: .5rem;
                                    -webkit-line-clamp: 3;
                                }
                            }

                            a.browse {
                                display: inline-block;
                                background: rgba(0, 0, 0, 0);
                                border-radius: 3px;
                                border: 1px solid $text-color;
                                font-size: 1rem;
                                font-weight: bold;
                                margin-top: 2rem;
                                padding: 1rem;
                                text-transform: uppercase;
                                transition: all .2s;

                                @media only screen and (max-width: 980px) {
                                    margin-top: 1rem;
                                }

                                @media only screen and (max-width: 768px) {
                                    padding: 0;
                                    border: none;
                                }

                                @media only screen and (max-width: 600px) {
                                    font-size: .9rem;
                                }

                                @media only screen and (max-width: 480px) {
                                    display: inline-block;
                                    -webkit-user-select: none;
                                    background: $primary-color;
                                    border-radius: 3px;
                                    color: white;
                                    font-size: .9rem;
                                    padding: .7rem 1.4rem;
                                    transition: all .2s;
                                    user-select: none;
                                }

                                @media only screen and (max-width: 380px) {
                                    margin-top: .5rem;
                                }

                                &:hover {
                                    background: $text-color;
                                    color: white;
                                }
                            }

                            img.brand {
                                display: block;
                                padding-top: 2rem;
                                width: 150px;
                            }
                        }

                        img {
                            max-height: 400px;
                            max-width: 400px;
                            opacity: .9;

                            @media only screen and (max-width: 768px) {
                                max-height: 300px;
                                max-width: 300px;
                            }

                            @media only screen and (max-width: 600px) {
                                max-height: 250px;
                                max-width: 250px;
                            }

                            @media only screen and (max-width: 480px) {
                                max-height: 200px;
                                max-width: 200px;
                            }

                            @media only screen and (max-width: 480px) {
                                max-height: 350px;
                                max-width: 100%;
                                margin-right: -2rem;
                            }

                            @media only screen and (max-width: 380px) {
                                
                            }
                        }
                    }
                }
            }
        }

        .slideshow-progress {
            display: flex;
            border-radius: 30px;
            bottom: 0.5rem;
            left: 50%;
            padding: 0.5rem;
            position: absolute;
            transform: translate(-50%, -50%);
            z-index: 0;

            div {
                display: inline-block;
                height: 10px;
                width: 10px;
                background: $background-dark;
                border-radius: 100%;
                margin-right: 1rem;
                cursor: pointer;
                transition: all .2s;

                &.active {
                    background: $primary-color;
                }

                &:last-of-type {
                    margin-right: 0;
                }
            }
        }
    }

    .popular-categories {
        margin-bottom: 3rem;

        @media only screen and (max-width: 768px) {
            margin-bottom: 1rem;
        }

        > .container {
            display: flex;
            grid-gap: 2rem;

            @media only screen and (max-width: 768px) {
                flex-direction: column;
                gap: 1rem;
            }

            .category {
                position: relative;
                border: 1px solid $border-color;
                border-radius: 3px;
                height: 200px;
                overflow: hidden;
                transition: all .3s;
                width: 608px;

                @media only screen and (max-width: 1280px) {
                    height: 15.6vw;
                    width: 47.5vw;
                }

                @media only screen and (max-width: 768px) {
                    height: 28vw;
                    width: 100%;
                }

                &:hover {
                    .content {
                        > .title {
                            &:before {
                                transform-origin: left center;
                                transform: scale(1, 1);
                                -webkit-transform: scale(1, 1);
                            }
                        }
                    }
                }

                .category-background {
                    position: absolute;
                    height: 100%;
                    left: 0;
                    top: 0;
                    transition: all .3s;
                    width: 100%;
                }

                .content {
                    position: absolute;
                    color: $text-color;
                    left: 2rem;
                    top: 50%;
                    transform: translate(0, -50%);
                    transition: all .3s;

                    @media only screen and (max-width: 900px) {
                        left: 1rem;
                    }

                    @media only screen and (max-width: 768px) {
                        left: 2rem;
                    }

                    @media only screen and (max-width: 490px) {
                        left: 1rem;

                        &.top {
                            top: 40%;
                        }
                    }

                    @media only screen and (max-width: 390px) {
                        .heading {
                            font-size: 1rem !important;
                        }

                        .title {
                            font-size: 1.2rem !important;
                        }

                        .example {
                            font-size: .9rem !important;
                            margin-top: 0 !important;
                        }
                    }

                    &.light {
                        .heading {
                            color: rgba(255, 255, 255, 0.8);
                        }

                        .title {
                            color: white;
                        }

                        .example {
                            color: white;
                            margin-top: .5rem;
                        }
                    }

                    .heading {
                        font-size: 1.2rem;

                        span {
                            color: $primary-color;
                            font-weight: bold;
                            text-transform: uppercase;
                        }
                    }

                    .title {
                        font-size: 1.5rem;
                        font-weight: bold;
                        //max-width: 95%;
                        transition: all .3s;

                        &:before {
                            position: absolute;
                            border-top: 1px solid #805fd3;
                            bottom: -0.5rem;
                            content: "";
                            left: 0;
                            transform-origin: right center;
                            transform: scale(0, 1);
                            transition: transform 0.3s ease;
                            width: 100%;
                            -webkit-transform: scale(0, 1);
                            -webkit-transition: -webkit-transform 0.3s ease;
                        }
                    }

                    .example {
                        margin-top: 1rem;

                        span {
                            font-weight: bold;
                        }
                    }
                }
            }
        }
    }

    .storex-deals {
        > .container {
            display: grid;
            color: $text-color;
            grid-gap: 1rem;
            grid-template-columns: 3fr 1fr;

            @media only screen and (max-width: 1100px) {
                display: flex;
                flex-direction: column;
            }

            .weekly-deal {
                border: 1px solid $primary-color;
                border-radius: 3px;
                padding: 1.5rem;
                position: relative;

                @media only screen and (max-width: 600px) {
                    padding: 1rem;
                }

                .title {
                    display: flex;
                    align-items: center;
                    border-bottom: 1px solid $border-color;
                    font-size: 1.2rem;
                    font-weight: bold;
                    justify-content: space-between;
                    padding-bottom: 1rem;

                    i {
                        font-size: 1.2rem;
                    }
                }

                div.loading {
                    display: flex;
                    bottom: 0;
                    flex-direction: column;
                    justify-content: center;
                    left: 0;
                    margin-left: auto;
                    margin-right: auto;
                    position: absolute;
                    right: 0;
                    text-align: center;
                    top: 0;
    
                    .banner {
                        display: flex;
                        align-items: center;
                        border: 1px solid $border-color;
                        border-radius: 3px;
                        color: $text-color-alt;
                        flex-direction: column;
                        opacity: .75;
                        padding: 1rem 2rem;
    
                        i {
                            font-size: 2rem;
                        }
    
                        div {
                            margin-top: 1rem;
    
                            a {
                                font-weight: bold;
                                transition: all .2s;
    
                                &:hover {
                                    color: $primary-color;
                                }
                            }
                        }
                    }
    
                    > i {
                        animation: rotation 1s infinite linear;
                        color: $primary-color;
                        font-size: 3rem;
                        padding-top: 1px;
                        transition: all .2s;
                        user-select: none;
                        
                        @keyframes rotation {
                            from {
                                transform: rotate(0deg);
                            }
    
                            to {
                                transform: rotate(359deg);
                            }
                        }
                    }
    
                    span {
                        margin-top: 1rem;
                        color: $text-color-alt;
                    }
                }

                .listing-wrapper {
                    display: flex;
                    align-items: flex-start;
                    grid-gap: 1rem;
                    margin-top: 1rem;
                    visibility: hidden;

                    &.active {
                        visibility: visible;
                    }

                    @media only screen and (max-width: 600px) {
                        flex-direction: column;
                    }

                    .thumbnail-wrapper {
                        border-right: 1px solid $border-color;
                        box-sizing: content-box;
                        padding-right: 1rem;
                        user-select: none;

                        @media only screen and (max-width: 600px) {
                            border-right: none;
                            padding-right: 0;
                            width: 100%;
                        }

                        .thumbnail {
                            display: block;
                            background: white;
                            //border-radius: 6px 6px 0 0;
                            //border: 1px solid $border-color; // temp
                            height: 350px;
                            overflow: hidden;
                            position: relative;
                            width: 350px;

                            @media only screen and (max-width: 700px) {
                                height: 300px;
                                width: 300px;
                            }

                            @media only screen and (max-width: 600px) {
                                height: 300px;
                                width: 100%;
                            }

                            img, iframe {
                                position: absolute;
                                max-height: 350px;
                                max-width: 350px;
                                margin: auto;
                                left: 0;
                                top: 0;
                                right: 0;
                                bottom: 0;

                                @media only screen and (max-width: 700px) {
                                    max-height: 300px;
                                    max-width: 300px;
                                }
                            }

                            iframe {
                                position: absolute;
                                height: 350px;
                                width: 350px;
                                margin: auto;
                                left: 0;
                                top: 0;
                                right: 0;
                                bottom: 0;
                            }
                        }

                        .previews-wrapper {
                            height: 80px;
                            margin-top: .5rem;
                            overflow: hidden;
                            position: relative;
                            //border-radius: 0 0 6px 6px;
                            //border: 1px solid $border-color; // temp
                            //border-top: none;

                            @media only screen and (max-width: 700px) {
                                height: 68px;
                            }

                            @media only screen and (max-width: 600px) {
                                height: 19vw;
                            }

                            .previews {
                                display: flex;
                                //border-radius: 0 6px 6px 0;
                                width: 350px;
                                overflow: hidden;
                                position: relative;
                                scroll-behavior: smooth;
                                scroll-snap-type: x mandatory;
                                height: 80px;
                                flex-direction: row;
                                grid-gap: 10px;

                                @media only screen and (max-width: 700px) {
                                    height: 68px;
                                    width: 300px;
                                }

                                @media only screen and (max-width: 600px) {
                                    height: 19vw;
                                    width: 100%;
                                }

                                .image {
                                    display: inline-block;
                                    background: white;
                                    //border: 1px solid $border-color; // temp
                                    min-width: 80px;
                                    overflow: hidden;
                                    position: relative;

                                    @media only screen and (max-width: 700px) {
                                        min-width: 68px;
                                    }

                                    @media only screen and (max-width: 600px) {
                                        min-width: 19vw;
                                    }

                                    i {
                                        display: flex;
                                        align-items: center;
                                        color: white;
                                        font-size: 2rem;
                                        height: 100%;
                                        justify-content: center;
                                        position: absolute;
                                        width: 100%;
                                        z-index: 10;
                                    }

                                    img {
                                        position: absolute;
                                        max-width: 100%;
                                        max-height: 80px;
                                        margin: auto;
                                        left: 0;
                                        top: 0;
                                        right: 0;
                                        bottom: 0;

                                        @media only screen and (max-width: 700px) {
                                            max-height: 68px;
                                        }

                                        @media only screen and (max-width: 600px) {
                                            max-height: 19vw;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .details-wrapper {
                        //max-height: 420px;
                        width: 100%;
                        //overflow-y: scroll;

                        &::-webkit-scrollbar {
                            width: .5rem;
                            border-radius: 10px;
                            margin-left: 1rem;
                        }

                        &::-webkit-scrollbar-track {
                            background: #f1f1f1;
                            border-radius: 10px;
                        }

                        &::-webkit-scrollbar-thumb {
                            background: #888;
                        }

                        &::-webkit-scrollbar-thumb:hover {
                            background: #555;
                        }

                        .listing-title {
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 2;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            font-size: 1.2rem;
                            font-weight: bold;
                            margin-bottom: 1rem;
                            transition: all .3s;

                            &:hover {
                                color: $primary-color;
                            }
                        }

                        .listing-price {
                            border-top: 1px solid $border-color;
                            font-size: 1.8rem;
                            font-weight: bold;
                            margin-bottom: 1rem;
                            padding-top: 1rem;

                            .postage {
                                display: inline-block;
                                color: $text-color;
                                margin-left: .5rem;
                                font-size: .8rem;
                                font-weight: normal;

                                span {
                                    font-weight: bold;

                                    &.free {
                                        color: $positive-color;
                                    }
                                }
                            }
                        }

                        .listing-stock {
                            margin-bottom: 1rem;

                            .in-stock {
                                color: $positive-color;
                            }

                            .out-stock {
                                color: $negative-color;
                            }

                            i {
                                font-weight: bold;
                                margin-right: .3rem;
                            }
                        }

                        .listing-reviews {
                            display: inline-flex;
                            align-items: center;
                            color: $divider-color;
                            grid-gap: .3rem;
                            margin-bottom: 1rem;

                            i.fill {
                                color: $primary-color;
                            }

                            i.fad {
                                &:before {
                                    color: $primary-color;
                                }
                            }

                            span {
                                color: $text-color-alt;
                                font-size: .8rem;
                            }
                        }

                        .listing-options {
                            margin-bottom: 1rem;

                            .attribute {
                                display: flex;
                                margin-bottom: 1rem;
                                gap: .5rem;

                                span {
                                    display: inline-flex;
                                    align-items: flex-start;
                                    font-size: .8rem;
                                    padding: .3rem 0;
                                    line-height: 18px;
                                }

                                .radio-wrapper {
                                    display: inline-flex;
                                    flex-wrap: wrap;
                                    gap: .5rem;

                                    > div {
                                        display: inline-block;

                                        input {
                                            display: none;

                                            &:checked {
                                                + label {
                                                    color: $primary-color !important;
                                                    border-color: $primary-color !important;
                                                }
                                            }

                                            &:disabled {
                                                + label {
                                                    color: $border-color !important;
                                                    border-color: $border-color !important;
                                                }
                                            }
                                        }

                                        label {
                                            display: inline-block;
                                            border: 1px solid $divider-color;
                                            color: $text-color-alt;
                                            font-size: .8rem;
                                            line-height: 1rem;
                                            //margin-right: .5rem;
                                            padding: .3rem .5rem;
                                            transition: all .3s;
                                            font-weight: bold;
                                            border-radius: 3px;

                                            &:hover {
                                                border-color: $primary-color;
                                                color: $primary-color;
                                            }
                                        }
                                    }
                                }
                            }

                            .no-options {
                                display: block;
                                //font-style: italic;
                                margin-bottom: 1rem;
                                font-size: .8rem;
                            }

                            .reset {
                                display: inline-block;
                                font-size: .8rem;
                                opacity: .5;
                                transition: all .3s;

                                &:hover {
                                    opacity: 1;
                                }

                                i {
                                    font-size: .8rem;
                                    margin-right: .3rem;
                                }
                            }
                        }

                        .listing-checkout {
                            display: block;
                            border-top: 1px solid $border-color;
                            padding-top: 1rem;

                            .row {
                                display: flex;
                                grid-column-end: 3;
                                grid-column-gap: .5rem;
                                grid-column-start: 1;
                                width: 100%;
                                margin-bottom: 1rem;

                                @media only screen and (max-width: 700px) {
                                    flex-direction: column;
                                    gap: 1rem;
                                }

                                button {
                                    display: block;
                                    background: #805fd3;
                                    border-radius: 3px;
                                    color: white;
                                    font-weight: bold;
                                    margin: 0 auto;
                                    padding: 1rem 2rem;
                                    text-transform: uppercase;
                                    transition: all 0.2s;
                                    -webkit-user-select: none;
                                    user-select: none;

                                    @media only screen and (max-width: 700px) {
                                        width: 100% !important;
                                    }

                                    &:hover {
                                        opacity: .9;
                                    }

                                    &.purchase {
                                        width: 100%;
                                    }

                                    &.wishlist, &.cart {
                                        padding: 1rem !important;
                                        width: 100%;
                                        border: 1px solid $text-color;
                                        color: $text-color;
                                        background: none;

                                        &.wishlist {
                                            width: 70%;
                                        }

                                        &:hover {
                                            background: $primary-color;
                                            border-color: $primary-color;
                                            color: white;
                                            opacity: 1;
                                        }
                                    }

                                    &.remove-cart {
                                        display: flex;
                                        align-items: center;
                                        justify-content: space-between;
                                        cursor: default;

                                        &:hover {
                                            background: none;
                                            border-color: $text-color;
                                            color: $text-color;
                                        }

                                        i {
                                            margin-right: 0;
                                            transition: color .3s;
                                            cursor: pointer;

                                            &.fa-minus-circle:hover {
                                                color: $negative-color;
                                            }

                                            &.fa-plus-circle:hover {
                                                color: $positive-color;
                                            }
                                        }
                                    }

                                    &.wished {
                                        &:hover {
                                            background: $negative-color;
                                            border-color: $negative-color;
                                        }
                                    }

                                    i {
                                        margin-right: .3rem;
                                    }
                                }
                            }
                        }

                        .listing-currencies {
                            display: flex;
                            margin-bottom: 1rem;
                            align-items: center;

                            span {
                                display: inline-block;
                                font-size: .8rem;
                                color: $text-color-alt;
                                margin-right: .5rem;
                            }

                            img {
                                display: inline-block;
                                height: 28px;
                                width: 28px;
                                margin-right: .5rem;
                            }

                            div.overflow {
                                display: none;
                                height: 28px;
                                width: 28px;
                                background: $primary-color;
                                border-radius: 100px;
                                position: relative;
                                user-select: none;
                                line-height: 28px;

                                &:last-of-type {
                                    display: inline-block;
                                }

                                div.number {
                                    position: absolute;
                                    text-align: center;
                                    left: 0;
                                    right: 0;
                                    color: white;
                                    font-size: .8rem;
                                    font-weight: bold;
                                }
                            }
                        }
                    }
                }
            }

            .most-popular {
                border-radius: 3px;
                border: 1px solid $border-color;
                color: $text-color;
                min-height: 20rem;
                padding: 1.5rem;
                position: relative;

                .title {
                    display: flex;
                    align-items: center;
                    border-bottom: 1px solid $border-color;
                    font-size: 1.2rem;
                    font-weight: bold;
                    justify-content: space-between;
                    padding-bottom: 1rem;

                    i {
                        font-size: 1.2rem;
                    }
                }

                div.loading {
                    display: flex;
                    bottom: 0;
                    flex-direction: column;
                    justify-content: center;
                    left: 0;
                    margin-left: auto;
                    margin-right: auto;
                    position: absolute;
                    right: 0;
                    text-align: center;
                    top: 0;
    
                    .banner {
                        display: flex;
                        align-items: center;
                        border: 1px solid $border-color;
                        border-radius: 3px;
                        color: $text-color-alt;
                        flex-direction: column;
                        opacity: .75;
                        padding: 1rem 2rem;
    
                        i {
                            font-size: 2rem;
                        }
    
                        div {
                            margin-top: 1rem;
    
                            a {
                                font-weight: bold;
                                transition: all .2s;
    
                                &:hover {
                                    color: $primary-color;
                                }
                            }
                        }
                    }
    
                    > i {
                        animation: rotation 1s infinite linear;
                        color: $primary-color;
                        font-size: 3rem;
                        padding-top: 1px;
                        transition: all .2s;
                        user-select: none;
                        
                        @keyframes rotation {
                            from {
                                transform: rotate(0deg);
                            }
    
                            to {
                                transform: rotate(359deg);
                            }
                        }
                    }
    
                    span {
                        margin-top: 1rem;
                        color: $text-color-alt;
                    }
                }

                .listings-wrapper {
                    max-width: 300px;
                    visibility: hidden;

                    &.active {
                        visibility: visible;
                    }

                    @media only screen and (max-width: 1100px) {
                        display: grid;
                        grid-column-gap: 1rem;
                        grid-template-columns: repeat(2, minmax(0, 1fr));
                        max-width: unset;

                        .listing {
                            width: 100%;
                        }
                    }

                    @media only screen and (max-width: 768px) {
                        display: flex;
                        flex-direction: column;
                    }

                    div.loading {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        text-align: center;

                        .banner {
                            display: flex;
                            align-items: center;
                            border: 1px solid $border-color;
                            border-radius: 3px;
                            color: $text-color-alt;
                            flex-direction: column;
                            opacity: .75;
                            padding: 1rem 2rem;

                            i {
                                font-size: 2rem;
                            }

                            div {
                                margin-top: 1rem;

                                a {
                                    font-weight: bold;
                                    transition: all .2s;

                                    &:hover {
                                        color: $primary-color;
                                    }
                                }
                            }
                        }

                        > i {
                            animation: rotation 1s infinite linear;
                            color: $primary-color;
                            font-size: 3rem;
                            padding-top: 1px;
                            transition: all .2s;
                            user-select: none;
                            
                            @keyframes rotation {
                                from {
                                    transform: rotate(0deg);
                                }

                                to {
                                    transform: rotate(359deg);
                                }
                            }
                        }

                        span {
                            margin-top: 1rem;
                            color: $text-color-alt;
                        }
                    }

                    .listing {
                        display: flex;
                        align-items: center;
                        margin-top: 1rem;
                        grid-gap: 1rem;

                        .thumbnail-wrapper {
                            background: white;
                            border-radius: 3px;
                            border: 1px solid $border-color;
                            min-height: 100px;
                            min-width: 100px;
                            overflow: hidden;
                            position: relative;

                            img {
                                position: absolute;
                                max-height: 100px;
                                max-width: 100px;
                                margin: auto;
                                left: 0;
                                top: 0;
                                right: 0;
                                bottom: 0;
                            }
                        }

                        .details-wrapper {
                            width: 62%;

                            .listing-title {
                                font-size: .9rem;
                                margin-bottom: .3rem;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                transition: all .3s;
                                font-weight: bold;

                                &:hover {
                                    color: $primary-color;
                                }
                            }

                            .listing-reviews {
                                display: inline-flex;
                                align-items: center;
                                color: $divider-color;
                                font-size: .8rem;
                                grid-gap: .3rem;
                                margin-bottom: .7rem;
    
                                i.fill {
                                    color: $primary-color;
                                }
    
                                i.fad {
                                    &:before {
                                        color: $primary-color;
                                    }
                                }
    
                                span {
                                    color: $text-color-alt;
                                    font-size: .8rem;
                                }
                            }

                            .listing-price {
                                font-size: 1.2rem;
                                font-weight: bold;
                            }
                        }
                    }
                }
            }
        }
    }

    // Imported here due to having naming conflict with another component
    @import "../components/trending-categories";
                
    .popular-filters {
        color: $text-color;
        padding: 3rem 0;

        @media only screen and (max-width: 768px) {
            padding: 2rem 0;
        }

        > .container {
            .filter-wrapper {
                margin-bottom: 2rem;

                .filters {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    grid-gap: 1rem;

                    @media only screen and (max-width: 500px) {
                        display: grid;
                        grid-template-columns: repeat(2, 1fr);
                        text-align: center;
                    }

                    .filter {
                        padding: .5rem 1rem;
                        border: 1px solid $divider-color;
                        border-radius: 3px;
                        color: $text-color-alt;
                        font-size: .9rem;
                        transition: all .3s;

                        &:hover {
                            border-color: $primary-color;
                            color: $primary-color;
                        }

                        &.active {
                            border-color: $primary-color;
                            color: $primary-color;
                        }
                    }
                }
            }

            div.loading {
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin: auto;
                text-align: center;
                height: 338px;

                .banner {
                    display: flex;
                    align-items: center;
                    border: 1px solid $border-color;
                    border-radius: 3px;
                    color: $text-color-alt;
                    flex-direction: column;
                    opacity: .75;
                    padding: 1rem 2rem;

                    i {
                        font-size: 2rem;
                    }

                    div {
                        margin-top: 1rem;

                        a {
                            font-weight: bold;
                            transition: all .2s;

                            &:hover {
                                color: $primary-color;
                            }
                        }
                    }
                }

                > i {
                    animation: rotation 1s infinite linear;
                    color: $primary-color;
                    font-size: 3rem;
                    padding-top: 1px;
                    transition: all .2s;
                    user-select: none;
                    
                    @keyframes rotation {
                        from {
                            transform: rotate(0deg);
                        }

                        to {
                            transform: rotate(359deg);
                        }
                    }
                }

                span {
                    margin-top: 1rem;
                    color: $text-color-alt;
                }
            }

            .listings-wrapper {
                display: flex;
                column-gap: 1rem;
                flex-wrap: wrap;
                justify-content: center;
                row-gap: 2rem;
                transition: all .2s;

                @media only screen and (max-width: 510px) {
                    flex-direction: column;
                    row-gap: 1rem;
                }

                .break {
                    flex-basis: 100%;
                    height: 0;
                }

                .listing {
                    width: 230px;

                    @media only screen and (max-width: 510px) {
                        display: flex;
                        gap: 1rem;
                        width: 100%;
                    }

                    .thumbnail-wrapper {
                        position: relative;
                        border-radius: 3px;
                        border: 1px solid $border-color;
                        overflow: hidden;
                        height: 230px;
                        width: 230px;
                        transition: all .2s;

                        @media only screen and (max-width: 510px) {
                            height: 120px;
                            width: 120px;
                        }

                        img {
                            position: absolute;
                            max-width: 230px;
                            max-height: 230px;
                            margin: auto;
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0;

                            @media only screen and (max-width: 510px) {
                                max-height: 120px;
                                max-width: 120px;
                            }
                        }

                        &:hover {
                            border-color: $primary-color;
                        }
                    }

                    .details-wrapper {
                        text-align: left;
                        padding-top: 1rem;

                        @media only screen and (max-width: 510px) {
                            display: flex;
                            flex-direction: column;
                            padding-top: 0;
                        }

                        .title {
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 2;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            font-size: .9rem;
                            margin-bottom: .5rem;
                            transition: all .3s;
                            font-weight: bold;

                            &:hover {
                                color: $primary-color;
                            }
                        }

                        .rating {
                            display: inline-flex;
                            align-items: center;
                            color: $divider-color;
                            font-size: .8rem;
                            grid-gap: .3rem;
                            margin-bottom: .7rem;

                            i.fill {
                                color: $primary-color;
                            }

                            i.fad {
                                &:before {
                                    color: $primary-color;
                                }
                            }

                            span {
                                color: $text-color-alt;
                                font-size: .8rem;
                            }
                        }

                        .price {
                            font-size: 1.2rem;
                            font-weight: bold;
                        }
                    }
                }
            }
        }
    }

    .category-preview {
        color: $text-color;
        margin-bottom: 3rem;
        margin-top: 2rem;

        > .container {
            .section-title {
                display: flex;
                font-weight: bold;
                font-size: 1.2rem;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 1rem;
                padding-bottom: 1rem;
                border-bottom: 1px solid $border-color;

                span {
                    font-weight: normal;
                    font-size: 0.9rem;
                    display: flex;
                    align-items: center;
                    grid-gap: 0.3rem;
                    opacity: .5;
                    transition: all .3s;

                    &:hover {
                        opacity: 1;
                    }
                }
            }

            .content {
                display: grid;
                grid-template-columns: 2fr 6fr;
                grid-gap: 1rem;
                position: relative;

                @media only screen and (max-width: 460px) {
                    display: flex;
                }

                .category-feature {
                    height: 675px;
                    background: $background-light;
                    padding: 3rem 2rem;
                    border-radius: 3px;
                    min-width: 308px;
                    position: sticky;
                    align-self: flex-start;
                    top: 1rem;
                    background-repeat: no-repeat;
                        background-size: cover;

                    @media only screen and (max-width: 788px) {
                        min-width: 40vw;
                    }

                    @media only screen and (max-width: 550px) {
                        min-width: 35vw;
                        padding: 2rem 1rem;
                    }

                    @media only screen and (max-width: 460px) {
                        display: none;
                    }

                    .feature-subtitle {
                        font-size: 1.2rem;
                        margin-bottom: .5rem;
                        color: white;

                        @media only screen and (max-width: 550px) {
                            font-size: 1rem;
                        }

                        span {
                            font-weight: bold;
                            color: $primary-color;
                            text-transform: uppercase;
                        }
                    }

                    .feature-title {
                        font-size: 1.4rem;
                        margin-bottom: 1rem;
                        font-weight: bold;
                        color: white;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        overflow: hidden;
                        text-overflow: ellipsis;

                        @media only screen and (max-width: 550px) {
                            font-size: 1.2rem;
                        }
                    }

                    .feature-button {
                        display: inline-block;
                        padding: 0.5rem 1rem;
                        border: 1px solid $text-color;
                        border-radius: 3px;
                        color: $text-color;
                        font-size: 0.9rem;
                        transition: all 0.3s;
                        color: white;
                        border-color: white;

                        @media only screen and (max-width: 550px) {
                            font-size: .8rem;
                        }

                        &:hover {
                            background: $text-color;
                            color: white;
                        }
                    }
                }

                .category-listings {
                    display: flex;
                    align-items: center;
                    column-gap: 2rem;
                    flex-wrap: wrap;
                    justify-content: center;
                    min-height: 20rem;
                    position: relative;
                    row-gap: 2rem;
                    width: 100%;

                    @media only screen and (max-width: 788px) {
                        flex-direction: column;
                    }

                    @media only screen and (max-width: 460px) {
                        row-gap: 1rem;
                    }

                    div.loading {
                        display: flex;
                        bottom: 0;
                        flex-direction: column;
                        justify-content: center;
                        left: 0;
                        margin-left: auto;
                        margin-right: auto;
                        position: absolute;
                        right: 0;
                        text-align: center;
                        top: 0;
        
                        .banner {
                            display: flex;
                            align-items: center;
                            border: 1px solid $border-color;
                            border-radius: 3px;
                            color: $text-color-alt;
                            flex-direction: column;
                            opacity: .75;
                            padding: 1rem 2rem;
        
                            i {
                                font-size: 2rem;
                            }
        
                            div {
                                margin-top: 1rem;
        
                                a {
                                    font-weight: bold;
                                    transition: all .2s;
        
                                    &:hover {
                                        color: $primary-color;
                                    }
                                }
                            }
                        }
        
                        > i {
                            animation: rotation 1s infinite linear;
                            color: $primary-color;
                            font-size: 3rem;
                            padding-top: 1px;
                            transition: all .2s;
                            user-select: none;
                            
                            @keyframes rotation {
                                from {
                                    transform: rotate(0deg);
                                }
        
                                to {
                                    transform: rotate(359deg);
                                }
                            }
                        }
        
                        span {
                            margin-top: 1rem;
                            color: $text-color-alt;
                        }
                    }

                    .listing {
                        width: 200px;

                        @media only screen and (max-width: 788px) {
                            display: flex;
                            gap: 1rem;
                            width: 100%;
                        }

                        .thumbnail-wrapper {
                            position: relative;
                            border-radius: 3px;
                            border: 1px solid $border-color;
                            overflow: hidden;
                            height: 200px;
                            width: 200px;

                            @media only screen and (max-width: 700px) {
                                height: 20vw;
                                width: 20vw;
                                min-height: 100px;
                                min-width: 100px;
                            }

                            img {
                                position: absolute;
                                max-height: 200px;
                                max-width: 200px;
                                margin: auto;
                                top: 0;
                                left: 0;
                                right: 0;
                                bottom: 0;

                                @media only screen and (max-width: 700px) {
                                    max-height: 20vw;
                                    max-width: 20vw;
                                    min-width: 100px;
                                    min-height: 100px;
                                }
                            }
                        }

                        .details-wrapper {
                            text-align: left;
                            padding-top: 1rem;

                            @media only screen and (max-width: 790px) {
                                padding-top: 0;
                            }

                            .title {
                                display: -webkit-box;
                                -webkit-box-orient: vertical;
                                -webkit-line-clamp: 2;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                font-size: .9rem;
                                margin-bottom: .5rem;
                                transition: all .3s;
                                font-weight: bold;

                                &:hover {
                                    color: $primary-color;
                                }
                            }

                            .rating {
                                display: inline-flex;
                                align-items: center;
                                color: $divider-color;
                                font-size: .8rem;
                                grid-gap: .3rem;
                                margin-bottom: .7rem;
    
                                i.fill {
                                    color: $primary-color;
                                }
    
                                i.fad {
                                    &:before {
                                        color: $primary-color;
                                    }
                                }
    
                                span {
                                    color: $text-color-alt;
                                    font-size: .8rem;
                                }
                            }

                            .price {
                                font-size: 1.2rem;
                                font-weight: bold;
                            }
                        }
                    }
                }
            }
        }
    }

    .banner {
        margin-bottom: 3rem;

        > .container {
            .content {
                height: 10rem;
                background: $background-light;

                @media only screen and (max-width: 600px) {
                    height: 25vw;
                }
            }
        }
    }

    .recently-viewed {
        margin-bottom: 3rem;

        > .container {
            .section-title {
                display: flex;
                font-weight: bold;
                font-size: 1.2rem;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 1rem;
                padding-bottom: 1rem;
                border-bottom: 1px solid $border-color;
            }

            .listings {
                display: flex;
                align-items: center;
                //justify-content: space-between;
                gap: 1rem;

                @media only screen and (max-width: 950px) {
                    display: grid;
                    grid-template-columns: repeat(4, 1fr);
                }

                @media only screen and (max-width: 640px) {
                    grid-template-columns: repeat(3, 1fr);
                }

                @media only screen and (max-width: 480px) {
                    grid-template-columns: repeat(2, 1fr);
                }

                a {
                    @media only screen and (max-width: 950px) {
                        margin: 0 auto;
                    }

                    .listing {
                        position: relative;

                        &:hover {
                            .thumbnail-wrapper {
                                border-color: $primary-color;
                            }
                        }

                        .thumbnail-wrapper {
                            position: relative;
                            border-radius: 3px;
                            border: 1px solid $border-color;
                            height: 140px;
                            overflow: hidden;
                            width: 140px;
                            transition: all .3s;

                            img {
                                position: absolute;
                                max-width: 140px;
                                max-height: 140px;
                                margin: auto;
                                top: 0;
                                left: 0;
                                right: 0;
                                bottom: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}
