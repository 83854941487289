section.notifications {
    margin-bottom: 10rem;

    .page-header {
        color: $text-color;
        background: rgba($background-light, 0.5);
        text-align: center;
        font-weight: bold;
        font-size: 1.8rem;
        padding: 3rem 0;
        border-bottom: 1px solid $border-color;

        @media only screen and (max-width: 600px) {
            font-size: 1.5rem;
            padding: 1rem 0;
        }
    }

    > .container-small {
        color: $text-color;
        max-width: 960px;
        padding: 2rem 1rem;
        text-align: center;

        @media only screen and (max-width: 600px) {
            padding: 1rem 1rem 4rem 1rem;
        }

        .actions {
            display: flex;
            gap: 2rem;
            justify-content: flex-end;
            margin-bottom: 1rem;

            @media only screen and (max-width: 768px) {
                justify-content: center;
            }

            button {
                display: flex;
                align-items: center;
                background: none;
                color: $text-color-alt;
                gap: .5rem;
                line-height: 1;
                transition: all .2s;

                &:hover {
                    color: $text-color;
                }
            }
        }

        .notification-wrapper {
            display: flex;
            flex-direction: column;
            gap: 1rem;

            .empty {
                color: $text-color-alt;
                border: 1px solid $border-color;
                border-radius: 3px;
                padding: 1rem 2rem;
                opacity: .75;

                i {
                    font-size: 2rem;
                    margin-bottom: 1rem;
                }
            }

            .notification {
                display: flex;
                position: relative;
                align-items: center;
                border-radius: 6px;
                border: 1px solid $border-color;
                gap: 1rem;
                padding-right: 1rem;

                .icon {
                    display: flex;
                    align-items: center;
                    background: $border-color;
                    border-radius: 6px 0 0 6px;
                    color: white;
                    justify-content: center;
                    min-height: 80px;
                    min-width: 80px;

                    @media only screen and (max-width: 768px) {
                        min-height: 160px;
                    }
                    
                    &.green {
                        background: $positive-color;
                    }

                    &.amber {
                        background: $warning-color;
                    }

                    &.red {
                        background: $negative-color;
                    }

                    i {
                        display: flex;
                        align-items: center;
                        font-size: 2rem;
                        height: 32px;
                        justify-content: center;
                        width: 32px;
                    }
                }

                .notif-content {
                    display: flex;
                    gap: 1rem;
                    justify-content: space-between;
                    width: 100%;

                    @media only screen and (max-width: 768px) {
                        flex-direction: column;
                    }

                    .text {
                        display: flex;
                        align-items: flex-start;
                        flex-direction: column;
                        gap: .3rem;
    
                        .title {
                            color: $text-color;
                            font-size: 1.2rem;
                            font-weight: bold;
                        }
    
                        .subtitle {
                            color: $text-color;
                            text-align: left;
    
                            a {
                                font-weight: bold;
                                color: $primary-color;
                            }
                        }
    
                        .date {
                            color: $text-color-alt;
                            font-size: .8rem;
                        }
                    }
    
                    .action {
                        display: flex;
                        align-items: center;
                        gap: 1rem;
                        right: 1rem;
                        
                        button {
                            display: flex;
                            align-items: center;
                            background: none;
                            border-radius: 3px;
                            border: 1px solid $text-color-alt;
                            color: $text-color-alt;
                            font-size: .9rem;
                            gap: .3rem;
                            justify-content: center;
                            padding: .3rem .6rem;
                            transition: all .2s;
                            white-space: nowrap;

                            &:hover {
                                color: $text-color;
                                border-color: $text-color;
                            }
                        }
    
                        i {
                            color: $text-color-alt;
                            cursor: pointer;
                            transition: all .2s;
    
                            &:hover {
                                color: $text-color;
                            }
                        }
                    }
                }
            }
        }
    }
}
