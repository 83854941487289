section.listing {
    padding: 2rem 0;

    @media only screen and (max-width: 600px) {
        padding: 1rem 0;
    }

    > .container {
        color: $text-color;

        .listing-wrapper {
            display: grid;
            grid-template-columns: 10fr 3fr;
            grid-gap: 1rem;
            min-height: 30rem;

            @media only screen and (max-width: 768px) {
                display: flex;
                flex-direction: column;
                grid-gap: 2rem;
            }

            .content-wrapper {
                display: flex;
                grid-gap: 1rem;
                padding-bottom: 5rem;
                flex-direction: column;

                @media only screen and (max-width: 768px) {
                    padding-bottom: 2rem;
                    border-bottom: 1px solid $border-color;
                }

                .top-wrapper {
                    display: flex;
                    grid-gap: 1rem;

                    @media only screen and (max-width: 600px) {
                        flex-direction: column;
                    }

                    .mobile-title {
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        font-size: 1.2rem;
                        font-weight: bold;
                        transition: all .3s;
                    }

                    .thumbnail-wrapper {
                        box-sizing: content-box;
                        user-select: none;

                        @media only screen and (max-width: 768px) {
                            width: 50vw;
                        }

                        @media only screen and (max-width: 600px) {
                            width: 100%;
                        }

                        .thumbnail {
                            position: relative;
                            height: 450px;
                            width: 450px;
                            border-radius: 3px;
                            border: 1px solid #eeeeee;
                            overflow: hidden;

                            @media only screen and (max-width: 768px) {
                                height: 50vw;
                                width: 50vw;
                            }

                            @media only screen and (max-width: 600px) {
                                height: calc(100vw - 2rem);
                                width: 100%;
                            }

                            img, iframe {
                                position: absolute;
                                max-height: 420px;
                                max-width: 420px;
                                margin: auto;
                                left: 0;
                                top: 0;
                                right: 0;
                                bottom: 0;

                                @media only screen and (max-width: 768px) {
                                    max-height: 48vw;
                                    max-width: 48vw;
                                }

                                @media only screen and (max-width: 600px) {
                                    max-height: calc(98vw - 2rem);
                                    max-width: calc(98vw - 2rem);
                                }
                            }

                            iframe {
                                position: absolute;
                                height: 420px;
                                width: 420px;
                                margin: auto;
                                left: 0;
                                top: 0;
                                right: 0;
                                bottom: 0;
                            }

                            span {
                                position: absolute;
                                bottom: 1rem;
                                right: 1rem;
                                background: $background-light;
                                padding: 0.4rem 0.7rem;
                                font-size: 0.8rem;
                                border-radius: 3px;
                            }
                        }

                        .previews-wrapper {
                            height: 106.25px;
                            margin-top: .5rem;
                            overflow: hidden;
                            position: relative;

                            @media only screen and (max-width: 600px) {
                                height: 22.9vw;
                            }

                            @media only screen and (max-width: 420px) {
                                height: 22.3vw;
                            }

                            @media only screen and (max-width: 360px) {
                                height: 22vw;
                            }

                            .previews {
                                display: flex;
                                //border-radius: 0 6px 6px 0;
                                width: 450px;
                                overflow: hidden;
                                position: relative;
                                scroll-behavior: smooth;
                                scroll-snap-type: x mandatory;
                                height: 106.25px;
                                flex-direction: row;
                                grid-gap: .5rem;

                                @media only screen and (max-width: 768px) {
                                    height: 11.74vw;
                                    width: 50vw;
                                    grid-gap: 1vw;
                                }
                                @media only screen and (max-width: 600px) {
                                    height: 22.9vw;
                                    width: 100%;
                                }

                                @media only screen and (max-width: 420px) {
                                    height: 22.3vw;
                                }

                                @media only screen and (max-width: 360px) {
                                    height: 22vw;
                                }

                                .image {
                                    display: inline-block;
                                    background: white;
                                    border: 1px solid $border-color;
                                    border-radius: 3px;
                                    min-width: 106.25px;
                                    overflow: hidden;
                                    position: relative;
                                    transition: all .3s;

                                    @media only screen and (max-width: 768px) {
                                        min-width: 11.74vw;
                                    }

                                    @media only screen and (max-width: 600px) {
                                        min-width: 22.9vw;
                                    }

                                    @media only screen and (max-width: 420px) {
                                        min-width: 22.3vw;
                                    }

                                    @media only screen and (max-width: 360px) {
                                        min-width: 22vw;
                                    }

                                    &:hover {
                                        border-color: $primary-color;
                                    }

                                    &.active {
                                        border-color: $primary-color;
                                    }

                                    i {
                                        display: flex;
                                        align-items: center;
                                        color: white;
                                        font-size: 2rem;
                                        height: 100%;
                                        justify-content: center;
                                        position: absolute;
                                        width: 100%;
                                        z-index: 10;
                                    }

                                    img {
                                        position: absolute;
                                        max-width: 100%;
                                        max-height: 106.25px;
                                        margin: auto;
                                        left: 0;
                                        top: 0;
                                        right: 0;
                                        bottom: 0;

                                        @media only screen and (max-width: 768px) {
                                            max-height: 11.74vw;
                                        }

                                        @media only screen and (max-width: 600px) {
                                            max-height: 22.9vw;
                                        }

                                        @media only screen and (max-width: 420px) {
                                            max-height: 22.3vw;
                                        }

                                        @media only screen and (max-width: 360px) {
                                            max-height: 22vw;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .summary-wrapper {
                        color: $text-color;
                        width: 100%;

                        > .inactive {
                            display: flex;
                            align-items: center;
                            border-radius: 3px;
                            border: 1px solid $warning-color;
                            color: $warning-color;
                            justify-content: center;
                            margin-bottom: 1rem;
                            padding: .5rem 1rem;

                            i {
                                margin-right: .5rem;
                            }
                        }

                        > .suspended {
                            display: flex;
                            align-items: center;
                            border-radius: 3px;
                            border: 1px solid $negative-color;
                            color: $negative-color;
                            justify-content: center;
                            margin-bottom: 1rem;
                            padding: .5rem 1rem;

                            i {
                                margin-right: .5rem;
                            }
                        }

                        .title {
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 2;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            font-size: 1.2rem;
                            font-weight: bold;
                            margin-bottom: .6rem;
                            transition: all .3s;
                        }

                        .price {
                            border-top: 1px solid $border-color;
                            font-size: 1.8rem;
                            font-weight: bold;
                            margin-bottom: 1rem;
                            padding-top: 1rem;

                            .postage {
                                display: inline-block;
                                color: $text-color;
                                margin-left: .5rem;
                                font-size: .8rem;
                                font-weight: normal;

                                span {
                                    font-weight: bold;

                                    &.free {
                                        color: $positive-color;
                                    }
                                }
                            }
                        }

                        .stock {
                            margin-bottom: 1rem;

                            .in-stock {
                                color: $positive-color;
                            }

                            .out-stock {
                                color: $negative-color;
                            }

                            i {
                                margin-right: .3rem;
                                font-weight: bold;
                            }
                        }

                        .reviews {
                            display: inline-flex;
                            align-items: center;
                            color: $divider-color;
                            grid-gap: .3rem;
                            margin-bottom: 1rem;

                            i.fill {
                                color: $primary-color;
                            }

                            i.fad {
                                &:before {
                                    color: $primary-color;
                                }
                            }

                            span {
                                color: $text-color-alt;
                                font-size: .8rem;
                            }
                        }

                        .condition {
                            font-size: .8rem;
                            margin-bottom: 1rem;
                        }

                        .options {
                            margin-bottom: 1rem;

                            .attribute {
                                display: flex;
                                margin-bottom: 1rem;
                                gap: .5rem;

                                span {
                                    display: inline-flex;
                                    align-items: flex-start;
                                    font-size: .8rem;
                                    padding: .3rem 0;
                                    line-height: 18px;
                                }

                                .radio-wrapper {
                                    display: inline-flex;
                                    flex-wrap: wrap;
                                    gap: .5rem;

                                    > div {
                                        display: inline-block;

                                        input {
                                            display: none;

                                            &:checked {
                                                + label {
                                                    color: $primary-color !important;
                                                    border-color: $primary-color !important;
                                                }
                                            }

                                            &:disabled {
                                                + label {
                                                    color: $border-color !important;
                                                    border-color: $border-color !important;
                                                }
                                            }
                                        }

                                        label {
                                            display: inline-block;
                                            border: 1px solid $divider-color;
                                            color: $text-color-alt;
                                            font-size: .8rem;
                                            line-height: 1rem;
                                            padding: .3rem .5rem;
                                            transition: all .3s;
                                            font-weight: bold;
                                            border-radius: 3px;

                                            &:hover {
                                                border-color: $primary-color;
                                                color: $primary-color;
                                            }
                                        }
                                    }
                                }
                            }

                            .no-options {
                                display: block;
                                //font-style: italic;
                                margin-bottom: 1rem;
                                font-size: .8rem;
                            }

                            .reset {
                                display: inline-block;
                                font-size: .8rem;
                                opacity: .5;
                                transition: all .3s;

                                &:hover {
                                    opacity: 1;
                                }

                                i {
                                    font-size: .8rem;
                                    margin-right: .3rem;
                                }
                            }
                        }

                        .checkout {
                            display: block;
                            border-top: 1px solid $border-color;
                            padding-top: 1rem;

                            .row {
                                display: flex;
                                grid-column-end: 3;
                                grid-column-gap: .5rem;
                                grid-column-start: 1;
                                width: 100%;
                                margin-bottom: 1rem;

                                button {
                                    display: block;
                                    background: #805fd3;
                                    border-radius: 3px;
                                    color: white;
                                    font-weight: bold;
                                    margin: 0 auto;
                                    padding: 1rem 2rem;
                                    text-transform: uppercase;
                                    transition: all 0.2s;
                                    -webkit-user-select: none;
                                    user-select: none;

                                    &.disabled {
                                        background: $divider-color !important;
                                    }

                                    &:hover {
                                        opacity: .9;
                                    }

                                    &.purchase {
                                        width: 100%;
                                    }

                                    &.wishlist, &.cart {
                                        padding: 1rem !important;
                                        width: 100%;
                                        border: 1px solid $text-color;
                                        color: $text-color;
                                        background: none;

                                        @media only screen and (max-width: 360px) {
                                            padding: 1rem .5rem !important;
                                        }

                                        &.disabled {
                                            background: unset !important;
                                            border-color: $divider-color;
                                            color: $divider-color;

                                            &:hover {
                                                background: unset !important;
                                                border-color: $divider-color;
                                                color: $divider-color;
                                                opacity: .9;
                                            }
                                        }

                                        &.wishlist {
                                            width: 70%;
                                        }

                                        &:hover {
                                            background: $primary-color;
                                            border-color: $primary-color;
                                            color: white;
                                            opacity: 1;
                                        }
                                    }

                                    &.remove-cart {
                                        display: flex;
                                        align-items: center;
                                        justify-content: space-between;
                                        cursor: default;

                                        &:hover {
                                            background: none;
                                            border-color: $text-color;
                                            color: $text-color;
                                        }

                                        i {
                                            margin-right: 0;
                                            transition: color .3s;
                                            cursor: pointer;

                                            &.fa-minus-circle:hover {
                                                color: $negative-color;
                                            }

                                            &.fa-plus-circle:hover {
                                                color: $positive-color;
                                            }
                                        }
                                    }

                                    &.wished {
                                        &:hover {
                                            background: $negative-color;
                                            border-color: $negative-color;
                                        }
                                    }

                                    i {
                                        margin-right: .3rem;
                                    }
                                }
                            }
                        }

                        .currencies {
                            display: flex;
                            margin-bottom: 1rem;
                            align-items: center;

                            span {
                                display: inline-block;
                                font-size: .8rem;
                                color: $text-color-alt;
                                margin-right: .5rem;
                            }

                            img {
                                display: inline-block;
                                border-radius: 100%;
                                height: 28px;
                                margin-right: .5rem;
                                width: 28px;
                            }

                            div.overflow {
                                display: none;
                                height: 28px;
                                width: 28px;
                                background: $primary-color;
                                border-radius: 100px;
                                position: relative;
                                user-select: none;
                                line-height: 28px;

                                &:last-of-type {
                                    display: inline-block;
                                }

                                div.number {
                                    position: absolute;
                                    text-align: center;
                                    left: 0;
                                    right: 0;
                                    color: white;
                                    font-size: .8rem;
                                    font-weight: bold;
                                }
                            }
                        }

                        .cashback {
                            display: flex;
                            align-items: center;
                            margin-top: 1rem;

                            span {
                                display: inline-block;
                                font-size: .8rem;
                                color: $text-color-alt;
                                margin-right: .5rem;
                            }

                            .amount {
                                background: $primary-color;
                                color: white;
                                padding: .2rem .4rem;
                                font-weight: bold;
                                line-height: 1.2;
                                border-radius: 3px;
                            }
                        }
                    }
                }

                .bottom-wrapper {
                    .description-tabs {
                        border-bottom: 1px solid $border-color;
                        margin-bottom: 1rem;

                        .tablink {
                            background: none;
                            font-weight: bold;
                            font-size: 1.2rem;
                            padding: 1rem 0;
                            margin-right: 2rem;
                            color: $text-color-alt;
                            border-bottom: 2px solid rgba(0, 0, 0, 0,);
                            transition: all .3s;

                            @media only screen and (max-width: 600px) {
                                &:last-of-type {
                                    margin-right: 0;
                                }
                            }

                            @media only screen and (max-width: 420px) {
                                margin-right: 1rem;
                            }

                            &:hover {
                                color: $text-color;
                                border-bottom: 2px solid $primary-color;
                            }

                            &.active {
                                color: $text-color;
                                border-bottom: 2px solid $primary-color;
                            }

                            @keyframes fadeEffect {
                                from { opacity: 0 }
                                to { opacity: 1 }
                            }
                        }
                    }

                    .tab-content {
                        color: $text-color;
                        line-height: 1.5;

                        .shipping {
                            .content {
                                display: flex;
                                align-items: center;
                                flex-direction: row;
                                gap: 1rem;

                                i {
                                    color: $primary-color;
                                }

                                .input-group {
                                    margin-bottom: 0;

                                    select[name="buyer-location"] {
                                        option:disabled {
                                            color: rgba($text-color, 0.25);
                                        }
                                    }
                                }
                            }

                            .postage {
                                line-height: 1.5;
                                margin-top: .5rem;
                                padding: 0.5rem 0;

                                &.free {
                                    span {
                                        color: $positive-color;
                                    }
                                }

                                &.unavailable {
                                    color: $negative-color;
                                    font-weight: bold;
                                }

                                span {
                                    color: $primary-color;
                                    font-weight: bold;
                                }
                            }
                        }

                        .reviews {
                            div.review {
                                display: flex;
                                border-bottom: 1px solid $border-color;
                                flex-direction: column;
                                padding-bottom: 1rem;
                                margin-bottom: 1rem;
                                gap: .3rem;

                                &:last-of-type {
                                    border-bottom: none;
                                    padding-bottom: 0;
                                    margin-bottom: 0;
                                }

                                span {
                                    display: flex;
                                    justify-content: space-between;
                                    font-size: .7rem;

                                    div.verified {
                                        color: $positive-color;
                                    }
                                }

                                div > i.fill {
                                    color: $primary-color;
                                }
    
                                div > i.fad {
                                    &:before {
                                        color: $primary-color;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .sidebar-wrapper {
                position: sticky;
                align-self: flex-start;
                top: 1rem;

                @media only screen and (max-width: 768px) {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    width: 100%;
                    gap: 1rem;
                }

                @media only screen and (max-width: 600px) {
                    display: flex;
                    flex-direction: column;
                    gap: 0;
                }

                .listing-features {
                    border: 1px solid $border-color;
                    border-radius: 3px;
                    padding: 1rem;
                    margin-bottom: 1rem;

                    .feature {
                        display: flex;
                        align-items: center;
                        grid-gap: 1rem;
                        padding-bottom: 1rem;
                        margin-bottom: 1rem;
                        border-bottom: 1px solid $border-color;

                        &:last-of-type {
                            padding-bottom: 0;
                            margin-bottom: 0;
                            border-bottom: none;
                        }

                        > i {
                            display: flex;
                            color: $primary-color;
                            font-size: 1.5rem;
                            justify-content: center;
                            min-width: 30px;

                            &.warning {
                                color: $warning-color;
                            }
                        }

                        p {
                            font-size: .9rem;

                            i {
                                font-size: .9rem;
                            }
                        }
                    }
                }

                .item-share {
                    cursor: pointer;
                }

                .item-location,
                .item-share {
                    display: flex;
                    align-items: center;
                    border-radius: 3px;
                    border: 1px solid $border-color;
                    grid-gap: 1rem;
                    margin-bottom: 1rem;
                    padding: 1rem;

                    i {
                        display: flex;
                        color: $primary-color;
                        font-size: 1.5rem;
                        justify-content: center;
                        min-width: 30px;

                        &.warning {
                            color: $warning-color;
                        }
                    }

                    p {
                        font-size: .9rem;

                        i {
                            font-size: .9rem;
                        }
                    }
                }

                .seller-profile {
                    display: flex;
                    align-items: center;
                    border-radius: 3px;
                    border: 1px solid $border-color;
                    grid-gap: .5rem;
                    margin-bottom: 1rem;
                    padding: 1rem;

                    .avatar {
                        min-height: 40px;
                        min-width: 40px;
                        border-radius: 100%;
                        border: 1px solid $border-color;
                        overflow: hidden;
                        position: relative;

                        img {
                            position: absolute;
                            max-height: 100%;
                            max-width: 100%;
                            margin: auto;
                            left: 0;
                            top: 0;
                            right: 0;
                            bottom: 0;
                        }
                    }

                    .wrapper {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        width: 100%;

                        .text {
                            .username {
                                display: flex;
                                align-items: center;
                                grid-gap: .3rem;
                                font-size: .9rem;
                                font-weight: bold;
                                transition: all .3s;
                                margin-bottom: .1rem;

                                &:hover {
                                    color: $primary-color;
                                }

                                i {
                                    display: none;
                                    color: #20cca5;
                                    font-size: .8rem;

                                    &.active {
                                        display: inline-block;
                                    }
                                }
                            }

                            .joined {
                                font-size: .8rem;
                            }
                        }

                        i {
                            font-size: 1.5rem;
                            transition: all .3s;

                            &:hover {
                                color: $primary-color;
                            }
                        }
                    }

                    .rating {
                        display: inline-flex;
                        align-items: center;
                        color: $primary-color;
                        font-size: .8rem;
                        grid-gap: .3rem;
                    }
                }

                .other-items {
                    display: flex;
                    align-items: center;
                    border-radius: 3px;
                    border: 1px solid $border-color;
                    flex-direction: column;
                    margin-bottom: 1rem;
                    padding: 1rem;

                    .heading {
                        display: flex;
                        align-items: center;
                        border-bottom: 1px solid #eeeeee;
                        font-size: 1.2rem;
                        font-weight: bold;
                        justify-content: space-between;
                        padding-bottom: 1rem;
                        width: 100%;
                    }

                    .listings-wrapper {
                        max-width: 300px;

                        @media only screen and (max-width: 600px) {
                            max-width: unset;
                            width: 100%;
                        }

                        .listing {
                            display: flex;
                            align-items: center;
                            margin-top: 1rem;
                            grid-gap: 1rem;
                            width: 100%;

                            .thumbnail-wrapper {
                                background: white;
                                border-radius: 3px;
                                border: 1px solid $border-color;
                                min-height: 100px;
                                min-width: 100px;
                                overflow: hidden;
                                position: relative;

                                img {
                                    position: absolute;
                                    max-height: 100px;
                                    max-width: 100px;
                                    margin: auto;
                                    left: 0;
                                    top: 0;
                                    right: 0;
                                    bottom: 0;
                                }
                            }

                            .details-wrapper {
                                min-width: 180px;
                                width: 62%;

                                .listing-title {
                                    font-size: .9rem;
                                    margin-bottom: .3rem;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                    transition: all .3s;
                                    font-weight: bold;

                                    &:hover {
                                        color: $primary-color;
                                    }
                                }

                                .listing-reviews {
                                    display: inline-flex;
                                    align-items: center;
                                    color: $divider-color;
                                    font-size: .8rem;
                                    grid-gap: .3rem;
                                    margin-bottom: .6rem;
        
                                    i.fill {
                                        color: $primary-color;
                                    }
        
                                    i.fad {
                                        &:before {
                                            color: $primary-color;
                                        }
                                    }
        
                                    span {
                                        color: $text-color-alt;
                                        font-size: .8rem;
                                    }
                                }

                                .listing-price {
                                    font-size: 1.2rem;
                                    font-weight: bold;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
