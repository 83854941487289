section.user-agreement {
    padding: 4rem 0;
    color: $text-color-alt;

    div.page-title {
        display: block;
        font-size: 1.5rem;
        margin-bottom: 2rem;
        text-transform: uppercase;
    }

    div.intro {
        display: block;
        margin-bottom: 2rem;

        div.last-updated {
            display: block;
            margin-bottom: 1rem;
        }

        div.pre {
            display: block;
            margin-bottom: 1rem;

            a {
                color: $primary-color;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    div.section {
        display: block;
        margin-bottom: 2rem;

        div.title {
            display: block;
            margin-bottom: 1rem;
            font-weight: bold;
        }

        div.content {
            display: block;

            ul {
                display: block;
                list-style: unset;

                li {
                    margin-left: 2rem;
                }
            }

            a {
                color: $primary-color;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}
