section.wallet, section.nfts {
    overflow-x: auto;

    table {
        overflow-x: auto;
        table-layout: fixed;
        width: 100%;

        @media only screen and (max-width: 600px) {
            overflow-x: unset;
        }
        
        tr {
            display: flex;
            align-items: center;
            border-bottom: 1px solid $border-color;
            border-radius: 3px;
            flex-direction: row;
            grid-gap: .5rem;
            min-height: 3rem;
            padding: .5rem 1rem;

            @media only screen and (max-width: 600px) {
                padding-right: 2rem;
            }

            &:hover {
                background: $background-light;
            }

            &.expand {
                display: flex;
                background: #fbfbfb;
                gap: 1rem;
                justify-content: space-around;
                padding: 1rem;

                .expand-gr {
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    gap: .3rem;
                    justify-content: center;

                    .subtitle {
                        font-size: .8rem;
                    }
                }
            }

            &.collapse {
                display: none;
            }

            &.table-header {
                display: flex;
                background: $background-light;
                border-radius: 3px 3px 0px 0px;
                color: $text-color-alt;
                padding-left: 1rem;

                td {
                    font-size: .9rem;
                    font-weight: normal;
                    text-align: left;
                    width: 100%;
                }

                > i {
                    visibility: hidden;
                }
            }

            &.empty {
                color: $text-color-alt;
                font-size: .9rem;
                justify-content: center;

                &:hover {
                    background: unset;
                }

                td {
                    justify-content: center;
                }
            }

            input[type="checkbox"] {
                appearance: checkbox;
                flex-shrink: 0;
                font-size: 14px;
                height: 14px;
                margin-right: 1rem;
                min-height: 14px;
                min-width: 14px;
                width: 14px;
            }

            td {
                display: inline-flex;
                align-items: center;
                font-size: .9rem;
                grid-gap: .5rem;
                text-overflow: ellipsis;
                white-space: nowrap;
                width: 100%;

                &.long {
                    width: 150%;

                    @media only screen and (max-width: 480px) {
                        width: 200%;
                    }
                }

                &.short {
                    width: 60%;

                    @media only screen and (max-width: 600px) {
                        width: 75%;
                    }

                    @media only screen and (max-width: 420px) {
                        width: 100%;
                    }
                }

                &.justify-center {
                    @media only screen and (max-width: 380px) {
                        justify-content: center;
                    }
                }

                &.justify-end {
                    @media only screen and (max-width: 600px) {
                        width: 50%;
                    }

                    @media only screen and (max-width: 380px) {
                        justify-content: flex-end;
                    }
                }

                &.green {
                    color: $positive-color;
                }

                &.amber {
                    color: $warning-color;
                }

                &.bold {
                    font-weight: bold;
                }

                &.asset {
                    position: relative;

                    img {
                        border-radius: 50%;
                        height: 28px;
                        width: 28px;
                    }

                    div {
                        transition: all .3s;

                        &:hover {
                            //color: $primary-color;
                            text-decoration: underline;
                        }
                    }

                    span {
                        border-radius: 6px;
                        font-size: 0.7rem;
                        padding: 0 0.3rem;
                        right: 0.5rem;
                        
                        &.farm {
                            background: $positive-color;
                            border: 1px solid $positive-color;
                            color: white;
                        }

                        &.bp {
                            background: #6E37A0;
                            border: 1px solid #6E37A0;
                            color: white;
                        }
                    }
                }

                img {
                    height: 18px;
                    width: 18px;
                }
            }

            button {
                display: flex;
                align-items: center;
                background: $primary-color;
                border-radius: 3px;
                color: white;
                font-size: .9rem;
                gap: .3rem;
                justify-content: center;
                padding: .3rem .9rem;
                transition: all .3s;
                width: 100%;

                &:hover {
                    opacity: .9;
                }

                &:disabled {
                    background: $divider-color;
                }

                &.green {
                    background: $positive-color;
                }

                &.purple {
                    background: #6E37A0;
                }

                i {
                    color: white;
                }
            }
        }
    }
}
