section.account {
    .page-header {
        color: $text-color;
        background: rgba($background-light, 0.5);
        text-align: center;
        font-weight: bold;
        font-size: 1.8rem;
        padding: 3rem 0;
        border-bottom: 1px solid $border-color;

        @media only screen and (max-width: 600px) {
            font-size: 1.5rem;
            padding: 1rem 0;
          }
    }

    > .container {
        padding: 2rem 1rem;
        color: $text-color;

        @media only screen and (max-width: 600px) {
            padding: 1rem 1rem 4rem 1rem;
        }

        .account-wrapper {
            display: flex;
            grid-gap: 2rem;
            margin-top: 2rem;

            @media only screen and (max-width: 600px) {
                display: block;
                margin-top: 2rem;
            }

            .sidebar-wrapper {
                display: flex;
                align-items: flex-start;
                align-self: flex-start;
                flex-direction: column;
                grid-gap: 1rem;
                position: sticky;
                top: 1rem;
                white-space: nowrap;
                width: 30%;

                > a {
                    background: none;
                    border-bottom: 1px solid $border-color;
                    color: $text-color-alt;
                    font-weight: bold;
                    padding-bottom: .5rem;
                    text-align: left;
                    transition: all .3s;
                    width: 100%;

                    &:hover {
                        color: $primary-color;
                    }

                    &.active {
                        color: $primary-color;
                    }
                }
            }

            .tile-wrapper {
                display: flex;
                flex-direction: column;
                grid-gap: 2rem;
                width: 100%;

                .account-overview {
                    .important {
                        display: none;
                        padding: .6rem 1.2rem;;
                        border: 1px solid $negative-color;
                        border-radius: 3px;
                        width: 100%;
                        margin-bottom: 2rem;

                        &.active {
                            display: block;
                        }

                        i {
                            margin-right: .3rem;
                            color: $negative-color;
                        }
                    }

                    p {
                        span {
                            font-weight: bold;
                        }
                    }

                    div.notifications {
                        margin-top: 1rem;

                        span {
                            font-weight: bold;
                            transition: all .3s;

                            &:hover {
                                color: $primary-color;
                            }
                        }
                    }
                }

                .tiles {
                    display: grid;
                    grid-template-columns: repeat(3, 1fr);
                    grid-gap: 1rem;

                    @media only screen and (max-width: 768px) {
                        grid-template-columns: repeat(2, 1fr);
                    }

                    @media only screen and (max-width: 420px) {
                        grid-template-columns: repeat(1, 1fr);
                    }

                    .tile {
                        display: flex;
                        align-items: center;
                        border-radius: 3px;
                        border: 1px solid $border-color;
                        color: $text-color;
                        flex-direction: column;
                        height: 100%;
                        justify-content: center;
                        padding: 2rem;
                        position: relative;
                        text-align: center;
                        transition: all .3s;

                        &:hover {
                            border-color: $primary-color;
                        }

                        &.soon {
                            opacity: .5;
                            pointer-events: none;
                        }

                        i {
                            font-size: 2rem;
                            margin-bottom: 1rem;
                        }

                        span {
                            font-weight: bold;
                            margin-bottom: .5rem;
                        }

                        p {
                            font-size: .8rem;
                        }

                        .soon {
                            position: absolute;
                            background: $primary-color;
                            border-radius: 6px;
                            border: 1px solid $primary-color;
                            color: white;
                            font-size: .7rem;
                            padding: .3rem;
                            right: .5rem;
                            top: .5rem;
                            opacity: .5;
                        }
                    }
                }
            }

            .content-wrapper {
                width: 100%;

                section {
                    margin-bottom: 4rem;

                    .content-title-area {
                        display: flex;
                        justify-content: space-between;
                        margin-bottom: 2rem;
                        position: relative;

                        @media only screen and (max-width: 600px) {
                            flex-direction: column;
                            gap: 1rem;
                        }  

                        .content-title {
                            height: 30px;
                        }

                        input {
                            padding: 0.5rem;
                            height: 30px;
                            width: 200px;
                            border-radius: 3px;
                            border: solid 1px $divider-color;
                            font-size: 0.9rem;

                            &:focus,
                            &:hover {
                                border-color: $text-color-alt;
                            }

                            @media only screen and (max-width: 600px) {
                                width: 100%;
                            }       
                        }
                        
                        .content-title {
                            display: flex;
                            align-items: center;
                            font-size: 1.2rem;
                            font-weight: bold;
                            grid-gap: .5rem;
                            // margin-bottom: 2rem;
                            position: relative;
        
                            &.divider {
                                //border-top: 1px solid $border-color;
                                //margin-top: 2rem;
                            }
        
                            i {
                                font-size: 1.5rem;
                            }
        
                            span {
                                color: $primary-color;
                                font-size: .7rem;
                                font-weight: bold;
                            }
                        }

                        span.filters {
                            display: flex;
                            align-items: center;
                            gap: 1rem;
                            position: absolute;
                            right: 0;
    
                            button {
                                background: none;
                                border-radius: 3px;
                                border: 1px solid $text-color-alt;
                                padding: .5rem 1rem;
                                color: $text-color-alt;
                                transition: all .3s;
    
                                &:hover {
                                    background: #805fd310;
                                    border-color: $primary-color;
                                    color: $primary-color;
                                }
    
                                &.active {
                                    border-color: $primary-color;
                                    color: $primary-color;
                                };
                            }
                        }
                    }
                }
            }
        }
    }
}
