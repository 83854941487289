div.wallet-selector {
    background-color: rgba(0, 0, 0, 0);
    padding: 1rem;
    transition-delay: .24s;
    transition: all 0s;
    width: 100vw !important;

    div.wallet-selector-inner {
        border-radius: 6px !important;
        box-shadow: none !important;
        margin: 1rem;
        max-width: 392px;
        width: 100%;
        padding-top: 2rem !important;

        div.wallet-selector-connect {
            padding: 0 2rem 2rem 2rem;
        }

        div.wallet-selector-connect-header {
            img.wallet-selector-logo {
                border: none !important;
                margin-bottom: 0 !important;
            }

            span.wallet-selector-title {
                color: rgb(17, 25, 40) !important;
                font-family: "Roboto", sans-serif !important;
                font-size: 1.2rem !important;
            }

            span.wallet-selector-subtitle {
                display: none !important;
            }
        }

        div.wallet-selector-connect-body {
            margin-top: 2rem !important;

            ul.wallet-selector-wallet-list {
                display: flex;
                flex-direction: column;
                gap: 1rem;

                li {
                    display: flex;
                    align-items: center;
                    background: rgba(243, 244, 246, 1) !important;
                    border-radius: 3px !important;
                    border: none !important;
                    color: rgb(17, 25, 40) !important;
                    font-size: 20px;
                    font-weight: 500;
                    gap: 0.5rem;
                    justify-content: center;
                    line-height: 1.5rem;
                    margin-top: 0 !important;
                    padding: .75rem 1.5rem;
                    transition: all .2s;

                    &:hover {
                        background-color: rgb(209, 213, 219) !important;
                    }

                    @media only screen and (max-width: 1366px) {
                        &:has(div.wallet-selector-logo--webauth) {
                            display: none;
                        }
                    }

                    .wallet-selector-logo {
                        display: flex;
                        align-items: center;
                        background: none !important;
                        font-family: "Font Awesome 5 Pro";
                        font-size: 20px;
                        height: 20px;
                        justify-content: center;
                        width: 20px;

                        &.wallet-selector-logo--proton {
                            &:before {
                                content: "\f10b";
                                font-weight: 900;
                            }

                            &+.wallet-selector-wallet-name {
                                
                                //visibility: hidden;

                                // &:before {
                                //     content: "WebAuth Wallet";
                                //     visibility: visible;
                                // }
                            }
                        }

                        &.wallet-selector-logo--webauth {
                            &:before {
                                content: "\f37e";
                                font-weight: 900;
                            }
                        }

                        &.wallet-selector-logo--anchor {
                            &:before {
                                content: "\f108";
                                font-weight: 900;
                            }
                        }
                    }

                    span.wallet-selector-wallet-name {
                        color: rgb(17, 25, 40) !important;
                        font-family: "Roboto", sans-serif !important;
                        margin-left: 0 !important;
                    }

                    .wallet-selector-right-arrow {
                        display: none !important;
                    }
                }
            }

            .wallet-selector-tos-agreement {
                display: none;
            }
        }
    }
}

div.proton-link {
    background: rgba(0, 0, 0, 0.25) !important;
    width: 100vw !important;

    div.proton-link-inner {
        background: white !important;
        border-radius: 6px !important;
        box-shadow: none !important;
        width: 392px;

        div.proton-link-nav {
            background: rgb(243, 244, 246) !important;
            border-radius: 6px 6px 0 0 !important;

            .proton-link-back {
                background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'><g fill='none' fill-rule='evenodd'><g><g><g><path d='M0 0L24 0 24 24 0 24z' transform='translate(-348 -152) translate(332 136) translate(16 16)'/><path fill='rgba(17, 25, 40, 1)' fill-rule='nonzero' d='M16.41 5.791L14.619 4 7 11.619 14.619 19.239 16.41 17.448 10.594 11.619z' transform='translate(-348 -152) translate(332 136) translate(16 16)'/></g></g></g></g></svg>%0A");
            }

            .proton-link-header {
                color: rgb(17, 25, 40) !important;
                font-family: "Roboto", sans-serif !important;
            }

            .proton-link-close {
                background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.66 10.987L6 7.327l-3.66 3.66A1.035 1.035 0 11.876 9.523l3.66-3.66-3.66-3.66A1.035 1.035 0 012.34.737L6 4.398 9.66.739a1.035 1.035 0 111.464 1.464l-3.66 3.66 3.66 3.661a1.035 1.035 0 11-1.464 1.464z' fill='rgba(17, 25, 40, 1)' fill-rule='nonzero'/%3E%3C/svg%3E");
            }
        }

        div.proton-link-request {
            padding: 1rem 2rem 2rem 2rem;

            .proton-link-subtitle {
                color: $text-color;
            }

            .proton-link-info {
                .proton-link-countdown {
                    border: 1px solid $border-color;
                    color: $text-color;
                    margin-top: 1rem;
                }
            }

            .proton-link-hr {
                border-top: $border-color;
                margin: 1rem 0;
            }

            .proton-link-background {
                border: 1px solid $border-color;
                border-radius: 6px;
                box-shadow: none !important;
            }

            .proton-link-separator {
                //display: none;
                color: rgb(17, 25, 40) !important;
                font-family: "Roboto", sans-serif !important;

                &:before {
                    border-color: $divider-color !important;
                }

                &:after {
                    border-color: $divider-color !important;
                }
            }

            .proton-link-button {
                display: flex;
                align-items: center;
                background: rgb(243, 244, 246) !important;
                border-radius: 3px !important;
                border: none !important;
                color: rgb(17, 25, 40) !important;
                font-family: "Roboto", "Font Awesome 5 Pro", sans-serif !important;
                //font-size: 20px;
                font-weight: 500;
                gap: 0.5rem;
                justify-content: center;
                line-height: 1.5rem;
                margin-top: 0 !important;
                padding: 0.75rem 1.5rem;
                transition: all 0.2s;

                &:before {
                    content: "\f10b";
                    font-weight: 900;
                }
            }

            .proton-link-footnote {
                display: none;
            }
        }

        img.proton-link-qr {
            border-radius: 6px !important;
        }

        a.proton-link-button {
            border-radius: 3px !important;
        }
    }
}
