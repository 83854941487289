section.footer {
    @media only screen and (max-width: 600px) {
        padding-bottom: 5rem;
    }

    @media print {
        display: none;
    }

    .newsletter {
        background: #f5f5f5;
        padding: 20px;

        .container {
            max-width: 960px;
            margin: 0 auto;

            .info {
                display: flex;
                align-items: center;
                justify-content: center;

                .fal {
                    font-size: 2rem;
                }

                .n420 {
                    display: none;
                }

                .title {
                    text-align: center;
                    text-transform: uppercase;
                    font-weight: bold;
                    font-size: 1rem;
                }

                .subtitle {
                    text-align: center;
                    font-size: .9rem;
                }
            }

            .subscribe {
                margin-top: 20px;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;

                i {
                    display: none;
                }

                input[type="email"] {
                    flex: 1;
                    padding: 10px;
                    font-size: 1rem;
                    line-height: 1.2;
                    border: none;
                    border-radius: 5px;
                    margin-right: 10px;
                    max-width: 400px;

                    @media only screen and (max-width: 600px) {
                        max-width: unset;
                    }
                }

                button[type="submit"] {
                    background-color: $primary-color;
                    color: #fff;
                    padding: 10px 20px;
                    border: none;
                    border-radius: 5px;
                    font-size: 1rem;
                    cursor: pointer;
                    transition: background-color 0.2s ease-in-out;

                    &:hover {
                        background-color: $primary-color;
                    }

                    .no-spin {
                        animation: none;
                    }
                }
            }
        }

        // Responsive design for screens up to 420px wide
        @media screen and (max-width: 420px) {
            .container {
                .info {
                    flex-wrap: wrap;

                    .fal {
                    margin-right: 10px;
                    }

                    .n420 {
                    display: block;
                    }

                    .text {
                    margin: 10px 0;
                    }
                }

                .subscribe {
                    flex-direction: column;

                    input[type="email"] {
                        margin-right: 0;
                        margin-bottom: 10px;
                    }
            }
            }
        }

        // Responsive design for screens between 420px and 600px wide
        @media screen and (min-width: 421px) and (max-width: 600px) {
            .container {
            .subscribe {
                flex-wrap: wrap;

                input[type="email"] {
                flex: 1 1 100%;
                margin-right: 0;
                }

                button[type="submit"] {
                margin-top: 10px;
                flex: 0 1 100%;
                }
            }
            }
        }
    }

    // .newsletter {
    //     display: flex;
    //     align-items: center;
    //     justify-content: space-between;
    //     //background: $text-color;
    //     background: $background-light;
    //     //color: white;
    //     color: $text-color-alt;
    
    //     > .container {
    //         display: flex;
    //         align-items: center;
    //         justify-content: space-between;
    //         padding: 2rem 1rem;
    //         border-bottom: 1px solid $border-color-alt;
    
    //         @media only screen and (max-width: 768px) {
    //             flex-direction: column;
    //             gap: 1rem;
    //         }
    
    //         .info {
    //             display: flex;
    //             align-items: center;
    //             justify-content: space-between;
    //             grid-gap: 1rem;
    
    //             i {
    //                 font-size: 2.5rem;
    //             }
    
    //             .text {
    //                 @media only screen and (max-width: 768px) {
    //                     text-align: center;
    //                 }
    
    //                 .title {
    //                     text-transform: uppercase;
    //                     font-weight: bold;
    //                     font-size: 1rem;
    //                 }
    
    //                 .subtitle {
    //                     font-size: .9rem;
    //                 }
    //             }
    //         }
    
    //         .subscribe {
    //             display: flex;
    //             align-items: center;
    //             flex-wrap: nowrap;
    //             width: 60%;
    
    //             @media only screen and (max-width: 600px) {
    //                 width: 100%;
    //             }
    
    //             &.pending {
    //                 > i {
    //                     display: inline-block;
    //                     animation: rotation 1s infinite linear;
    //                     color: $text-color-alt;
    //                 }
    
    //                 input {
    //                     padding-left: 2.5rem;
    //                 }
    //             }
    
    //             &.success {
    //                 > i {
    //                     display: inline-block;
    //                     color: $positive-color;
    //                 }
    
    //                 input {
    //                     padding-left: 2.5rem;
    //                 }
    //             }
    
    //             > i {
    //                 display: none;
    //                 position: relative;
    //                 left: 2rem;
    //                 color: black;
    //                 line-height: 1;
    //                 font-size: 1.4rem;
    //             }
    
    //             input {
    //                 display: inline-block;
    //                 -moz-appearance: none !important;
    //                 -webkit-appearance: none !important;
    //                 appearance: none !important;
    //                 border-radius: 3px;
    //                 border: 1px solid $border-color;
    //                 font-size: .9rem;
    //                 margin-right: 1rem;
    //                 padding: .8rem 1.2rem;
    //                 width: 100%;
    
    //                 @media only screen and (max-width: 768px) {
    //                     margin-right: 0;
    //                 }
    //             }
    
    //             button {
    //                 display: inline-flex;
    //                 align-items: center;
    //                 background: white;
    //                 border-radius: 3px;
    //                 border: 1px solid $border-color;
    //                 font-size: .9rem;
    //                 font-weight: bold;
    //                 grid-gap: .5rem;
    //                 justify-content: space-between;
    //                 padding: .8rem 1.2rem;
    //                 text-transform: uppercase;
    //                 transition: all .3s;
    
    //                 &:hover {
    //                     opacity: .9;
    //                 }
    
    //                 i {
    //                     font-size: 1rem;
    //                 }
    //             }
    
    //             @keyframes rotation {
    //                 from {
    //                     transform: rotate(0deg);
    //                 }
    //                 to {
    //                     transform: rotate(359deg);
    //                 }
    //             }
    //         }
    //     }
    // }
    
    .footer-middle {
        background: $background-light;
        color: $text-color-alt;
        border-top: 1px solid #eeeeee;
    
        > .container {
            padding: 2rem 1rem;
            border-bottom: 1px solid $border-color-alt;
    
            @media only screen and (max-width: 768px) {
                padding: 2rem;
            }
    
            .wrapper {
                display: flex;
                align-items: center;
                justify-content: space-between;
                grid-gap: 15rem;
    
                @media only screen and (max-width: 1000px) {
                    gap: 3rem;
                }
    
                @media only screen and (max-width: 600px) {
                    gap: 1rem
                }

                @media only screen and (max-width: 480px) {
                    justify-content: center;
                }
    
                .company-info {
                    min-width: 200px;

                    @media only screen and (max-width: 480px) {
                        text-align: center;
                    }
    
                    img {
                        width: 140px;
                        margin-bottom: 1rem;
                    }
    
                    p {
                        font-size: .9rem;
                    }
    
                    .email {
                        margin-bottom: 1rem;
    
                        span {
                            font-size: 1.2rem;
                            margin-top: .3rem;
                            font-weight: bold;
                            color: $text-color-alt;
                            transition: all .3s;
    
                            &:hover {
                                color: $primary-color;
                            }
                        }
                    }
    
                    .social {
                        .icons {
                            display: flex;
                            margin-top: .3rem;
                            grid-gap: 1rem;

                            @media only screen and (max-width: 480px) {
                                justify-content: center;
                            }
    
                            .icon {
                                display: inline-block;
                                color: $text-color-alt;
    
                                &:hover {
                                    i {
                                        color: $primary-color;
                                    }
                                }
    
                                i {
                                    display: flex;
                                    align-items: center;
                                    border-radius: 100px;
                                    //border: 2px solid $text-color-alt;
                                    box-sizing: unset;
                                    font-size: 20px;
                                    //height: 16px;
                                    justify-content: center;
                                    //padding: .3rem;
                                    //width: 16px;
                                    transition: all .3s;
    
                                    &.bold {
                                        font-weight: bold;
                                    }
                                }
                            }
                        }
                    }
                }
    
                .sitemap {
                    display: flex;
                    gap: 1rem;
                    justify-content: space-between;
                    width: 100%;
    
                    @media only screen and (max-width: 600px) {
                        gap: 1rem;
                    }
    
                    .map-column {
                        display: flex;
                        flex-direction: column;
                        grid-gap: 1rem;
                        font-size: .9rem;
    
                        .column-title {
                            font-weight: bold;
                        }
    
                        a {
                            display: inline-block;
                            transition: all .3s;
                            position: relative;
    
                            &:hover {
                                color: $primary-color;
                            }
                        }
                    }
                }
            }
        }
    }
    
    .footer-bottom {
        background: $background-light;
        color: $text-color-alt;
    
        > .container {
            padding: 2rem 1rem;
            border-bottom: 1px solid $border-color-alt;
    
            .cat-row {
                display: flex;
                -ms-overflow-style: none;
                flex-direction: row;
                flex-wrap: wrap;
                font-size: .9rem;
                line-height: 1.4;
                margin-bottom: 1rem;
                overflow: scroll;
                scrollbar-width: none;
    
                &::-webkit-scrollbar {
                    display: none;
                }
    
                .title {
                    font-weight: bold;
                    margin-right: .5rem;
                    white-space: nowrap;
                }
    
                a {
                    &:last-of-type {
                        span {
                            padding-right: none;
                            margin-right: none;
                            border-right: none;
                        }
                    }
    
                    span {
                        padding-right: .5rem;
                        margin-right: .5rem;
                        border-right: 1px solid $divider-color;
                    }
                }
            }
        }
    }
    
    .copyright {
        background: $background-light;
        color: $text-color;
    
        > .container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 2rem 1rem;
    
            @media only screen and (max-width: 768px) {
                flex-direction: column;
                gap: 1rem;
            }
    
            .copyright-notice {
                font-size: .8rem;
    
                @media only screen and (max-width: 600px) {
                    text-align: center;
                }
            }
    
            .powered-by {
                display: flex;
                grid-gap: .5rem;
                font-size: .8rem;
                align-items: center;
    
                img {
                    width: 128px;
                }
            }
        }
    }
}