section.settings, section.private-key, section.kyc, section.sessions {
    .subtitle {
        color: $text-color-alt;
        font-size: .8rem;
        margin-bottom: .5rem;

        & + .status {
            display: inline-flex;
            align-items: center;
            border-radius: 3px;
            border: 1px solid $border-color;
            gap: .5rem;
            margin-bottom: 1rem;
            padding: .5rem 1rem;

            &.green {
                background: rgba($positive-color, 0.1);
                border-color: $positive-color;
                color: $positive-color;

                > i {
                    color: $positive-color;
                }
            }

            &.amber {
                background: rgba($warning-color, 0.1);
                border-color: $warning-color;
                color: $warning-color;

                > i {
                    color: $warning-color;
                }
            }

            &.red {
                background: rgba($negative-color, 0.1);
                border-color: $negative-color;
                color: $negative-color;

                > i {
                    color: $negative-color;
                }
            }
        }
    }

    .limits {
        display: flex;
        flex-direction: column;
        gap: .5rem;

        .limit {
            display: flex;
            align-items: center;
            gap: .3rem;
            color: $text-color;
            font-size: .9rem;

            .fa-check-circle {
                color: $positive-color;
            }
    
            .fa-times-circle {
                color: $negative-color;
            }

            .fa-info-circle {
                color: $text-color-alt;
                font-size: .8rem;
                line-height: 1px;
            }
        }
    }

    button.verify {
        margin-top: 1rem;
    }

    form {
        display: flex;
        color: $text-color;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;

        @media only screen and (max-width: 500px) {
            flex-direction: column;
            flex-wrap: nowrap;
        }

        .input-group {
            display: block;
            margin-bottom: 2rem;
            width: 49%;
            position: relative;

            i.fa-spinner-third {
                color: $primary-color;
                right: unset;
                left: 0;
                padding: .5rem 0;
            }

            @media only screen and (max-width: 500px) {
                width: 100%;
            }

            &.pad-right {
                input {
                    padding-right: 40px;
                }
            }

            &.phone {
                display: flex;
            }

            &.date {
                i {
                    z-index: -1;
                }

                input {
                    background: rgba(0, 0, 0, 0);
                    padding-right: 15px;
                    text-align: left;
                    text-transform: uppercase;

                    &::-webkit-calendar-picker-indicator {
                        opacity: 0;
                    }

                    &:focus {
                        background: rgba(0, 0, 0, 0);
                    }
                }
            }

            > i {
                position: absolute;
                border: 1px solid rgba(0, 0, 0, 0);
                bottom: 0;
                color: #808080;
                right: 0;
                line-height: 1.5;
                padding: .5rem 1rem;
                user-select: none;

                &.verified {
                    color: $positive-color;
                    z-index: 1;

                    &:before {
                        content: "\f058";
                    }
                }

                &.unverified {
                    color: $warning-color;
                    z-index: 1;

                    &:before {
                        content: "\f06a";
                    }
                }
            }

            select.calling-code {
                width: 4rem;
            }

            .hint {
                display: block;
                color: $text-color;
                font-size: 13px;
                padding-top: 5px;
                text-align: right;
                width: 100%;
            }
        }

        .notice {
            font-size: .8rem;
            margin-bottom: 1rem;

            a {
                font-weight: bold;
                transition: all .3s;

                &:hover {
                    color: $primary-color;
                }
            }
        }
    }

    ul.session-list {
        @media only screen and (max-width: 470px) {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 1rem;
        }

        li.session {
            display: grid;
            align-items: center;
            background: white;
            border-radius: 3px;
            border: 1px solid $border-color;
            color: $text-color;
            grid-gap: 2rem;
            grid-template-columns: repeat(3, 1fr);
            justify-content: space-between;
            margin-bottom: 1rem;
            padding: 1rem 3rem 1rem 4rem;
            position: relative;
            text-align: center;

            @media only screen and (max-width: 470px) {
                display: flex;
                flex-direction: column;
                padding: 3rem 1rem 1rem 1rem;
                gap: 1rem;
                margin-bottom: 0;
            }

            i.device-type {
                font-size: 1.2rem;
                left: 0;
                margin-left: 1rem;
                position: absolute;
                text-align: center;
                width: 24px;

                @media only screen and (max-width: 470px) {
                    left: 0;
                    margin: auto;
                    padding: 1rem 0;
                    right: 0;
                    top: 0;
                }

                &.desktop {
                    &:before {
                        content: "\f108"
                    }
                }

                &.phone {
                    &:before {
                        content: "\f3cd"
                    }
                }

                &.unknown {
                    &:before {
                        content: "\f128"
                    }
                }
            }

            i.device-terminate {
                position: absolute;
                cursor: pointer;
                margin-right: 1rem;
                right: 0;
                transition: all .2s;

                @media only screen and (max-width: 470px) {
                    top: .5rem;
                }

                &:hover {
                    color: $negative-color;
                }
            }
        }
    }

    div.terminate-all-wrapper {
        text-align: right;

        button.terminate-all {
            display: inline-block;
            background: white;
            border-radius: 3px;
            border: 1px solid $border-color;
            color: $text-color;
            padding: .5rem 1rem;
            right: 0;
            transition: all .2s;

            &:hover {
                color: $negative-color;
            }
        }
    }
}
